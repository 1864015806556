<template>
  <nav aria-label="Page navigation" class="d-flex justify-content-end float-left align-items-center">
    <div style="display: flex; justify-content: center; align-items: center; gap: 1em;">
      <ul class="pagination">
        <!-- Previous Page Button -->
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click="changePage(currentPage - 1)" aria-label="Previous">
            <span aria-hidden="true">Previous</span>
          </button>
        </li>

        <!-- Page Number Buttons -->
        <li class="page-item" v-for="page in paginatedPages" :key="page" :class="{ active: page === currentPage }">
          <button class="page-link" @click="changePage(page)">{{ page }}</button>
        </li>

        <!-- Next Page Button -->
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button class="page-link" @click="changePage(currentPage + 1)" aria-label="Next">
            <span aria-hidden="true">Next</span>
          </button>
        </li>
      </ul>

      <!-- Total Pages Display -->
      <div >
        Page {{ currentPage }} of {{ totalPages }}
      </div>

      <div >
        <input type="number" v-model.number="inputPage" @keyup.enter="goToPage" min="1" :max="totalPages"
          class="form-control" style="min-width: 150px;" placeholder="Go to page" />

      </div>

      <button class="btn btn-primary mt-1" @click="goToPage">Go</button>
    </div>

    <!-- Go to Page Input -->

  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      inputPage: null
    };
  },
  computed: {
    paginatedPages() {
      const pages = [];
      const startPage = Math.floor((this.currentPage - 1) / 5) * 5 + 1;
      const endPage = Math.min(startPage + 4, this.totalPages);
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    changePage(newPage) {
      if (newPage > 0 && newPage <= this.totalPages) {
        this.$emit('page-change', newPage);
      }
    },
    goToPage() {
      if (this.inputPage > 0 && this.inputPage <= this.totalPages) {
        this.changePage(this.inputPage);
      }
    }
  }
};
</script>

<style scoped>
.page-item.disabled .page-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>
