import axios from 'axios';
import { globalState } from '/src/router';


function getAuthToken() {
  return localStorage.getItem('token');
}

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
});

const apiClientBypass = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
});

// Add a request interceptor
apiClientBypass.interceptors.request.use(function (config) {

  const token = getAuthToken();
  config.headers['key'] = token ? token : '';
  config.headers['Accept'] = 'application/json';
  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a request interceptor
apiClient.interceptors.request.use(function (config) {
  const token = getAuthToken();
  globalState.isLoading = true; // Start loading
  config.headers['key'] = token ? token : '';
  config.headers['Accept'] = 'application/json';
  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});



apiClient.interceptors.response.use(
  response => {
    // Stop loading
    globalState.isLoading = false;
    return response;
  },
  error => {
    // Stop loading on error
    globalState.isLoading = false;

    // Check for 401 Unauthorized response
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('firstTimeLogin');
      localStorage.removeItem('systemUserId');
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    console.log(error);

    return Promise.reject(error);
  }
);

// Convert a date to a UTC string
function toUTCString(date, hours = 16, minutes = 0, seconds = 0) {
  const dateObj = new Date(date);
  dateObj.setUTCHours(hours, minutes, seconds, 0); // Set to 16:00:00 UTC
  return dateObj.toISOString().replace(/\.\d{3}Z$/, ''); // Removes milliseconds and 'Z'
}

export default {

  getImage(filename) {
    return apiClient.get(`/api/getImageUrl/${filename}`);
  },
  // User Authentication
  login(credentials) {
    return apiClientBypass.post('/api/login', credentials);
  },
  logout() {
    return apiClient.post('/api/logout');
  },
  checkUserSession() {
    return apiClientBypass.get('/api/checkUserSession');
  },

  //Dashboard
  getOverallReport({ isMemberOnly, isLifeTimeValue, startDateTime, endDateTime } = {}) {
    const params = new URLSearchParams();

    if (isMemberOnly) params.append('isMemberOnly', isMemberOnly);
    if (isLifeTimeValue) params.append('isLifeTimeValue', isLifeTimeValue);
    if (startDateTime) params.append('startDateTime', startDateTime);
    if (endDateTime) params.append('endDateTime', endDateTime);
    return apiClient.get('/api/dashboard', { params });
  },
    //Comm Report
    getCommReport({ playerId, eventId, startDateTime, endDateTime } = {}) {
      const params = new URLSearchParams();
  
      if (playerId) params.append('playerId', playerId);
      if (eventId) params.append('eventId', eventId);
      if (startDateTime) params.append('startDateTime', startDateTime);
      if (endDateTime) params.append('endDateTime', endDateTime);
      return apiClient.get('/api/commission', { params });
    },
  

  //General Dropdown
  getCountryCallingCode() {
    return apiClient.get('/api/getallcountrycallingcode');
  },

  getOdds({ typeId } = {}) {
    const params = new URLSearchParams();

    // Add parameters if they are provided
    if (typeId) params.append('typeId', typeId);
    return apiClient.get('/api/odds', { params });
  },

  //Role Module
  getRoleAccess(credentials) {
    return apiClient.post('/api/roleaccess', credentials);
  },
  getRole({ name, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    // Add parameters if they are provided
    if (name) params.append('name', name);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/getrole', { params });
  },
  getRoleById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/roleById`, { params });
  },
  getRoleAccessPage() {
    return apiClient.get(`/api/roleAccessPage`);
  },
  updateRoleStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update role status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/role/${id}`, data);
  },
  updateRoleAccess({ id, name, pageIds }) {
    if (!id) throw new Error("ID is required to update role.");


    const data = {
      name: name,
      pageIds: pageIds
    };

    return apiClient.patch(`/api/roleUpdate/${id}`, data);

  },
  createRole(credentials) {
    return apiClient.post(`/api/createRole`, credentials);
  },

  //User Module
  getUsers({ name, accessControlId, username, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    // Add parameters if they are provided
    if (name) params.append('name', name);
    if (accessControlId) params.append('accessControlId', accessControlId);
    if (username) params.append('username', username);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/userlist', { params });
  },
  getUserById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/userbyId`, { params });
  },
  updateUserStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update user status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/user/${id}`, data);
  },
  updateUserInformation({ id, name, password, accessControlId, countryCallingCodeId, contactNumber }) {
    if (!id) throw new Error("ID is required to update user status.");


    const data = {
      name: name,
      accessControlId: accessControlId,
      countryCallingCodeId: countryCallingCodeId,
      contactNumber: contactNumber
    };
    if (password) data.password = password;
    return apiClient.patch(`/api/userInfo/${id}`, data);

  },
  createUser(credentials) {
    return apiClient.post(`/api/createUser`, credentials);
  },

  //Division Module
  getDivisions({ name, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    // Add parameters if they are provided
    if (name) params.append('name', name);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/divisionlist', { params });
  },
  getDivisionById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/divisionbyId`, { params });
  },
  updateDivisionStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update division status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/division/${id}`, data);
  },
  updateDivisionInformation({ id, name, chineseName }) {
    if (!id) throw new Error("ID is required to update division.");
    const data = {
      name: name,
      chineseName: chineseName,
    };

    return apiClient.patch(`/api/divisionInfo/${id}`, data);

  },
  createDivision(credentials) {
    return apiClient.post(`/api/createDivision`, credentials);
  },


  //Membership Upgrade Module
  getMembershipUpgradeList({ membershipId, playerUsername, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (membershipId) params.append('membershipId', membershipId);
    if (playerUsername) params.append('playerUsername', playerUsername);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/membershipupgradelist', { params });
  },
  //Membership Upgrade Module
  getMemberBetUpgradeList({ id, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (id) params.append('id', id);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/memberbetupgradelist', { params });
  },

  //Membership Module

  getMemberships({ statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/membershiplist', { params });
  },
  getMembershipById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/membershipbyId`, { params });
  },
  getMembershipPlayerById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/membershipPlayerbyId`, { params });
  },
  updateMembershipStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update membership status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/membership/${id}`, data);
  },
  updateMembershipInformation({ id, name, level, levelUpDay, levelUpBetAmount }) {
    if (!id) throw new Error("ID is required to update membership.");
    const data = {
      name: name,
      level: level,
      levelUpDay: levelUpDay,
      levelUpBetAmount: levelUpBetAmount,
    };

    return apiClient.patch(`/api/membershipInfo/${id}`, data);

  },
  createMembership(credentials) {
    try {
      const ipResponse = apiClient.get('https://api.ipify.org?format=json');
      console.log(ipResponse);
      if (ipResponse.data && ipResponse.data.query) {
        credentials.ip = ipResponse.data.ip;
      }
    } catch (error) {
      console.error("Error fetching IP:", error);
      // If there's an error, ip remains an empty string
    }
    return apiClient.post(`/api/createMembership`, credentials);
  },

  //Member Module

  getMembers({ name, membershipid, username, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (name) params.append('name', name);
    if (membershipid) params.append('membershipid', membershipid);
    if (username) params.append('username', username);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/playerlist', { params });
  },
  getMemberById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/playerbyId`, { params });
  },
  getMemberWalletById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/playerwalletbyId`, { params });
  },
  getMemberWalletLogById({ id, offset, limit, sort, order } = {}) {

    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get(`/api/playerwalletlogbyId`, { params });
  },
  getPlayerInfoById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/playerInfobyId`, { params });
  },
  getPlayerReferralInfoById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/playerReferralInfobyId`, { params });
  },
  getPlayerReferreeListbyId({ id, offset, limit, sort, order } = {}) {

    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get(`/api/playerReferreeListbyId`, { params });
  },
  getPlayerCommissionListbyId({ id, offset, limit, sort, order } = {}) {

    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    console.log(params);
    return apiClient.get(`/api/playerCommissionListbyId`, { params });
  },

  updateMemberStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update player status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/player/${id}`, data);
  },

  updateMemberInformation({ id, name, countryCallingCodeId, contactNumber, email, dateOfBirth, membershipId, password, isOptForMarketing }) {
    if (!id) throw new Error("ID is required to update player.");

    let data = {
      name: name,
      countryCallingCodeId: countryCallingCodeId,
      contactNumber: contactNumber,

      membershipId: membershipId,
      isOptForMarketing: isOptForMarketing,
    };

    // Only add email and password to data if they are present
    if (email) data.email = email;
    if (password) data.password = password;
    if (dateOfBirth) data.dateOfBirth = dateOfBirth;
    return apiClient.patch(`/api/playerInfo/${id}`, data);
  },

  createMember(credentials) {
    return apiClient.post(`/api/createplayer`, credentials);
  },

  createWalletAdjustment(credentials) {
    return apiClient.post(`/api/createWalletAdjustment`, credentials);
  },



  //Team Module
  getTeams({ name, divisionId, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    // Add parameters if they are provided
    if (name) params.append('name', name);
    if (statusId) params.append('statusId', statusId);
    if (divisionId) params.append('divisionId', divisionId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/teamlist', { params });
  },
  getTeamById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/teambyId`, { params });
  },
  updateTeamStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update team status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/team/${id}`, data);
  },
  updateTeamInformation({ id, name, chineseName, divisionId, logoFileExtension, logoFile }) {
    if (!id) throw new Error("ID is required to update team.");
    const data = {
      name: name,
      chineseName: chineseName,
      divisionId: divisionId,

    };
    if (logoFileExtension) data.logoFileExtension = logoFileExtension;
    if (logoFile) data.logoFile = logoFile;

    return apiClient.patch(`/api/teamInfo/${id}`, data);

  },
  createTeam(credentials) {
    return apiClient.post(`/api/createTeam`, credentials);
  },

  //Event Module
  getEvents({ teamId, name, statusId, startEventAt, endEventAt, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    // Add parameters if they are provided
    if (teamId) params.append('teamId', teamId);
    if (name) params.append('name', name);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);

    if (startEventAt) params.append('startEventAt', toUTCString(startEventAt));
    if (endEventAt) params.append('endEventAt', toUTCString(endEventAt));

    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);

    return apiClient.get('/api/eventlist', { params });
  },
  getEventById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/eventbyId`, { params });
  },
  updateEventStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update event status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/event/${id}`, data);
  },
  updateEventInformation({ id, divisionIds, homeTeamId, awayTeamId, eventAt, odds }) {
    if (!id) throw new Error("ID is required to update event.");
    const data = {
      divisionIds: divisionIds,
      homeTeamId: homeTeamId,
      awayTeamId: awayTeamId,
      eventAt: eventAt,
      odds: odds
    };

    return apiClient.patch(`/api/eventInfo/${id}`, data);

  },

  updateEventScore({ id, halfHomeScore, halfAwayScore, fullHomeScore, fullAwayScore }) {
    if (!id) throw new Error("ID is required to update team.");
    const data = {
      halfHomeScore: halfHomeScore,
      halfAwayScore: halfAwayScore,
      fullHomeScore: fullHomeScore,
      fullAwayScore: fullAwayScore,
    };

    return apiClient.patch(`/api/eventScore/${id}`, data);

  },
  createEvent(credentials) {
    return apiClient.post(`/api/createEvent`, credentials);
  },

  //OddPreset Module
  getOddPresets({ name, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    // Add parameters if they are provided
    if (name) params.append('name', name);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/oddPresetlist', { params });
  },
  getOddPresetById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/oddPresetbyId`, { params });
  },
  updateOddPresetStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update oddPreset status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/oddPreset/${id}`, data);
  },
  updateOddPresetInformation({ id, name, details }) {
    if (!id) throw new Error("ID is required to update oddPreset.");
    const data = {
      name: name,
      details: details,
    };

    return apiClient.patch(`/api/oddPresetInfo/${id}`, data);

  },
  createOddPreset(credentials) {
    return apiClient.post(`/api/createOddPreset`, credentials);
  },


  //PaymentMethod Module
  getPaymentMethods({ statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/paymentmethodlist', { params });
  },
  getPaymentMethodById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/paymentmethodbyId`, { params });
  },
  updatePaymentMethodStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update payment method status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/paymentmethod/${id}`, data);
  },
  updatePaymentMethodInformation({ id, name, chineseName, isOnlinePayment }) {
    if (!id) throw new Error("ID is required to update payment method.");
    const data = {
      name: name,
      chineseName: chineseName,
      isOnlinePayment: isOnlinePayment,
    };

    return apiClient.patch(`/api/paymentmethodInfo/${id}`, data);

  },
  createPaymentMethod(credentials) {
    return apiClient.post(`/api/createPaymentMethod`, credentials);
  },

  //PaymentChannel Module
  getPaymentChannels({ paymentMethodId, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (paymentMethodId) params.append('paymentMethodId', paymentMethodId);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/paymentchannellist', { params });
  },
  getPaymentChannelById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/paymentchannelbyId`, { params });
  },
  updatePaymentChannelStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update payment channel status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/paymentchannel/${id}`, data);
  },
  updatePaymentChannelInformation({ id, name, code, logoFileExtension, logoFile, paymentMethodId, paymentGatewayId }) {
    if (!id) throw new Error("ID is required to update payment channel.");
    const data = {
      name: name,
      code: code,
      logoFileExtension: logoFileExtension,
      logoFile: logoFile,
      paymentMethodId: paymentMethodId,
    };
    if (paymentGatewayId) data.paymentGatewayId = paymentGatewayId;
    return apiClient.patch(`/api/paymentchannelInfo/${id}`, data);

  },
  createPaymentChannel(credentials) {
    return apiClient.post(`/api/createPaymentChannel`, credentials);
  },

  //Payment Gateway
  getPaymentChannelGateways() {
    return apiClient.get('/api/paymentgateway');
  },

  //PaymentChannelAccount Module
  getPaymentChannelAccounts({ accountNumber, paymentChannelId, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (accountNumber) params.append('accountNumber', accountNumber);
    if (paymentChannelId) params.append('paymentChannelId', paymentChannelId);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/paymentchannelaccountlist', { params });
  },
  getPaymentChannelAccountById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/paymentchannelaccountbyId`, { params });
  },
  updatePaymentChannelAccountStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update payment channel account status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/paymentchannelaccount/${id}`, data);
  },
  updatePaymentChannelAccountInformation({ id, name, accountName, accountNumber, paymentChannelId }) {
    if (!id) throw new Error("ID is required to update channel account.");
    const data = {
      name: name,
      accountName: accountName,
      accountNumber: accountNumber,
      paymentChannelId: paymentChannelId,
    };
    return apiClient.patch(`/api/paymentchannelaccountInfo/${id}`, data);

  },
  createPaymentChannelAccount(credentials) {
    return apiClient.post(`/api/createPaymentChannelAccount`, credentials);
  },

  //DepositTransaction Module
  getDepositTransactions({ playerUsername, paymentMethodId, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (playerUsername) params.append('playerUsername', playerUsername);
    if (paymentMethodId) params.append('paymentMethodId', paymentMethodId);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/deposittransactionlist', { params });
  },
  getDepositTransactionById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/deposittransactionbyId`, { params });
  },
  updateDepositTransactionStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update deposit transaction status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/deposittransaction/${id}`, data);
  },
  createDepositTransaction(credentials) {
    return apiClient.post(`/api/createDepositTransaction`, credentials);
  },

  //withdrawalTransaction Module
  getWithdrawalTransactions({ playerUsername, paymentMethodId, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (playerUsername) params.append('playerUsername', playerUsername);
    if (paymentMethodId) params.append('paymentMethodId', paymentMethodId);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/withdrawaltransactionlist', { params });
  },
  getWithdrawalTransactionById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/withdrawaltransactionbyId`, { params });
  },
  updateWithdrawalTransactionStatusById({ id, status, paymentChannelAccountId } = {}) {
    if (!id) throw new Error("ID is required to update withdrawal transaction status.");

    const data = {
      status: status,
      paymentChannelAccountId: paymentChannelAccountId,
    };

    return apiClient.patch(`/api/withdrawaltransaction/${id}`, data);
  },
  createWithdrawalTransaction(credentials) {
    return apiClient.post(`/api/createwithdrawalTransaction`, credentials);
  },
  //PlayerBankAccount Module
  getPlayerBankAccounts({ playerId, statusId, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (playerId) params.append('playerId', playerId);
    if (statusId) params.append('statusId', statusId);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/playerbankaccountlist', { params });
  },
  getPlayerBankAccountById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/playerbankaccountbyId`, { params });
  },
  updatePlayerBankAccountStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update player bank account status.");

    const data = {
      status: status
    };

    return apiClient.patch(`/api/playerbankaccount/${id}`, data);
  },
  updatePlayerBankAccountInformation({ playerId, accountName, accountNumber, paymentChannelId }) {
    if (!playerId) throw new Error("ID is required to update player bank account.");
    const data = {
      id: playerId,
      accountName: accountName,
      accountNumber: accountNumber,
      paymentChannelId: paymentChannelId,
    };

    return apiClient.patch(`/api/playerbankaccountInfo/${playerId}`, data);

  },
  createPlayerBankAccount(credentials) {
    return apiClient.post(`/api/createPlayerBankAccount`, credentials);
  },

  //TransferTransaction Module
  getTransferTransactions({ playerUsername, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (playerUsername) params.append('playerUsername', playerUsername);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/transfertransactionlist', { params });
  },
  getTransferTransactionById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/transfertransactionbyId`, { params });
  },
  updateTransferTransactionStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update deposit transaction status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/transfertransaction/${id}`, data);
  },
  createTransferTransaction(credentials) {
    return apiClient.post(`/api/createTransferTransaction`, credentials);
  },

  //Commission Setiup
  updateCommissionSettings(credentials) {
    return apiClient.patch(`/api/commission`, credentials);
  },

  getCommissionSettings() {
    return apiClient.get(`/api/getCommison`);
  },

  //MemberBet Module
  getMemberBets({ playerUsername, teamId, statusId, fromDate, toDate, offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (playerUsername) params.append('playerUsername', playerUsername);
    if (statusId) params.append('statusId', statusId);
    if (teamId) params.append('teamName', teamId);
    if (fromDate) params.append('startKickOffAt', fromDate);
    if (toDate) params.append('endKickOffAt', toDate);
    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/memberbetlist', { params });
  },
  getMemberBetById({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/memberbetbyId`, { params });
  },
  updateMemberBetStatusById({ id, amount, eventOddId } = {}) {
    if (!id) throw new Error("ID is required to update member bet transaction status.");

    const data = {
      amount: amount,
      eventOddId: eventOddId,
    };

    return apiClient.patch(`/api/memberbet/${id}`, data);
  },
  createMemberBet(credentials) {
    return apiClient.post(`/api/createMemberBet`, credentials);
  },

//Imported 
getImportedMatch({  statusId, offset, limit, sort, order } = {}) {
  const params = new URLSearchParams();

  if (statusId) params.append('statusId', statusId);
  if (offset) params.append('offset', offset);
  if (limit) params.append('limit', limit);
  if (sort) params.append('sort', sort);
  if (order) params.append('order', order);
  return apiClient.get('/api/import/event', { params });
},

getCompetition() {
  const params = new URLSearchParams();
  return apiClient.get('/api/import/competition', { params });
},

importEvent(credentials) {
  return apiClient.post(`/api/import/event`, credentials);
},

getImportEventById({ id } = {}) {
  const params = new URLSearchParams();
  if (id) params.append('id', id);
  return apiClient.get(`/api/import/eventbyId`, { params });
},

updateImportEventById({ id, statusId, divisionName, divisionChineseName, homeTeamName, homeTeamChineseName, awayTeamName, homeTeamLogoUrl, awayTeamLogoUrl, awayTeamChineseName, eventAt } = {}) {
  if (!id) throw new Error("ID is required to update.");

  const data = {
    statusId: statusId,
    divisionName: divisionName,
    divisionChineseName : divisionChineseName,
    homeTeamName : homeTeamName,
    homeTeamChineseName : homeTeamChineseName,
    awayTeamName : awayTeamName,
    homeTeamLogoUrl : homeTeamLogoUrl,
    awayTeamLogoUrl : awayTeamLogoUrl,
    awayTeamChineseName : awayTeamChineseName,
    eventAt : eventAt,
  };

  return apiClient.patch(`/api/import/event/${id}`, data);
},

updateMultipleImportEvent({  statusId, importEventIds} = {}) {
  

  const data = {
    statusId: statusId,
    importEventIds: importEventIds
  };

  return apiClient.patch(`/api/import/event`, data);
},

  //Currency Exchange Module
  getCurrencyExchange({ offset, limit, sort, order } = {}) {
    const params = new URLSearchParams();

    if (offset) params.append('offset', offset);
    if (limit) params.append('limit', limit);
    if (sort) params.append('sort', sort);
    if (order) params.append('order', order);
    return apiClient.get('/api/currencyExchangelist', { params });
  },
  getCurrencyExchangebyId({ id } = {}) {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    return apiClient.get(`/api/currencyExchangebyId`, { params });
  },
  updateCurrencyExchangeStatusById({ id, status } = {}) {
    if (!id) throw new Error("ID is required to update currency Exchange status.");

    const data = {
      status: status,
    };

    return apiClient.patch(`/api/currencyExchangeStatus/${id}`, data);
  },
  updateCurrencyExchangeInformation({ id, currencyCode, toUsdRate }) {
    if (!id) throw new Error("ID is required to update Currency Exchange.");
    const data = {
      currencyCode: currencyCode,
      toUsdRate: toUsdRate,
    };

    return apiClient.patch(`/api/currencyExchange/${id}`, data);

  },
  createCurrencyExchange(credentials) {
    return apiClient.post(`/api/createCurrencyExchange`, credentials);
  },

};
