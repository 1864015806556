<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t" @submit.prevent="search">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="ibox-content m-b-sm border-bottom">

        <div class="row">


          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="name">Login ID</label>
              <Autocomplete placeholder="Search Login ID" :suggestions="loginIdSuggestions.map(player => player.username)"
                @select="onLoginIdSelect" ref="autocompleteRef" />

            </div>

          </div>
          <div class="col-sm-3" style="    display: flex; align-items: flex-end;">
            <div class="form-group">
              <label class="col-form-label" for="name"></label>
              <Button button-type="button" button-class="btn btn-w-m btn-success" button-text="Show member list"
                @click="toggleDrawer" />

            </div>

          </div>


        </div>



        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
            <!-- <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
              button-icon="fa-search" /> -->

            <Button button-type="button" button-class="btn-warning block  m-b" button-text="Add" button-icon="fa-plus"
              @click="add" v-if="searchInput.id" />
          </div>
        </div>
      </div>
      <div class="row" v-if="searchInput.id">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content table-responsive">

              <DynamicTable :columns="tableInfoColumns" :rows="referralInfo" :sortingKey="sorting.sortBy"
                :sortOrder="sorting.sortOrder" @sort-requested="sort">

                <template v-slot:custom="{ row }">
                  <span class="text-active" @click="viewCommission(row)" >
                    {{ row.totalEarnedCommissionAmount }}
                  </span>
                </template>
              </DynamicTable>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="searchInput.id && rows.length > 0">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content table-responsive">
              <rows-per-page-select v-model="pagination.perPage" @update:value="onPerPageChange"></rows-per-page-select>

              <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                :sortOrder="sorting.sortOrder" @sort-requested="sort">
                <!-- Define slots for action buttons -->

                <template v-slot:actionButton="{ row }">
                  <!-- Your custom action button -->
                  <Button button-type="button" button-class="btn btn-w-m btn-default btn-loginId"
                    :button-text="row.username" @click="handleRowSelected(row)" />
                </template>
                <template v-slot:custom="{ row }">
                  <span class="text-active" @click="viewCommission(row)" >
                    {{ row.totalEarnedCommissionAmount }}
                  </span>
                </template>
                <template v-slot:statusButton="{ row }">


                  <span :classList="getStatusColor(row.statusId)">
                    {{ getStatusLabel(row.statusId) }}
                  </span>
                </template>

              </DynamicTable>
              <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                @page-change="onPageChange"></pagination-component>
            </div>
          </div>
        </div>
      </div>


    </div>
  </form>

  <LeftDrawer v-model="isDrawerOpen" @update:isOpen="isDrawerOpen = $event">
    <!-- Content for the drawer -->


    <div class="row">
      <h3>Member List</h3>
      <div class="col-sm-3">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Search Login ID" v-model="searchQuery"
            @keyup="handleSearch" />
        </div>

      </div>
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-content table-responsive">

            <DynamicTable :columns="tableMemberColumns" :rows="filteredMembers" :sortingKey="sorting.sortBy"
              :sortOrder="sorting.sortOrder" @sort-requested="sort" @row-selected="handleRowSelected">

            </DynamicTable>
          </div>
        </div>
      </div>
    </div>
  </LeftDrawer>
  <dynamic-modal :title="modalTitle" :modal-id="'memberModal'" :is-edit="isEdit" :row="activeRow ? activeRow : null"
    :memberships="memberships" @modalClose="modalClosedHandler" />
  <CommissionModal :modalId="'infoModal'" :title="modalTitle" :memberId="selectedRefereeId" :memberName="memberName" />
</template>



<script>
import { ref, reactive, computed, watch } from 'vue';
import Button from '@/components/Element/Button.vue';
import DynamicModal from './DynamicModal.vue';
import CommissionModal from './CommissionModal.vue';
import { useToast } from 'vue-toastification';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import { EventStatusEnum } from '@/enum/eventStatus';
import apiService from '@/components/Services/apiService';
import Autocomplete from "@/components/Element/Autocomplete.vue";

import LeftDrawer from '@/components/Element/LeftDrawer.vue';
export default {
  components: {
    Button,
    DynamicModal,
    DynamicTable,
    PaginationComponent,
    RowsPerPageSelect,
    Autocomplete,
    LeftDrawer,
    CommissionModal
  },
  setup() {
    const autocompleteRef = ref(null);
    const rows = ref([]);
    const referralInfo = ref([]);
    const teams = ref([]);
    const toast = useToast();
    const activeRow = ref({});

    const modalTitle = ref('Add Bet');
    const isEdit = ref();
    const searchQuery = ref('');
    const filteredMembers = ref([]);
    const memberships = ref([]);
    const isDrawerOpen = ref(false);

    const selectedRefereeId = ref();
    const memberName = ref();
    const loginIdSuggestions = ref([]);
    const searchInput = reactive({
      id: 0,
    });

    const sorting = reactive({
      sortBy: 'id',
      sortOrder: 'desc'
    });

    const pagination = reactive({
      currentPage: 1,
      perPage: 5,
      totalRows: 0
    });


    const debounce = (func, delay) => {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      }
    };


    const handleSearch = () => {
      if (searchQuery.value) {
        filteredMembers.value = loginIdSuggestions.value.filter((member) =>
          member.username.toLowerCase().includes(searchQuery.value.toLowerCase())
        );

      } else {
        filteredMembers.value = loginIdSuggestions.value;
      }
    };

    watch(searchQuery, handleSearch);
    const toggleDrawer = debounce(() => {
      isDrawerOpen.value = !isDrawerOpen.value;
    }, 300);




    const statusArray = computed(() =>
      Object.entries(EventStatusEnum).map(([label, id]) => ({ id, label }))
    );

    const totalPages = computed(() =>
      Math.ceil(pagination.totalRows / pagination.perPage)
    );

    const tableColumns = [
      { name: 'Login ID', key: 'username', isSortable: false, isAction: true, slotName: 'actionButton' },
      { name: '#Direct Referral', key: 'directDownline', isSortable: true, isAction: false },
      { name: 'Group Size', key: 'groupSize', isSortable: true },
      { name: 'Membership level', key: 'membershipName', isSortable: true, isAction: false },
      { name: 'Commission Rate', key: 'commissionRate', isSortable: true, isAction: false },
      { name: 'Latest Earned Commission', key: 'latestEarnedCommissionAmount', isSortable: true, isAction: false },
      { name: 'Total Earned Commission', key: 'totalEarnedCommissionAmount', isSortable: true, isAction: false, slotName: 'custom', isCustom: true },
      { name: 'Joined Date', key: 'createdAt', isSortable: true, isAction: false, isDate: true },
    ]

  
    const tableMemberColumns = [
      { name: 'Login ID', key: 'username', isSortable: false, isAction: false },
      { name: 'Full Name', key: 'name', isSortable: true, isAction: false },
      { name: 'Joined Date', key: 'createdAt', isSortable: true, isAction: false, isDate: true },
    ]

    const tableInfoColumns = [
      { name: '#Direct Referral', key: 'directDownline', isSortable: true, isAction: false },
      { name: 'Group Size', key: 'groupSize', isSortable: true },
      { name: 'Membership level', key: 'membershipName', isSortable: true, isAction: false },
      { name: 'Commission Rate', key: 'commissionRate', isSortable: true, isAction: false },
      { name: 'Latest Earned Commission', key: 'latestEarnedCommissionAmount', isSortable: true, isAction: false },
      { name: 'Total Earned Commission', key: 'totalEarnedCommissionAmount', isSortable: true, isAction: false , slotName: 'custom', isCustom: true},
      { name: 'Joined Date', key: 'createdAt', isSortable: true, isAction: false, isDate: true },
    ]




    async function fetchPlayerReferee() {
      if (searchInput.id) {
        const skip = (pagination.currentPage - 1) * pagination.perPage;
        const params = { ...searchInput, offset: skip, limit: pagination.perPage, sort: sorting.sortBy, order: sorting.sortOrder };
        try {
          const response = await apiService.getPlayerReferreeListbyId(params);
          rows.value = response.data.playerReferees;

          pagination.totalRows = response.data.count;
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }

    }

    async function fetchPlayerReferralInfo() {
      if (searchInput.id) {
        try {
          const response = await apiService.getPlayerReferralInfoById({ id: searchInput.id });
          response.data.id = searchInput.id;
          referralInfo.value = [response.data]; 

        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    }




    const add = debounce(async () => {
      const memberDetails = await apiService.getMemberById({ id: searchInput.id });
      const memberWalletRes = await apiService.getMemberWalletById({ id: searchInput.id });
      activeRow.value = { ...memberDetails.data, affiliateUsername: memberDetails.data.username, affiliateBalance: memberWalletRes.data.walletBalance.toFixed(2) };
      modalTitle.value = 'Add Member (Affiliate : ' + memberDetails.data.username + ')';
      isEdit.value = false;
      window.showModal('memberModal', false);
    }, 300);


    async function editRow(row) {

      try {
        const userDetails = await apiService.getMemberBetById({ id: row.id });

        let data = { ...userDetails.data, eventId: row.eventId, username: row.username, statusId: row.statusId };
        modalTitle.value = 'Edit Bet';
        activeRow.value = data;
        isEdit.value = true;
        window.showModal('betModal', false);
      } catch (error) {
        console.error('Failed to fetch event details:', error);
      }

    }

    function sort(key) {
      if (sorting.sortBy === key) {
        sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        sorting.sortBy = key;
        sorting.sortOrder = 'asc';
      }
    }


    function modalClosedHandler() {
      fetchPlayerReferee();
    }


    function onPageChange(newPage) {
      pagination.currentPage = newPage;
    }

    function onPerPageChange(newPerPage) {
      pagination.perPage = newPerPage;
      pagination.currentPage = 1;
      fetchPlayerReferee();
    }

    function getStatusLabel(statusId) {
      return Object.keys(EventStatusEnum).find(key => EventStatusEnum[key] === statusId);
    }

    function getStatusColor(statusId) {
      switch (statusId) {
        case EventStatusEnum.Draft:
          return 'status-label badge border-none  ';
        case EventStatusEnum.Pending:
          return 'status-label badge border-none  bg-warning';
        case EventStatusEnum.Running:
          return 'status-label badge border-none  bg-info';
        case EventStatusEnum.Completed:
          return 'status-label badge border-none  bg-success';
        case EventStatusEnum.Cancelled:
          return 'status-label badge border-none  bg-error';
        default:
          return 'status-label badge border-none  bg-secondary';
      }
    }

    function onLoginIdSelect(selectedOption) {
      const selectedId = loginIdSuggestions.value
        .filter(item => item.username === selectedOption).map(item => item.id);
      if (selectedId.length > 0) {
        if (autocompleteRef.value) {
          autocompleteRef.value.setQuery(selectedOption);
        }
        searchInput.id = selectedId[0];
        fetchPlayerReferee();
        fetchPlayerReferralInfo();
      }
    }



    const viewCommission = debounce(async (row) => {
      const memberDetails = await apiService.getMemberById({ id: row.id });
      selectedRefereeId.value = row.id;
      memberName.value = memberDetails.data.username
      modalTitle.value = 'Total Earned Commission ';
      isEdit.value = false;
      window.showModal('infoModal', false);
    }, 300);


    function handleRowSelected(selectedRow) {
      const selectedId = loginIdSuggestions.value
        .filter(item => item.username === selectedRow.username).map(item => item.id);
      if (selectedId.length > 0) {
        if (autocompleteRef.value) {
          autocompleteRef.value.setQuery(selectedRow.username);
        }
        searchInput.id = selectedId[0];
        fetchPlayerReferee();
        fetchPlayerReferralInfo();
        isDrawerOpen.value = false;
      }
    }

    async function fetchAllMembers() {
      try {
        const response = await apiService.getMembers();
        if (response.data && response.data.players) {
          loginIdSuggestions.value = response.data.players;
          filteredMembers.value = response.data.players;
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    async function getMemberships() {
      try {
        const response = await apiService.getMemberships();
        memberships.value = response.data.memberships;
      } catch (error) {
        console.error('error:', error);
      }
    }

    fetchAllMembers();
    getMemberships();


    return {
      autocompleteRef,
      searchInput,
      sorting,
      pagination,
      rows,
      referralInfo,
      statusArray,
      totalPages,
      tableColumns,
      tableInfoColumns,
      tableMemberColumns,
      activeRow,
      teams,
      modalTitle,
      isEdit,
      loginIdSuggestions,
      isDrawerOpen,
      filteredMembers,
      searchQuery,
      memberships,
      memberName,
      modalClosedHandler,
      onPageChange,
      onPerPageChange,
      sort,
      getStatusColor,
      getStatusLabel,
      add,
      onLoginIdSelect,
      editRow,
      toggleDrawer,
      handleSearch,
      handleRowSelected,
      viewCommission,
      selectedRefereeId
    };
  }
};
</script>

<style scoped>
.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-success {
  background-color: #18a689 !important;
}

.btn-loginId {
  font-size: 13px;
  font-weight: 600;

}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    cursor: pointer;
}

.table>tbody>tr:hover{
 transform: scale(1.1);
}
</style>