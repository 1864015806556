<template>
  <v-select v-model="internalValue"  :options="computedOptions" :reduce="option => option.value"></v-select>
</template>
  
<script>
export default {
  props: {
    value: { // for v-model
      required: false
    },
    options: { // Array of objects
      type: Array,
      required: true
    },
    labelField: { // Field name for the label
      type: String,
      required: true
    },
    valueField: { // Field name for the value
      type: String,
      required: true
    },
    includeAllOption: { // Flag to include 'All' option
      type: Boolean,
      default: true
    }
  },
  computed: {
    computedOptions() {
      const options = this.options.map(option => ({
        label: option[this.labelField],
        value: option[this.valueField]
      }));
      if (this.includeAllOption) {
        return [{ label: 'All', value: 0 }].concat(options);
      }
      return options;
    }
  },
  data() {
    return {
      internalValue: this.value
    };
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal, oldVal) {
      this.$emit('input', newVal);
      if (newVal !== oldVal) {
        this.$emit('change', newVal); // Emitting a custom change event
      }
    }
  }
};
</script>
  