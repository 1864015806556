<template>
  <div class="parent-container">
    <div class="middle-box text-center loginscreen animated fadeInDown">
      <div>
        <h3 style="font-size: 20px; font-weight: bold;">Welcome to InvestPro
          <Icon icon="fa-copyright" />
        </h3>
        <p style="padding: 15px 0;">Sign in today for more experience.</p>
        <form class="m-t login-form-control" @submit.prevent="login">
          <InputField type="text" placeholder="Login ID" v-model="credentials.username" required />
          <InputField type="password" placeholder="Password" v-model="credentials.password" required />
          <Button button-type="submit" button-class="btn-primary block full-width m-b" button-text="Login" />
        </form>
        <p v-if="loginError" class="text-danger">{{ loginError }}</p>
        <p class="m-t"> <small>InvestPro System version 1.0.0</small> </p>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '../Element/InputField.vue';
import Button from '../Element/Button.vue';
import Icon from '../Element/Icon.vue';
import apiService from '@/components/Services/apiService';

export default {
  components: {
    Button,
    InputField,
    Icon
  },
  data() {
    return {
      credentials: {
        username: '',
        password: ''
      },
      loginError: null // Don't forget to define this if you're using it
    };
  },
  methods: {
    async login() {
      try {
        const response = await apiService.login(this.credentials);
        if (response.status == 200) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('systemUserId', response.data.systemUserId);

          localStorage.setItem('firstTimeLogin', true);
          this.$router.push('/dashboard');

          const userDetails = await apiService.getUserById({ id: response.data.systemUserId });

          if (userDetails) {

            localStorage.setItem('username', userDetails.data.username);
            localStorage.setItem('role', userDetails.data.accessControlName);

          }
        } else {
          this.loginError = response.message;
        }

      } catch (error) {
        this.loginError = 'Failed to log in. Please check your credentials.';
      }
    }
  }
};
</script>
<style>
.login-form-control>div>input {
  padding: 9px !important;
  border: 1px solid #c3c3c366 !important
}
</style>