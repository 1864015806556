<template>
  <div class="tooltip-container">
    <font-awesome-icon :icon="computedIcon" :class="iconClass" />
    <span v-if="tooltip" class="tooltip-text">{{ tooltip }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  computed: {
    computedIcon() {
      return ['fas', this.icon];
    }
  }
};
</script>

<style>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 45px;
  background-color: #676767;
  color: white;
  text-align: center;
  font-size: 10px;
  border-radius: 5px;
  padding: 3px 0;
  position: absolute;
  z-index: 1;
  top: 80%;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}
</style>
