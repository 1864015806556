<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <!-- Dynamic content for your form inputs -->
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="division-name" class="form-label">Division Name</label>
              <input type="text" class="form-control" id="divisionName" v-model="this.data.divisionName" required>
            </div>

            <div class="mb-3">
              <label for="division-name" class="form-label">Division Chinese Name</label>
              <input type="text" class="form-control" id="divisionChineseName" v-model="this.data.divisionChineseName"
                required>
            </div>

            <div class="mb-3">
              <label for="division-name" class="form-label">Home Team Name</label>
              <input type="text" class="form-control" id="homeTeamName" v-model="this.data.homeTeamName" required>
            </div>

            <div class="mb-3">
              <label for="division-name" class="form-label">Home Team Chinese Name</label>
              <input type="text" class="form-control" id="homeTeamChineseName" v-model="this.data.homeTeamChineseName"
                required>
            </div>

            <div class="mb-3">
              <label for="division-name" class="form-label">Away Team Name</label>
              <input type="text" class="form-control" id="awayTeamName" v-model="this.data.awayTeamName" required>
            </div>

            <div class="mb-3">
              <label for="division-name" class="form-label">Away Team Chinese Name</label>
              <input type="text" class="form-control" id="awayTeamChineseName" v-model="this.data.awayTeamChineseName"
                required>
            </div>

            <div class="mb-3">
              <label for="division-name" class="form-label">Home Team Logo Url</label>
              <div style="    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;">
                <input type="text" class="form-control" id="awayTeamChineseName" disabled
                  v-model="this.data.homeTeamLogoUrl" required>
                <img :src="this.data.homeTeamLogoUrl" style="height: 80px;">
              </div>

            </div>

            <div class="mb-3">
              <label for="division-name" class="form-label">Away Team Logo Url</label>
              <div style="    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;">
                <input type="text" class="form-control" id="awayTeamChineseName" disabled
                  v-model="this.data.awayTeamLogoUrl" required>
                <img :src="this.data.awayTeamLogoUrl" style="height: 80px;">
              </div>
            </div>
            <div class="mb-3">
              <label for="division-name" class="form-label">Kick-off Time</label>
              <input type="datetime-local" class="form-control" placeholder="Kick-off Date" v-model="eventAt"
                required />

            </div>
            <div style="display: flex; gap: 10px; justify-content: flex-end;">
              <button type="button" @click="cancel" class="btn btn-danger">Cancel</button>
              <button type="button" @click="approve" class="btn btn-primary">Approve</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
import { ImportedStatusEnum } from '@/enum/importedStatus';
const toast = useToast();
export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: { ...this.row },
      eventAt: '',
    };
  },
  methods: {
    createDefaultData() {
      // Return the default structure for division data
      return {
        name: '',
      };
    },
    validateFormData() {
      const fieldsToValidate = ['divisionName', 'divisionChineseName', 'homeTeamName', 'homeTeamChineseName', 'awayTeamName', 'awayTeamChineseName', 'homeTeamLogoUrl', 'awayTeamLogoUrl', 'eventAt'];

      for (const field of fieldsToValidate) {
        if (!this.data[field] || this.data[field].trim() === '') {
          toast.error('Please fill out all fields before proceeding.');
          return false;
        }
      }

      return true; // All fields are valid
    },
    formatToUTCMinus8(dateString) {
      if (!dateString) return '';

      let date = new Date(dateString + 'Z'); // Assuming dateString is in UTC
      date = new Date(date.getTime() - (8 * 60 * 60 * 1000));

      const isoString = date.toISOString();
      return isoString.substring(0, 16); // Removes seconds and timezone part, not needed for datetime-local
    },

    formatToLocalDateTimeInput(dateString) {
      if (!dateString) return '';

      // Create a Date object in UTC
      let date = new Date(dateString + 'Z'); // Assuming dateString is in UTC

      // Convert to UTC+8
      date = new Date(date.getTime() + (8 * 60 * 60 * 1000));

      // Format to YYYY-MM-DDTHH:mm which is the format datetime-local expects
      const isoString = date.toISOString();
      return isoString.substring(0, 16); // Removes seconds and timezone part, not needed for datetime-local
    },
    async approve() {
      try {
        if (!this.validateFormData()) return;

        // Prepare modified eventAt for API call
        const eventAtForAPI = this.formatToUTCMinus8(this.eventAt);

        // Clone data object and replace eventAt with the modified value
        const dataForAPI = { ...this.data, eventAt: eventAtForAPI };

        dataForAPI.statusId = ImportedStatusEnum.Imported;
        console.log(dataForAPI)
        const resp = await apiService.updateImportEventById(dataForAPI);
        if (resp.status === 200) {
          this.hideModal();
          toast.success(`Update Success`);
        } else {
          console.error('error:', resp);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    async cancel() {
      try {

        this.data.statusId = ImportedStatusEnum.Cancelled
        const resp = await apiService.updateImportEventById(this.data);
        if (resp.status === 200) {
          this.hideModal();
          toast.success(`Update Success`);
        } else {
          console.error('error:', resp);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    resetForm() {
      this.data = this.createDefaultData();
    },
  },
  watch: {
    // Whenever the row prop changes, update the data properties
    row(newRow) {
      this.data = { ...newRow };

      if (this.data.eventAt) {
       this.eventAt = this.formatToLocalDateTimeInput(this.data.eventAt);
      }
    }
  }
};
</script>