
<template>
    <div class="autocomplete" ref="autoComplete">
        <input type="text" class="form-control" :placeholder="placeholder" v-model="searchQuery" ref="inputRef"
            @keyup="onInput" :disabled="disabled" />
        <ul v-if="filteredSuggestions.length && showSuggestions">
            <li v-for="(suggestion, index) in filteredSuggestions" :key="index" @click="selectSuggestion(suggestion)">
                {{ suggestion }}
            </li>
        </ul>
    </div>
</template>
  
  
<script>
export default {
    name: "Autocomplete",
    props: {
        placeholder: String,
        suggestions: Array,
    },
    data() {
        return {
            searchQuery: "",
            showSuggestions: false,
            disabled: false
        };
    },
    computed: {
        filteredSuggestions() {
            if (!this.searchQuery) {
                return [];
            }
            return this.suggestions.filter(suggestion =>
                suggestion.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
    },
    methods: {
        focusTo() {
            if (this.$refs.inputRef) {
                this.$refs.inputRef.focus();
            }
        },
        onInput() {
            this.showSuggestions = true;

            this.$emit("input", this.searchQuery);
        },
        selectSuggestion(suggestion) {
            this.searchQuery = suggestion;
            this.showSuggestions = false;
            this.$emit("select", suggestion);
        },
        handleClickOutside(event) {
            if (this.showSuggestions && !this.$refs.autoComplete.contains(event.target)) {
                this.showSuggestions = false;
            }
        },
        clearSearch() {
            this.searchQuery = "";
            this.showSuggestions = false;
        },
        setQuery(query) {
            this.searchQuery = query;
            this.showSuggestions = false;
        },
        disable() {
            this.disabled = true;

        },
        enable() {
            this.disabled = false;

        },
    },
    mounted() {

        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
};
</script>
  
<style scoped>
.autocomplete {
    position: relative;
}

.autocomplete ul {
    list-style: none;
    padding: 5px;
    margin: 0;
    position: absolute;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    z-index: 100;
    max-height: 200px;
    overflow: auto;
    border-top: 0px;
}

.autocomplete li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.autocomplete li:hover {
    background: #1ab394 !important;
    color: white;
    border-radius: 5px;
}
</style>
  