<template>
    <div class="ibox">
        <div class="ibox-title">
            <h5>{{ title }}</h5>
            <div class="ibox-tools" v-if="isToggleEnabled">
                <a class="collapse-link" @click="toggleCollapse">
                    <i :class="collapsed ? 'fa fa-chevron-down' : 'fa fa-chevron-up'"></i>
                </a>
            </div>
        </div>
        <div class="ibox-content" v-show="!collapsed">
            <!-- Slot for dynamic content -->
            <slot></slot>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'IboxComponent',
    props: {
        title: {
            type: String,
            default: 'Default Title'
        },
        isToggleEnabled: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            collapsed: false
        };
    },
    methods: {
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        }
    }
};
</script>
<style>
.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>