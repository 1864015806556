<template>
    <div class="row" style="margin-bottom: 20px;">
        <div class="btn-group">
            <button v-for="(range, index) in dateRanges" :key="index" class="btn" :disabled="isDisabled"
                :class="{ 'btn-white active': activeRange === range.key, 'btn-white': activeRange !== range.key }"
                @click="setRange(range.key)">
                {{ range.label }}
            </button>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'DateRangeSelector',
    props: {
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeRange: null,
            dateRanges: [
                { key: 'today', label: 'Today' },
                { key: 'yesterday', label: 'Yesterday' },
                { key: 'thisWeek', label: 'This Week' },
                { key: 'lastWeek', label: 'Last Week' },
                { key: 'thisMonth', label: 'This Month' },
                { key: 'lastMonth', label: 'Last Month' }
            ],
        };
    },
    methods: {
        setRange(rangeKey) {
            if (this.activeRange === rangeKey) {
                return;
            }

            this.activeRange = rangeKey;
            let startDate = new Date();
            let endDate = new Date();

            switch (rangeKey) {
                case 'today':
                    // startDate is already set to today

                    break;
                case 'yesterday':
                    startDate.setDate(startDate.getDate() - 1);
                    endDate.setDate(endDate.getDate() - 1);

                    break;
                case 'thisWeek':
                    startDate.setDate(startDate.getDate() - startDate.getDay());

                    break;
                case 'lastWeek':
                    startDate.setDate(startDate.getDate() - startDate.getDay() - 7);
                    endDate.setDate(endDate.getDate() - endDate.getDay() - 1);

                    break;
                case 'thisMonth':
                    startDate.setDate(1);
                    endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

                    break;
                case 'lastMonth':
                    startDate.setMonth(startDate.getMonth() - 1, 1);
                    endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

                    break;
                default: break;

            }

            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);

            this.$emit('date-range-selected', {
                start: startDate.toISOString(),
                end: endDate.toISOString()
            });

        }
    }
};
</script>
  
<style>
.btn-blue {
    background-color: blue;
    color: white;
}

.btn-white {
    background-color: white;
    color: black;
}
</style>
