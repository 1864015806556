<template>
  <div class="col-lg-3">
    <div class="ibox">
      <div class="ibox-title">
        <Icon :icon="iconName" class="icon"></Icon>
        <h5> {{ title }}</h5>
      </div>
      <div class="ibox-content">
        <h1 class="no-margins">{{ value }}</h1>
        <small>{{ title }}</small>

      </div>
    </div>
  </div>
</template>
  
<script>

import Icon from '@/components/Element/Icon.vue';
export default {
  components: {
    Icon
  },
  props: {
    title: String,
    value: Number,
    tag: String,
    tagClass: String,
    iconName: String
  }
}
</script>
<style scoped>
.float-right {
  position: absolute;
  right: 10px;
}

.ibox {
  background: white;
}

.icon {
  margin: 0 0 7px;
  padding-right: 10px;
  font-size: 14px;
}
.ibox-title {
    
    display: flex;
    align-items: center;
}
</style>