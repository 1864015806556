<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <form class="m-t" @submit.prevent="search">
        <div class="wrapper wrapper-content animated fadeInRight ecommerce">


            <div class="ibox-content m-b-sm border-bottom">

                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="col-form-label" for="name">Login ID</label>
                            <Autocomplete placeholder="Search Login ID"
                                :suggestions="loginIdSuggestions.map(player => player.username)" @select="onLoginIdSelect"
                                ref="autocompleteRef" />

                        </div>

                    </div>
                    <div class="col-sm-3" style="    display: flex; align-items: flex-end;">
                        <div class="form-group">
                            <label class="col-form-label" for="name"></label>
                            <Button button-type="button" button-class="btn btn-w-m btn-success"
                                button-text="Show member list" @click="toggleDrawer" />

                        </div>

                    </div>


                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="col-form-label" for="role">Start Date</label>

                            <input type="date" class="form-control" placeholder="Date of Birth"
                                v-model="searchInput.startDateTime">

                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="col-form-label" for="role">End Date</label>

                            <input type="date" class="form-control" placeholder="Date of Birth"
                                v-model="searchInput.endDateTime">

                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="col-form-label" for="role">Event</label>

                            <SelectIncludeAll v-model="searchInput.eventId"  :options="events && events.length > 0 ? events.map(item => ({ label: item.homeTeamName +' vs ' + item.awayTeamName, value: item.id })) : []" labelField="label"
                                valueField="value">
                            </SelectIncludeAll>

                        </div>
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
                        <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
                            button-icon="fa-search" @click="getCommReport()" />

                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="ibox">
                        <div class="ibox-content table-responsive" v-if="selectedMemberDetails.length > 0">
                            <rows-per-page-select v-model="pagination.perPage"
                                @update:value="onPerPageChange"></rows-per-page-select>

                            <DynamicTable :columns="tableColumns" :rows="selectedMemberDetails" :sortingKey="sorting.sortBy"
                                :sortOrder="sorting.sortOrder" @sort-requested="sort">
                                <!-- Define slots for action buttons -->

                                <template v-slot:actionButton="{ row }">
                                    <!-- Your custom action button -->
                                    <Button button-type="button" button-class="btn btn-w-m btn-default btn-loginId"
                                        :button-text="row.playerUsername" @click="handleUplineSelected(row, row.playerUsername)" />
                                </template>
                                <template v-slot:actionButton2="{ row }">
                                    <!-- Your custom action button -->
                                    <Button button-type="button" button-class="btn btn-w-m btn-default btn-loginId"
                                        :button-text="row.referrerUsername" @click="handleUplineSelected(row, row.referrerUsername)" />
                                </template>

                                <template v-slot:statusButton="{ row }">


                                    <span :classList="getStatusColor(row.statusId)">
                                        {{ getStatusLabel(row.statusId) }}
                                    </span>
                                </template>

                            </DynamicTable>
                            <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                                @page-change="onPageChange"></pagination-component>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-for="(commissionData, index) in commData" :key="index">
                <div class="col-lg-12" v-if="commissionData && commissionData.commissionDetails.length > 0">
                    <div class="ibox">
                        <div class="ibox-title">
                            <h5 style="text-transform: capitalize;">{{ getIndexInWords(index) }} Generation</h5>
                        </div>
                        <div class="ibox-content table-responsive">
                            <DynamicTable :columns="tableColumns" :rows="commissionData.commissionDetails"
                                :sortingKey="sorting.sortBy" :sortOrder="sorting.sortOrder" @sort-requested="sort">
                                <template v-slot:actionButton="{ row }">
                                    <!-- Your custom action button -->
                                    <Button button-type="button" button-class="btn btn-w-m btn-default btn-loginId"
                                        :button-text="row.playerUsername" @click="handleUplineSelected(row, row.playerUsername)" />
                                </template>
                                <template v-slot:actionButton2="{ row }">
                                    <!-- Your custom action button -->
                                    <Button button-type="button" button-class="btn btn-w-m btn-default btn-loginId"
                                        :button-text="row.referrerUsername" @click="handleUplineSelected(row, row.referrerUsername)" />
                                </template>
                            </DynamicTable>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </form>

    <LeftDrawer v-model="isDrawerOpen" @update:isOpen="isDrawerOpen = $event">
        <!-- Content for the drawer -->


        <div class="row">
            <h3>Member List</h3>
            <div class="col-sm-3">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Search Login ID" v-model="searchQuery"
                        @keyup="handleSearch" />
                </div>

            </div>
            <div class="col-lg-12">
                <div class="ibox">
                    <div class="ibox-content table-responsive custom">

                        <DynamicTable :columns="tableMemberColumns" :rows="filteredMembers" :sortingKey="sorting.sortBy"
                            :sortOrder="sorting.sortOrder" @sort-requested="sort" @row-selected="handleRowSelected">

                        </DynamicTable>
                    </div>
                </div>
            </div>
        </div>
    </LeftDrawer>
</template>
  
  
  
<script>
import { ref, reactive, computed, watch } from 'vue';
import Button from '@/components/Element/Button.vue';
import { useToast } from 'vue-toastification';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import { EventStatusEnum } from '@/enum/eventStatus';
import apiService from '@/components/Services/apiService';
import Autocomplete from "@/components/Element/Autocomplete.vue";

import LeftDrawer from '@/components/Element/LeftDrawer.vue';
export default {
    components: {
        Button,
        DynamicTable,
        PaginationComponent,
        RowsPerPageSelect,
        Autocomplete,
        LeftDrawer,
        SelectIncludeAll
    },
    setup() {
        const autocompleteRef = ref(null);
        const rows = ref([]);
        const referralInfo = ref([]);
        const events = ref([]);
        const toast = useToast();
        const activeRow = ref({});
        const selectedMemberDetails = ref([]);
        const isEdit = ref();
        const searchQuery = ref('');
        const filteredMembers = ref([]);
        const isDrawerOpen = ref(false);
        const commData = ref([]);
        const selectedRefereeId = ref();
        const memberName = ref();
        const loginIdSuggestions = ref([]);
        const searchInput = reactive({
            id: 0,
        });

        const sorting = reactive({
            sortBy: 'id',
            sortOrder: 'desc'
        });

        const pagination = reactive({
            currentPage: 1,
            perPage: 5,
            totalRows: 0
        });


        const debounce = (func, delay) => {
            let debounceTimer;
            return function () {
                const context = this;
                const args = arguments;
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => func.apply(context, args), delay);
            }
        };


        const getIndexInWords = (index) => {
            const ordinals = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth", "tenth", "eleventh", "twelfth", "thirteenth", "fourteenth", "fifteenth", "sixteenth", "seventeenth", "eighteenth", "nineteenth", "twentieth"];
            return ordinals[index] || `${index + 1}th`;
        }
        const handleSearch = () => {
            if (searchQuery.value) {
                filteredMembers.value = loginIdSuggestions.value.filter((member) =>
                    member.username.toLowerCase().includes(searchQuery.value.toLowerCase())
                );

            } else {
                filteredMembers.value = loginIdSuggestions.value;
            }
        };

        watch(searchQuery, handleSearch);
        const toggleDrawer = debounce(() => {
            isDrawerOpen.value = !isDrawerOpen.value;
        }, 300);




        const statusArray = computed(() =>
            Object.entries(EventStatusEnum).map(([label, id]) => ({ id, label }))
        );

        const totalPages = computed(() =>
            Math.ceil(pagination.totalRows / pagination.perPage)
        );

        const tableColumns = [
            { name: 'Login ID', key: 'playerUsername', isSortable: false, isAction: true, slotName: 'actionButton' },
            { name: 'Upline', key: 'referrerUsername', isSortable: true, isAction: true, slotName: 'actionButton2' },
            { name: 'Membership level', key: 'membershipName', isSortable: true, isAction: false },
            { name: 'Bet Amount', key: 'betAmount', isSortable: true, isAction: false, isNumber: true },
            { name: 'Brokerage Fees', key: 'processingFeeAmount', isSortable: true, isAction: false, isNumber: true },
            { name: 'Agent Comm.', key: 'agentCommissionAmount', isSortable: true, isAction: false, isNumber: true },
            { name: 'Rank Bonus', key: 'rankCommissionAmount', isSortable: true, isAction: false, isNumber: true },
            { name: 'Generation Reward', key: 'generationCommissionAmount', isSortable: true, isAction: false, isNumber: true, },
        ]

        const tableMemberColumns = [
            { name: 'Login ID', key: 'username', isSortable: false, isAction: false },
            { name: 'Full Name', key: 'name', isSortable: true, isAction: false },
            { name: 'Joined Date', key: 'createdAt', isSortable: true, isAction: false, isDate: true },
        ]

        async function getCommReport() {
            if (searchInput.playerId) {
                const skip = (pagination.currentPage - 1) * pagination.perPage;
                const params = { ...searchInput, offset: skip, limit: pagination.perPage, sort: sorting.sortBy, order: sorting.sortOrder };
                try {
                    const response = await apiService.getCommReport(params);
                    if (response.data.commission) {

                        selectedMemberDetails.value = [...response.data.commission.commissionDetails];
                    }
                    if (response.data.generations) {

                        commData.value = response.data.generations.map(item => item.commission);
                    }
                } catch (error) {
                    console.log(error);
                    toast.error(error.response.data.message);
                }
            }

        }



        function sort(key) {
            if (sorting.sortBy === key) {
                sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                sorting.sortBy = key;
                sorting.sortOrder = 'asc';
            }
        }


        function modalClosedHandler() {
            getCommReport();
        }


        function onPageChange(newPage) {
            pagination.currentPage = newPage;
        }

        function onPerPageChange(newPerPage) {
            pagination.perPage = newPerPage;
            pagination.currentPage = 1;
            getCommReport();
        }

        function getStatusLabel(statusId) {
            return Object.keys(EventStatusEnum).find(key => EventStatusEnum[key] === statusId);
        }

        function getStatusColor(statusId) {
            switch (statusId) {
                case EventStatusEnum.Draft:
                    return 'status-label badge border-none  ';
                case EventStatusEnum.Pending:
                    return 'status-label badge border-none  bg-warning';
                case EventStatusEnum.Running:
                    return 'status-label badge border-none  bg-info';
                case EventStatusEnum.Completed:
                    return 'status-label badge border-none  bg-success';
                case EventStatusEnum.Cancelled:
                    return 'status-label badge border-none  bg-error';
                default:
                    return 'status-label badge border-none  bg-secondary';
            }
        }

        function onLoginIdSelect(selectedOption) {
            const selectedId = loginIdSuggestions.value
                .filter(item => item.username === selectedOption).map(item => item.id);
            if (selectedId.length > 0) {
                if (autocompleteRef.value) {
                    autocompleteRef.value.setQuery(selectedOption);
                }
                searchInput.playerId = selectedId[0];
                getCommReport();
            }
        }



        function handleRowSelected(selectedRow) {
            console.log(selectedRow);
            const selectedId = loginIdSuggestions.value
                .filter(item => item.username === selectedRow.username).map(item => item.id);
            if (selectedId.length > 0) {
                if (autocompleteRef.value) {
                    autocompleteRef.value.setQuery(selectedRow.username);
                }
                searchInput.playerId = selectedId[0];
                isDrawerOpen.value = false;
            }
        }

        function handleUplineSelected(selectedRow, username) {
            console.log(selectedRow);
            const selectedId = loginIdSuggestions.value
                .filter(item => item.id === selectedRow.playerId).map(item => item.id);
            if (selectedId.length > 0) {
                if (autocompleteRef.value) {
                    autocompleteRef.value.setQuery(username);
                }
                searchInput.playerId = selectedId[0];
                getCommReport();
                isDrawerOpen.value = false;
            }
        }


        async function fetchAllMembers() {
            try {
                const response = await apiService.getMembers();
                if (response.data && response.data.players) {
                    loginIdSuggestions.value = response.data.players;
                    filteredMembers.value = response.data.players;
                } else {
                    console.error("Invalid response structure:", response);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        }

        async function fetchEvents() {
            try {
                const response = await apiService.getEvents();
                events.value = response.data.events;

            } catch (error) {
                toast.error('Error fetching events');
                console.error('Error fetching events:', error);
            }
        }


        fetchAllMembers();
        fetchEvents();

        return {
            autocompleteRef,
            searchInput,
            sorting,
            pagination,
            rows,
            referralInfo,
            statusArray,
            totalPages,
            tableColumns,
            tableMemberColumns,
            activeRow,
            events,
            isEdit,
            loginIdSuggestions,
            selectedMemberDetails,
            isDrawerOpen,
            filteredMembers,
            searchQuery,
            memberName,
            commData,
            modalClosedHandler,
            onPageChange,
            onPerPageChange,
            sort,
            getStatusColor,
            getStatusLabel,
            onLoginIdSelect,
            toggleDrawer,
            handleSearch,
            handleRowSelected,
            getIndexInWords,
            getCommReport,
            handleUplineSelected,
            selectedRefereeId
        };
    }
};
</script>
  
<style scoped>
.bg-danger {
    background-color: #dc3545 !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

.bg-success {
    background-color: #18a689 !important;
}

.btn-loginId {
    font-size: 13px;
    font-weight: 600;

}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    cursor: pointer;
}

::v-deep(.custom>.table>tbody>tr:hover) {
    transform: scale(1.1);
}

</style>