<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <button :type="buttonType" :class="['btn', buttonClass]" :disabled="disabled" @click="handleClick">
    <slot>
      <Icon v-if="buttonIcon" :icon="buttonIcon" class="side-menu-icon" />{{ buttonText }}
     
    </slot>
  </button>
</template>
  
<script>

import Icon from '@/components/Element/Icon.vue';
export default {
  props: {
    buttonText: {
      type: String,
      default: 'Click me'
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: 'button' // or 'submit', 'reset', etc.
    },
    buttonClass: {
      type: String,
      default: 'btn-primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Icon
  },
  methods: {
    handleClick(event) {
      // Emit an event for parent components
      this.$emit('click', event);
    }
  }
};
</script>
  