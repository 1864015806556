<template>
    <div class="d-flex justify-content-end mb-2">
      <div class="d-flex align-items-center">
        <label for="rowsPerPage" class="me-2">Rows per page:</label>
        <select id="rowsPerPage" class="form-select" v-model="internalPerPage" @change="onChange" style="width: auto;">
          <option v-for="option in options" :key="option" :value="option">{{ option }}</option>
        </select>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: { // for v-model
        type: Number,
        required: false,
        default: 5
      },
      options: { // Array of page size options
        type: Array,
        default: () => [5, 10, 20]
      }
    },
    data() {
      return {
        internalPerPage: this.value
      };
    },
    methods: {
      onChange() {
        this.$emit('update:value', this.internalPerPage);
      }
    },
    watch: {
      value(newVal) {
        this.internalPerPage = newVal;
      }
    }
  };
  </script>
  