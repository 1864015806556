<template>
  <div id="app">
    <!-- Show loading screen when loading is true -->
    <div v-if="loading" class="loading-overlay">
      <div class="loading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <!-- Show router view when loading is done -->
    <router-view v-else></router-view>
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  setup() {
    const loading = ref(true); // start with loading set to true
    const router = useRouter();
    const route = useRoute();

    onBeforeMount(() => {
      // Before the app mounts, subscribe to route changes
      router.beforeEach((to, from, next) => {
        // Trigger loading state
        loading.value = true;
        next();
      });

      router.afterEach(() => {
        // When each route is resolved (async components loaded), hide the loading screen
        loading.value = false;
      });
    });

    // Initial check in case we're accessing the route directly
    if (route.matched.length > 0) {
      loading.value = false;
    }

    return { loading };
  },
};
</script>

<style>
.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #ebebeb;
}

.loading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #49a84c;
  --third-color: #f6bb02;
  --fourth-color: #f6bb02;
  --fifth-color: #2196f3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  gap: 6px;
  height: 100px;
}

.loading span {
  width: 4px;
  height: 50px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.loading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.loading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.loading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.loading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}

@keyframes scale {

  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1);
  }
}
</style>
