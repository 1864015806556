<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeButton" @click="hideModal"></button>
        </div>
        <div class="modal-body">
          <!-- Dynamic content for your form inputs -->
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="division-name" class="form-label">Team Name</label>
              <input type="text" class="form-control" id="division-name" v-model="this.teamData.name" required>
            </div>
            <div class="mb-3">
              <label for="chinese-division-name" class="form-label">Team Chinese Name</label>
              <input type="text" class="form-control" id="chinese-division-name" v-model="this.teamData.chineseName"
                required>
            </div>
            <div class="mb-3">
              <label for="accessControlId" class="form-label">Division</label>

              <select class="form-select" v-model="teamData.divisionId" required>
                <option v-for="(division, index) in divisions" :key="index" :value="division.id">
                  {{ division.name }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="file-upload" class="form-label">Upload File</label>
              <input type="file" class="form-control" id="file-upload" @change="handleFileUpload" :required="!isEdit">
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
const toast = useToast();
export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      teamData: { ...this.row },
      divisions: [],
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      const maxSize = 5245329;

      if (file) {
        if (file.size > maxSize) {
          toast.error('File size should not exceed 5MB');
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.teamData.logoFile = e.target.result.split(',').pop();
          this.teamData.logoFileExtension = file.name.split('.').pop(); // file extension
        };
        reader.readAsDataURL(file);
      }
    },

    async submitForm() {
      try {

        if (!this.isEdit) {
          const response = await apiService.createTeam(this.teamData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Create Team Success");
          } else {
            console.error('error:', response);
          }

        } else {
          const response = await apiService.updateTeamInformation(
            {
              id: this.teamData.id,
              name: this.teamData.name,
              chineseName: this.teamData.chineseName,
              divisionId: this.teamData.divisionId,
              logoFileExtension: this.teamData.logoFileExtension,
              logoFile: this.teamData.logoFile,
            }
          );
          if (response.status === 200) {
            this.hideModal();
            toast.success("Update Team Information Success");
          } else {
            console.error('error:', response);
          }
        }

      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    resetForm() {
      this.teamData = {
        name: '',
        chineseName: '',
        divisionId: null, // Assuming the initial state is null
        logoFile: null,
        logoFileExtension: null,
      };

    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    async getDivisions() {
      try {
        const response = await apiService.getDivisions();
        this.divisions = response.data.divisions;
      } catch (error) {
        console.error('error:', error);
      }
    }
  },
  mounted() {
    this.getDivisions();
  },
  watch: {
    // Whenever the row prop changes, update the data properties
    row(newRow) {
      this.teamData = { ...newRow };
    }
  }
};
</script>
  