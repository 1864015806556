
<template>
    <div class="table-responsive">
        <rows-per-page-select v-model="pagination.perPage" @update:value="onPerPageChange"></rows-per-page-select>
        <table class="table table-hover table-bordered ">
            <thead class="table-light">
                <tr>
                    <th class="text-center">Action</th>
                    <th @click="sort('name')">
                        Role Name
                        <span v-if="sorting.sortBy === 'name'">
                            <span v-if="sorting.sortOrder === 'asc'">&#9650;</span> <!-- Up arrow -->
                            <span v-else>&#9660;</span> <!-- Down arrow -->
                        </span>
                    </th>
                    <th class="text-center" @click="sort('statusId')">
                        Status
                        <span v-if="sorting.sortBy === 'statusId'">
                            <span v-if="sorting.sortOrder === 'asc'">&#9650;</span> <!-- Up arrow -->
                            <span v-else>&#9660;</span> <!-- Down arrow -->
                        </span>
                    </th>

                    <th @click="sort('createdAt')">
                        Created Timestamp
                        <span v-if="sorting.sortBy === 'createdAt'">
                            <span v-if="sorting.sortOrder === 'asc'">&#9650;</span> <!-- Up arrow -->
                            <span v-else>&#9660;</span> <!-- Down arrow -->
                        </span>
                    </th>

                    <th @click="sort('updatedAt')">
                        Last Modified Timestamp
                        <span v-if="sorting.sortBy === 'updatedAt'">
                            <span v-if="sorting.sortOrder === 'asc'">&#9650;</span> <!-- Up arrow -->
                            <span v-else>&#9660;</span> <!-- Down arrow -->
                        </span>
                    </th>


                </tr>
            </thead>
            <tbody>
                <tr v-for="row in rows" :key="row.id">
                    <td class="text-center actionDiv">
                        <Icon icon="fa-edit" class="edit-icon" tooltip="Edit" @click="editRow(row)"></Icon>
                    </td>
                    <td>{{ row.name }}</td>
                    <td class="text-center">
                        <span :class="[' status-label  badge', row.statusId === 1 ? 'bg-primary' : 'bg-danger']"
                            @click="updateStatus(row)">
                            {{ row.statusId === 1 ? 'Active' : 'Inactive' }}
                        </span>
                    </td>
                    <td>{{ formatDate(row.createdAt) }}</td>
                    <td>{{ formatDate(row.updatedAt) }}</td>

                </tr>
            </tbody>
        </table>

        <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
            @page-change="onPageChange"></pagination-component>
    </div>
    <dynamic-modal :modalId="'editModal'" :title="'Edit Role'" :is-edit="true" :row="activeRow"
        @modalClose="modalClosedHandler" />
</template>


<script>
import { ref, reactive, onMounted, watch } from 'vue';
import apiService from '@/components/Services/apiService';
import Icon from '@/components/Element/Icon.vue';
import { useToast } from 'vue-toastification';
import DynamicModal from '@/components/Views/SystemSetting/Role/DynamicModal.vue';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
export default {
    components: {
        Icon,
        DynamicModal,
        PaginationComponent,
        RowsPerPageSelect
    },
    computed: {
        totalPages() {
            return Math.ceil(this.pagination.totalRows / this.pagination.perPage);
        }
    },
    props: {
        searchCriteria: {
            type: Object,
            default: () => ({}),
        },
    }
    ,
    setup(props) {
        const loading = ref(false);

        const toast = useToast();
        const activeRow = ref({});
        const rows = ref([]);
        const pagination = reactive({
            currentPage: 1,
            perPage: 5,
            totalRows: 0
        });
        const sorting = reactive({
            sortBy: 'id',
            sortOrder: 'desc'
        });
        // Watcher to refetch data when searchCriteria or currentPage changes
        watch([() => props.searchCriteria, () => pagination.currentPage], fetchRoles, { deep: true });

        onMounted(fetchRoles);

        async function fetchRoles() {
            if (loading.value) return; // Prevent duplicate calls
            loading.value = true;
            const skip = (pagination.currentPage - 1) * pagination.perPage;
            const params = {
                ...props.searchCriteria,
                offset: skip,
                limit: pagination.perPage,
            };

            // Only add sort and order if they have values
            if (sorting.sortBy) {
                params.sort = sorting.sortBy;
                params.order = sorting.sortOrder;
            }

            try {
                const response = await apiService.getRole(params);
                rows.value = response.data.accessControls;
                pagination.totalRows = response.data.count;
            } catch (error) {
                toast.error(error.response.data.message);
            }

            loading.value = false;
        }

        function modalClosedHandler() {
            fetchRoles();
        }

        function onPerPageChange(newPerPage) {

            pagination.perPage = newPerPage;
            pagination.currentPage = 1;
            fetchRoles();
        }


        function sort(field) {
            if (sorting.sortBy === field) {
                sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                sorting.sortBy = field;
                sorting.sortOrder = 'asc';
            }
            fetchRoles();
        }


        function formatDate(dateString) {

            if (dateString) {
                const date = new Date(dateString);

                date.setHours(date.getHours() + 8);
                return date.toLocaleDateString('en-US', {
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
                });
            } else {
                return '-'
            }

        }

        async function updateStatus(row) {
            try {
                const newStatus = row.statusId === 1 ? 2 : 1;

                const response = await apiService.updateRoleStatusById({
                    id: row.id,
                    status: newStatus
                });

                if (response.status === 200) {
                    toast.success(`Role status updated to ${newStatus === 1 ? 'Active' : 'Inactive'}`);
                    fetchRoles();
                } else {
                    toast.error('Failed to update role status');
                }
            } catch (error) {
                toast.error(error.response.data.message);
            }
        }


        async function editRow(row) {

            try {
                const roleDetails = await apiService.getRoleById({ id: row.id });
                activeRow.value = { ...roleDetails.data };

                console.log(roleDetails);
                window.showModal('editModal', true);
            } catch (error) {
                toast.error(error.response.data.message);
            }

        }

        // Method to handle page change from pagination controls
        function onPageChange(newPage) {
            pagination.currentPage = newPage;
        }

        return {
            rows,
            pagination,
            sorting,
            activeRow,
            editRow,
            formatDate,
            sort,
            updateStatus,
            onPageChange,
            onPerPageChange,
            modalClosedHandler
        };
    }
};
</script>


<style>
.text-center {
    text-align: center !important;
}

.edit-icon {
    cursor: pointer;
}

.status-label {
    padding: 0.55em 0.5em !important;
    border-radius: 4px;
    color: white;
    display: inline-block;
    cursor: pointer;
    width: 100px !important;
}

.status-label.active {
    background-color: #38b2ac;
}
</style>
