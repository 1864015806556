<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm" aria-label="Close"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm" autocomplete="off">


            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Login ID</label>
              <Autocomplete placeholder="Search Login ID" ref="autocompleteRef"
                :suggestions="loginIdSuggestions.map(player => player.username)" @select="onLoginIdSelect" />
            </div>
            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Balance</label>
              <input type="text" class="form-control" v-model="transactionData.balance" disabled>
            </div>
            <div v-if="isPlayerSelected">
              <div class="mb-3  grid-3-7" v-if="!isEdit">
                <label for="accessControlId" class="form-label">Event</label>

                <SelectIncludeAll v-model="this.transactionData.eventId" :disabled="isEdit"
                  :options="events && events.length > 0 ? events.map(item => ({ label: item.homeTeamName + ' vs ' + item.awayTeamName, value: item.id })) : []"
                  labelField="label" valueField="value" :includeAllOption="false" @change="onEventIdChange">
                </SelectIncludeAll>

              </div>
              <div class="mb-3  grid-3-7" v-if="isEdit">
              <label for="login-id" class="form-label">Event</label>
              <input type="text" class="form-control" v-model="transactionData.teams" disabled>
            </div>
              <div class="mb-3  grid-3-7" v-if="isEdit">
                <label for="levelUpDay" class="form-label">Status</label>


                <span :classList="this.getStatusColor(this.transactionData.statusId)">
                  {{ this.getStatusLabel(this.transactionData.statusId) }}
                </span>
              </div>
              <div class="mb-3 grid-3-7" v-if="halfTimeScores.length > 0 || fullTimeScores.length > 0">
                <label for="score-select" class="form-label">Score</label>
                <select id="score-select" class="form-control" v-model="selectedScore">
                  <optgroup label="Half Time" v-if="halfTimeScores.length > 0">
                    <option v-for="score in halfTimeScores" :value="score.value" :key="score.value">{{ score.label }}
                    </option>
                  </optgroup>
                  <optgroup label="Full Time" v-if="fullTimeScores.length > 0">
                    <option v-for="score in fullTimeScores" :value="score.value" :key="score.value">{{ score.label }}
                    </option>
                  </optgroup>
                </select>
              </div>
              <div class="mb-3  grid-3-7">
                <label for="login-id" class="form-label">Odds</label>
                <input type="number" class="form-control" v-model="transactionData.ratio" :disabled="true">
              </div>

              <div class="mb-3  grid-3-7">
                <label for="login-id" class="form-label">Available Volume</label>
                <input type="number" class="form-control" v-model="transactionData.availableVolume" :disabled="true">
              </div>
              <div class="mb-3  grid-3-7">
                <label for="login-id" class="form-label">Fake Volume</label>
                <input type="number" class="form-control" v-model="transactionData.fakeVolume" :disabled="true">
              </div>

              <div class="mb-3  grid-3-7">
                <label for="login-id" class="form-label">Bet Amount</label>
                <input type="number" class="form-control" v-model="transactionData.amount" ref="amount">
              </div>
              <div class="mb-3  grid-3-7">
                <label for="login-id" class="form-label">Processing Fee</label>
                <input type="number" class="form-control" v-model="transactionData.processingAmount" :disabled="true">
              </div>
              <div class="mb-3  grid-3-7" style="display: none;">
                <label for="login-id" class="form-label">Processing Fee</label>
                <input type="number" class="form-control" v-model="transactionData.processingFee" :disabled="true">
              </div>
              <div class="mb-3  grid-3-7">
                <label for="login-id" class="form-label">Profit</label>
                <input type="number" class="form-control" v-model="transactionData.profit" :disabled="true">
              </div>
              <div class="mb-3  grid-3-7">
                <label for="login-id" class="form-label">Settlement Amount</label>
                <input type="number" class="form-control" v-model="transactionData.settlementAmount" :disabled="true">
              </div>
            </div>


            <button type="submit" class="btn btn-primary" :disabled="!isEnabled">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import Autocomplete from "@/components/Element/Autocomplete.vue";
import apiService from '@/components/Services/apiService';
import { EventStatusEnum } from '@/enum/eventStatus';
const toast = useToast();


export default {
  name: 'DynamicModal',
  components: {
    Autocomplete,
    SelectIncludeAll
  },
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    loginIdSuggestions: {
      type: Object
    },
    // You can expand this object to include all the fields you need
    row: {
      type: Object,
      default: () => ({
        playerId: '',
        amount: '',
        eventOddId: '',
      })
    }
  },
  async created() {
    this.resetForm();
    this.halfOdds = await this.getOdds(1);
    this.fullOdds = await this.getOdds(2);
    this.getCommission();
    this.getEvents();
  },
  data() {
    return {
      // Create a local copy of the user data to edit
      transactionData: { ...this.row },
      isEnabled: false,
      isPlayerSelected: false,
      events: [],
      selectedEventId: null,
      eventOdds: [],
      selectedScore: null,
      oddsHalf: [],
      oddsFull: [],

      intiAmount: 0,
    };
  },
  computed: {
    halfTimeScores() {
      return this.eventOdds
        .filter(odd => odd.oddTypeId === 1)
        .map(odd => ({ label: this.getOddDetailsFromHalfOdds(odd.oddId), value: odd.id }));
    },
    fullTimeScores() {
      return this.eventOdds
        .filter(odd => odd.oddTypeId === 2)
        .map(odd => ({ label: this.getOddDetailsFromFullOdds(odd.oddId), value: odd.id }));
    },
  },
  methods: {
    async submitForm() {
      try {


        if (this.transactionData.amount <= 0 || this.transactionData.amount == undefined) {
          if (this.$refs.amount) {
            this.$refs.amount.focus();
          }
          toast.warning("Invalid bet amount (Value must be greater than 0) ");
          return;
        }
        if (!this.isEdit) {
          if (this.transactionData.balance < this.transactionData.amount) {
            toast.error("Insufficient Balance");
            return;
          }
          const response = await apiService.createMemberBet(this.transactionData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Create Bet Success");
          } else {
            console.error('error:', response);
          }
        } else {
          if (this.transactionData.balance < (this.transactionData.amount - this.intiAmount)) {
            toast.error("Insufficient Balance");
            return;
          }
          if (this.transactionData.statusId != EventStatusEnum.Pending) {
            toast.error("Invalid Status");
            return;
          }
          const response = await apiService.updateMemberBetStatusById(this.transactionData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Update Bet Success");
          } else {
            console.error('error:', response);
          }
        }

      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    async onLoginIdSelect(selectedOption) {
      this.transactionData.username = selectedOption;

      const selectedId = this.loginIdSuggestions
        .filter(permission => permission.username == selectedOption).map(permission => permission.id);
      if (selectedId) {
        if (selectedId[0]) {
          this.$refs.autocompleteRef.setQuery(selectedOption);
          this.transactionData.playerId = selectedId[0];
          const memberWalletRes = await apiService.getMemberWalletById({ id: selectedId[0] });
          this.transactionData.balance = memberWalletRes.data.walletBalance.toFixed(2);
          this.isPlayerSelected = true;
        } else {
          this.isPlayerSelected = false;
        }

      }
    },
    async getOdds(typeId) {
      try {
        const response = await apiService.getOdds({ typeId });
        return response.data.odds;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    getOddDetailsFromHalfOdds(oddId) {
      const odd = this.halfOdds.find(o => o.id === oddId);
      if (odd) {
        return odd.name;
      } else {
        return null; // or some default value if odd is not found
      }
    },
    getOddDetailsFromFullOdds(oddId) {
      const odd = this.fullOdds.find(o => o.id === oddId);
      if (odd) {
        return odd.name;
      } else {
        return null; // or some default value if odd is not found
      }
    },
    resetForm() {
      this.transactionData = {
        playerId: '',
        username: '',
        name: '',
        eventOddId: ' ',
        amount: '',
      };

      this.selectedScore = null;
      if (this.$refs.autocompleteRef) {
        this.$refs.autocompleteRef.clearSearch();
        this.$refs.autocompleteRef.enable();
      }

      this.getCommission();
      this.isPlayerSelected = false;
      this.isEnabled = false;

    },
    async getEvents() {
      try {
        const params = { statusId: EventStatusEnum.Pending };
          const response = await apiService.getEvents(params);
          this.events = response.data.events;

      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    async getCommission() {
      try {
        const response = await apiService.getCommissionSettings();
        this.transactionData.processingFee = response.data.processingFee.toFixed(2);
      } catch (error) {
        console.error('error:', error);
      }
    },
    async onEventIdChange(newEventId) {
      if (newEventId) {
        try {
          const response = await apiService.getEventById({ id: newEventId });
          // Process the response
          this.eventOdds = response.data.eventOdds;
          this.transactionData.availableVolume = 0;
          this.transactionData.fakeVolume = 0;
          this.transactionData.ratio = 0;
          this.transactionData.amount = 0;
          this.transactionData.profit = 0;
          this.transactionData.settlementAmount = 0;
          if (this.eventOdds.length <= 0) {
            toast.error("There is not available score selections.");
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    },
    updateTransactionDataWithScoreDetails(scoreId) {

      const selectedScoreDetails = this.eventOdds.find(odd => odd.id === scoreId);
      if (selectedScoreDetails) {
        this.transactionData.availableVolume = selectedScoreDetails.availableVolume.toFixed(2);
        this.transactionData.fakeVolume = selectedScoreDetails.availableFakeVolume.toFixed(2);
        this.transactionData.ratio = selectedScoreDetails.profit;
        this.transactionData.eventOddId = scoreId;
        this.calculateProfit();
        if (!this.isEdit)
          this.isEnabled = true;
      } else {
        // Handle the case when the score details are not found
        console.error('Selected score details not found.');
        this.transactionData.availableVolume = 0;
        this.transactionData.fakeVolume = 0;
        this.transactionData.ratio = 0;
        this.isEnabled = false;
      }
    },

    calculateProfit() {
      const amount = parseFloat(this.transactionData.amount);
      const ratio = parseFloat(this.transactionData.ratio);
      const processRate = parseFloat(this.transactionData.processingFee);

      if (!isNaN(amount) && !isNaN(ratio)) {
        this.transactionData.processingAmount = amount * processRate / 100;
        this.transactionData.profit = amount * ratio / 100;
        this.transactionData.profit = this.transactionData.profit.toFixed(2);
        this.transactionData.settlementAmount = amount * ratio / 100;
        this.transactionData.settlementAmount = this.transactionData.settlementAmount.toFixed(2);

      } else {
        this.transactionData.profit = 0;
      }
    },

    getStatusLabel(statusId) {
      return Object.keys(EventStatusEnum).find(key => EventStatusEnum[key] === statusId);
    },

    getStatusColor(statusId) {
      switch (statusId) {
        case EventStatusEnum.Draft:
          return 'status-label badge border-none  ';
        case EventStatusEnum.Pending:
          return 'status-label badge border-none  bg-warning';
        case EventStatusEnum.Running:
          return 'status-label badge border-none  bg-info';
        case EventStatusEnum.Completed:
          return 'status-label badge border-none  bg-success';
        case EventStatusEnum.Cancelled:
          return 'status-label badge border-none  bg-error';
        default:
          return 'status-label badge border-none  bg-secondary';
      }
    }

  },
  watch: {
    async row(newRow) {
      if (this.isEdit) {

        this.transactionData = { ...newRow };
        this.getCommission();
        this.onLoginIdSelect(this.transactionData.username);
        if (this.transactionData.eventId) {
          try {
            const response = await apiService.getEventById({ id: this.transactionData.eventId });

            this.eventOdds = response.data.eventOdds;

          } catch (error) {
            toast.error(error.response.data.message);
          }

        }
        this.selectedScore = this.transactionData.eventOddId;
        this.updateTransactionDataWithScoreDetails(this.transactionData.eventOddId);
        this.calculateProfit();

        if (this.$refs.autocompleteRef) {
          this.$refs.autocompleteRef.disable();
        }

        if (this.transactionData.statusId == EventStatusEnum.Pending) {
          this.isEnabled = true;
        }

        this.intiAmount = this.transactionData.amount;

        console.log(newRow);
        this.transactionData.teams = newRow.homeTeamName + ' vs '+ newRow.awayTeamName;
      }

    },
    'transactionData.amount': function (newAmount) {
      if (newAmount) {
        this.calculateProfit();
      }
    },
    selectedScore(newScoreId) {
      if (newScoreId) {
        this.updateTransactionDataWithScoreDetails(newScoreId);
      }
    }
  }
};
</script>
