import { createRouter, createWebHistory } from 'vue-router';
import { reactive } from 'vue';
import apiService from '@/components/Services/apiService';

//Layout
import LoginLayout from '@/components/Layout/LoginLayout.vue';
import MainLayout from '@/components/Layout/MainLayout.vue';

//Pages
import LoginPage from '@/components/Page/LoginPage.vue';
import Dashboard from '@/components/Page/Dashboard.vue';
//import NotFoundPage from '@/components/Page/NotFoundPage.vue';

import Divisions from '@/components/Views/MatchSetup/Divisions/Divisions.vue';
import Team from '@/components/Views/MatchSetup/Team/Team.vue';
import Event from '@/components/Views/MatchSetup/Event/Event.vue';


import Member from '@/components/Views/Member/MemberList/Member.vue';
import Membership from '@/components/Views/Member/Membership/Membership.vue';
import MembershipUpgrade from '@/components/Views/Member/MembershipUpgrade/MembershipUpgrade.vue';


import UserAccess from '@/components/Views/SystemSetting/User/UserAccess.vue';
import RoleAccess from '@/components/Views/SystemSetting/Role/RoleAccess.vue';
import PresetData from '@/components/Views/SystemSetting/PresetData/PresetData.vue';

import DepositTransaction from '@/components/Views/Transaction/DepositTransaction/DepositTransaction.vue';
import WithdrawalTransaction from '@/components/Views/Transaction/WithdrawalTransaction/WithdrawalTransaction.vue';
import TransferTransaction from '@/components/Views/Transaction/TransferTransaction/TransferTransaction.vue';

import PaymentMethod from '@/components/Views/Bank/PaymentMethod/PaymentMethod.vue';
import PaymentChannel from '@/components/Views/Bank/PaymentChannel/PaymentChannel.vue';
import PaymentChannelAccount from '@/components/Views/Bank/PaymentChannelAccount/PaymentChannelAccount.vue';


import BetTransaction from '@/components/Views/BetTransaction/BetTransaction.vue';


import AffiliateList from '@/components/Views/Referral/Affiliate/AffiliateList.vue';
import CommissionSetup from '@/components/Views/Referral/CommissionSetup/CommissionSetup.vue';
import ReportPage from '@/components/Views/Report/ReportPage.vue';


import ImportedPage from '@/components/Views/Imported/ImportedPage.vue';
import currencyExchange from '@/components/Views/SystemSetting/CurrencyExchange/CurrencyExchange.vue';

export const globalState = reactive({
  isLoading: false,
});

const routes = [
  {
    path: '/login',
    component: LoginLayout,
    children: [
      { path: '/login', name: 'Login', component: LoginPage, meta: { guestOnly: true, title: 'Login' } },
    ]
  },
  {
    path: '/dashboard',
    component: MainLayout,
    meta: { requiresAuth: true, sidebar: true, title: 'Home', icon: 'fa-home', pageId: 1 },
    children: [
      { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { title: 'Dashboard' } },

    ]
  },
  {
    path: '/match',
    component: MainLayout,
    meta: { requiresAuth: true, sidebar: true, title: 'Match Setup', icon: 'fa-sliders', pageId: 2 },
    children: [
      { path: '/match/imported', name: 'Imported', component: ImportedPage, meta: { title: 'Imported' } },
      { path: '/match/team', name: 'Team', component: Team, meta: { title: 'Team' } },
      { path: '/match/division', name: 'Divisions', component: Divisions, meta: { title: 'Divisions' } },
      { path: '/match/event', name: 'Event', component: Event, meta: { title: 'Event' } },

    ]

  },
  {
    path: '/member',
    component: MainLayout,
    meta: { requiresAuth: true, sidebar: true, title: 'Member', icon: 'fa-user-circle', pageId: 3 },
    children: [
      { path: '/member/memberlist', name: 'Member List', component: Member, meta: { title: 'Member List' } },
      { path: '/member/membership', name: 'Membership', component: Membership, meta: { title: 'Membership' } },
      { path: '/member/memberUpgrade', name: 'Membership Upgrade', component: MembershipUpgrade, meta: { title: 'Membership Upgrade' } },

    ]

  },
  {
    path: '/transaction',
    component: MainLayout,
    meta: { requiresAuth: true, sidebar: true, title: 'Transaction', icon: 'fa-money-bill-transfer', pageId: 4 },
    children: [
      { path: '/transaction/deposit', name: 'Deposit', component: DepositTransaction, meta: { title: 'Deposit' } },
      { path: '/transaction/withdrawal', name: 'Withdrawal', component: WithdrawalTransaction, meta: { title: 'Withdrawal' } },
      { path: '/transaction/transfer', name: 'Transfer', component: TransferTransaction, meta: { title: 'Transfer' } },

    ]

  },
  {
    path: '/bank',
    component: MainLayout,
    meta: { requiresAuth: true, sidebar: true, title: 'Bank', icon: 'fa-bank', pageId: 5 },
    children: [
      { path: '/bank/payment-method', name: 'Payment Method', component: PaymentMethod, meta: { title: 'Payment Method' } },
      { path: '/bank/payment-channel', name: 'Payment Channel', component: PaymentChannel, meta: { title: 'Payment Channel' } },
      { path: '/bank/channel-account', name: 'Channel Account', component: PaymentChannelAccount, meta: { title: 'Channel Account' } },

    ]

  },
  {
    path: '/affiliate',
    component: MainLayout,
    meta: { requiresAuth: true, sidebar: true, title: 'Referral Affiliate', icon: 'fa-users-between-lines', pageId: 6 },
    children: [
      { path: '/affiliate/affiliate-list', name: 'Affiliate list', component:AffiliateList, meta: { title: 'Affiliate list' } },
      { path: '/affiliate/referral', name: 'Referral setting', meta: { title: 'Referral setting' } },
      { path: '/affiliate/commission', name: 'Commission setup', component: CommissionSetup, meta: { title: 'Commission setup' } },

    ]

  },
  {
    path: '/betlog',
    component: MainLayout,
    meta: { requiresAuth: true, sidebar: true, title: 'Bet Log', icon: 'fa-clipboard-list', pageId: 7 },
    children: [
      { path: '/betlog/member-bet-log', name: 'Member Bet Log', component: BetTransaction, meta: { title: 'Member Bet Log' } },

    ]

  },
  {
    path: '/report',
    component: MainLayout,
    meta: { requiresAuth: true, sidebar: true, title: 'Report', icon: 'fa-clipboard-user', pageId: 8 },
    children: [
      { path: '/report/commission-report', name: 'Commission Report', component: ReportPage, meta: { title: 'Commission Report' } },

    ]

  },
  {
    path: '/setting',
    component: MainLayout,
    meta: { requiresAuth: true, sidebar: true, title: 'Setting', icon: 'fa-user-gear', pageId: 9 },
    children: [
      { path: '/setting/user', name: 'User setting', component: UserAccess, meta: { title: 'User setting' } },
      { path: '/setting/role', name: 'Role setting', component: RoleAccess, meta: { title: 'Role setting' } },
      { path: '/setting/preset', name: 'Pre-set Data', component: PresetData, meta: { title: 'Pre-set Data' } },
      { path: '/setting/currency', name: 'Currency Exchange', component: currencyExchange, meta: { title: 'Currency Exchange' } },

    ]

  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: LoginPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Check if the token is set in localStorage
function isTokenSet() {
  return !!localStorage.getItem('token');
}

// Function to check session validity
function checkSession() {
  return apiService.checkUserSession().then(response => {
    return response.status === 200;
  }).catch(() => {
    return false;
  });
}

router.beforeEach(async (to, from, next) => {
  globalState.isLoading = true;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isGuestOnly = to.matched.some(record => record.meta.guestOnly);
  const isAlreadyLoggedIn = isTokenSet();
  const isFirstTimeLogin = localStorage.getItem('firstTimeLogin') === 'true';

  // If the user is logged in and trying to access a guest-only page like '/login'
  if (isAlreadyLoggedIn && isGuestOnly) {
    if (isFirstTimeLogin) {
      localStorage.removeItem('firstTimeLogin'); // Cleanup if it's the first login
    }
    return next('/dashboard'); // Redirect them to the dashboard
  }

  if (requiresAuth && !isAlreadyLoggedIn) {

    localStorage.removeItem('firstTimeLogin');
    localStorage.removeItem('systemUserId');
    localStorage.removeItem('token');
    return next('/login'); // Redirect them to login
  }

  if (isAlreadyLoggedIn && !isGuestOnly) {
    const isSessionValid = await checkSession();
    if (!isSessionValid) {

      localStorage.removeItem('firstTimeLogin');
      localStorage.removeItem('systemUserId');
      localStorage.removeItem('token');
      return next('/login');
    }

    return next();
  }

  if (!isAlreadyLoggedIn && !isGuestOnly) {
    const isSessionValid = await checkSession();
    if (isSessionValid) {
      return next('/dashboard');
    } else {
      localStorage.removeItem('firstTimeLogin');
      localStorage.removeItem('systemUserId');
      localStorage.removeItem('token');
      return next('/login');
    }
  }

  next();
});

router.afterEach(() => {
  globalState.isLoading = false; // Stop loading
});

export default router;
