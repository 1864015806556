<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="wrapper wrapper-content animated fadeInRight ecommerce">
    <div class=" m-b-sm border-bottom">


      <div class="row">
        <div class="col-sm-12 d-flex-custom" style="margin: 0.3rem;">

          <label for="startDateTime" class="col-form-label">Target</label>
          <div class="btn-group">
            <button class="btn"
              :class="{ 'btn-white active': !searchQuery.isMemberOnly, 'btn-white': searchQuery.isMemberOnly }"
              @click="selectTarget(false)">All</button>
            <button class="btn"
              :class="{ 'btn-white active': searchQuery.isMemberOnly, 'btn-white': !searchQuery.isMemberOnly }"
              @click="selectTarget(true)">Member Only</button>
          </div>
        </div>
        <div class="col-sm-12 d-flex-custom">
          <div class="form-group date-form-group">
            <label for="startDateTime" class="col-form-label">Start Date</label>
            <input type="date" placeholder="Start Date" id="startDateTime" class="form-control"
              v-model="searchQuery.startDateTime" :disabled="searchQuery.isLifeTimeValue">
          </div>
          <div class="form-group date-form-group">
            <label for="endDateTime" class="col-form-label">End Date</label>
            <input type="date" placeholder="End Date" id="endDateTime" class="form-control" :disabled="searchQuery.isLifeTimeValue" v-model="searchQuery.endDateTime">
          </div>
          <div class="form-group date-form-group">
            <input type="checkbox" id="checkbox1" class="form-check" v-model="searchQuery.isLifeTimeValue">
            <label for="checkbox1" style="margin-bottom: 0  !important;">
              LTV
            </label>
          </div>
        </div>


      </div>

      <DateRangeSelector @date-range-selected="onDateRangeSelected" :is-disabled="searchQuery.isLifeTimeValue" />

      <div class="form-group row">
        <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
          <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search" button-icon="fa-search"
            @click="performSearch" />

        </div>
      </div>

      <div class="wrapper wrapper-content">
        <div class="row">
          <label class="wallet-label">Member Total Wallet : {{ dashboardData.totalWalletBalanceAmount }}</label>
        </div>
        <div class="row" >
          <DashboardBox title="Total Member" :value="dashboardData.totalPlayerCount" tag="Total Member"
            tagClass="label label-success float-right" iconName="fa-users" />
          <DashboardBox title="Total New Registered Member" :value="dashboardData.totalNewPlayerCount"
            tag="Total New Registered Member" tagClass="label label-success float-right" iconName="fa-user-plus" />
          <DashboardBox title="Total Bet Amount" :value="dashboardData.totalBetAmount" tag="Total Bet Amount"
            tagClass="label label-success float-right" iconName="fa-money-bill-alt" />
          <DashboardBox title="Total Bet Placed" :value="dashboardData.totalBetCount" tag="Total Bet Placed"
            tagClass="label label-success float-right" iconName="fa-hand-paper" />
        </div>
        <div class="row" >

          <DashboardBox title="Total Number of Deposit" :value="dashboardData.totalDepositCount"
            tag="Total Number of Deposit" tagClass="label label-success float-right" iconName="fa-piggy-bank" />
          <DashboardBox title="Total Amount of Deposit" :value="dashboardData.totalDepositAmount"
            tag="Total Amount of Deposit" tagClass="label label-success float-right" iconName="fa-money-bill-wave" />
          <DashboardBox title="Total Number of Withdrawal" :value="dashboardData.totalWithdrawalCount"
            tag="Total Number of Withdrawal" tagClass="label label-success float-right" iconName="fa-hand-holding-usd" />
          <DashboardBox title="Total Amount of Withdrawal" :value="dashboardData.totalWithdrawalAmount"
            tag="Total Amount of Withdrawal" tagClass="label label-success float-right" iconName="fa-money-check-alt" />
        </div>
        <div class="row">

          <DashboardBox title="Total Profit Generated" :value="dashboardData.totalProfitAmount"
            tag="Total Profit Generated" tagClass="label label-success float-right" iconName="fa-balance-scale" />
          <DashboardBox title="Total Commission Generated" :value="dashboardData.totalCommissionAmount"
            tag="Total Commission Generated" tagClass="label label-success float-right" iconName="fa-user-tie" />
          <DashboardBox title="Net Cash" :value="dashboardData.netCash" tag="Net Cash"
            tagClass="label label-success float-right" iconName="fa-university" />
          <DashboardBox title="Gross Game Revenue" :value="dashboardData.revenue" tag="Gross Game Revenue"
            tagClass="label label-success float-right" iconName="fa-gamepad" />
        </div>
      </div>
    </div>

  </div>
</template>
  
<script setup>
import { onMounted } from 'vue'
import { useToast } from 'vue-toastification'
import DashboardBox from '../Element/DashboardBox.vue';
import DateRangeSelector from '@/components/Element/DateRangeSelector.vue';
import Button from '@/components/Element/Button.vue';
import apiService from '@/components/Services/apiService';
import { ref } from 'vue';

const searchQuery = ref({
  startDateTime: '',
  endDateTime: '',
  isLifeTimeValue: false,
  isMemberOnly: false,
});

const toast = useToast()

const dashboardData = ref({
  totalWalletBalanceAmount: 0,
  totalPlayerCount: 0,
  totalNewPlayerCount: 0,
  totalBetAmount: 0,
  totalBetCount: 0,
  totalDepositCount: 0,
  totalDepositAmount: 0,
  totalWithdrawalCount: 0,
  totalWithdrawalAmount: 0,
  totalProfitAmount: 0,
  totalCommissionAmount: 0,
  netCash: 0,
  revenue: 0
});
const performSearch = () => {
  fetchDashboardData(searchQuery.value);
};

function formatDateToISO(date) {
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

const selectTarget = (value) => {
  searchQuery.value.isMemberOnly = value;
};

const fetchDashboardData = async () => {
  try {
    
    const response = await apiService.getOverallReport(searchQuery.value);
    dashboardData.value = response.data;


    console.log(response.data);
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    // Handle error, maybe show a notification to the user
  }
};


onMounted(() => {
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  searchQuery.value.startDateTime = formatDateToISO(firstDayOfMonth);
  searchQuery.value.endDateTime = formatDateToISO(lastDayOfMonth);
  fetchDashboardData();
  if (!localStorage.getItem('firstVisit')) {
    toast.success('Welcome to InvestPro Back Office');
    localStorage.setItem('firstVisit', '1');
  }
});


const onDateRangeSelected = (range) => {
  searchQuery.value.startDateTime = formatDateToISO(new Date(range.start));
  searchQuery.value.endDateTime = formatDateToISO(new Date(range.end));
};

onMounted(() => {
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  searchQuery.value.startDateTime = formatDateToISO(firstDayOfMonth); // should be "2024-01-01" for January 2024
  searchQuery.value.endDateTime = formatDateToISO(lastDayOfMonth); // should be "2024-01-31" for January 2024



  if (!localStorage.getItem('firstVisit')) {
    toast.success('Welcome to InvestPro Back Office');
    localStorage.setItem('firstVisit', '1');
  }
});


</script>
<style>
.d-flex-custom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.date-form-group {
  display: flex;
  white-space: nowrap;
  gap: 10%;
  margin: 1rem;
  align-items: center;
  justify-content: center;
}

.wallet-label {
  float: right;
  text-align: end;
  font-size: 20px;
  font-weight: 600;
  padding-right: 23px !important;
}
</style>