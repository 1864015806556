<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <!-- Dynamic content for your form inputs -->
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="division-name" class="form-label">Payment Method Name</label>
              <input type="text" class="form-control" id="division-name" v-model="this.paymentMethodData.name">
            </div>
            <div class="mb-3">
              <label class="form-label">Online Payment</label>
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="onlinePaymentYes" value="true"
                    v-model="this.paymentMethodData.isOnlinePayment">
                  <label class="form-check-label" for="onlinePaymentYes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="onlinePaymentNo" value="false"
                    v-model="this.paymentMethodData.isOnlinePayment">
                  <label class="form-check-label" for="onlinePaymentNo">No</label>
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
const toast = useToast();

export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      paymentMethodData: { ...this.row },
    };
  },
  methods: {
    createDefaultPaymentMethodData() {
      // Return the default structure for division data
      return {
        name: '',
        isOnlinePayment: false,
      };
    },
    async submitForm() {
      try {
        if (!this.isEdit) {
          this.paymentMethodData.isOnlinePayment = Boolean(this.paymentMethodData.isOnlinePayment); // Convert to boolean
          const response = await apiService.createPaymentMethod(this.paymentMethodData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Create Payment Method Success");
          } else {
            console.error('error:', response);
          }
        }
        else {
          const response = await apiService.updatePaymentMethodInformation(
            {
              id: this.paymentMethodData.id,
              name: this.paymentMethodData.name,
              isOnlinePayment: this.paymentMethodData.isOnlinePayment === 'true',
            }
          );
          if (response.status === 200) {
            this.hideModal();
            toast.success("Update Payment Method Information Success");
          } else {
            console.error('error:', response);
          }
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    resetForm() {
      this.paymentMethodData = this.createDefaultPaymentMethodData();
    },
  },
  watch: {
    // Whenever the row prop changes, update the data properties
    row(newRow) {
      this.paymentMethodData = { ...newRow };
    }
  }
};
</script>
