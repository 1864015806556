<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm" aria-label="Close"
                        ref="closeButton"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 wallet-container">
                            <form @submit.prevent="submitForm" autocomplete="off">

                                <div class="mb-3  grid-3-7">
                                    <label for="full-name" class="form-label">Balance</label>
                                    <input type="text" class="form-control" 
                                        v-model="walletData.walletBalance" readonly>
                                </div>
                                <div class="mb-3  grid-3-7">
                                    <label for="membershipId" class="form-label">Debit/Credit</label>
                                    <SelectIncludeAll v-model="walletData.transactionTypeId" :options="walletAdjustmentType"
                                        labelField="label" valueField="value" :includeAllOption="false">
                                    </SelectIncludeAll>

                                </div>

                                <div class="mb-3  grid-3-7">
                                    <label for="username" class="form-label">Amount</label>
                                    <input type="number" class="form-control" v-model="walletData.amount" step="0.01" required>

                                </div>

                                <div class="mb-3  grid-3-7">
                                    <label for="referrerReferralCode" class="form-label">Remark</label>
                                    <input type="text" class="form-control"  v-model="walletData.remarks">
                                </div>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>

                    <div class="row" v-if="rows.length > 0">

                        <div class="col-lg-12">
                            <div class="ibox">
                                <div class="ibox-content table-responsive">
                                    <rows-per-page-select v-model="pagination.perPage"
                                        @update:value="onPerPageChange"></rows-per-page-select>

                                    <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                                        :sortOrder="sorting.sortOrder" @sort-requested="sort">
                                        <!-- Define slots for action buttons -->

                                        <template v-slot:custom="{ row }">
                                            <span
                                                :class="row.transactionTypeId == creditType ? 'text-green' : 'text-danger'">

                                                {{ row.amount }}
                                            </span>
                                        </template>

                                        <template v-slot:transactionType="{ row }">
                                            <span>

                                                {{ getTransactionTypeLabel(row.transactionSourceId) }}
                                            </span>
                                        </template>

                                        <template v-slot:adjustmentType="{ row }">
                                            <span>

                                                {{ getWalletAdjustmentTypeLabel(row.transactionTypeId) }}
                                            </span>
                                        </template>

                                    </DynamicTable>
                                    <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                                        @page-change="onPageChange"></pagination-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ref, reactive, watch, computed, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import apiService from '@/components/Services/apiService';
import { WalletAdjustmentType } from '@/enum/walletAdjustmentType';
import { getTransactionTypeLabel } from '@/enum/transactionType';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import DynamicTable from '@/components/Element/DynamicTable.vue';

export default {
    name: 'WalletManagement',
    components: {
        SelectIncludeAll,
        DynamicTable,
        PaginationComponent,
        RowsPerPageSelect
    },
    props: {
        title: String,
        modalId: {
            type: String,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        memberId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    setup(props, { emit }) {
        const toast = useToast();
        const walletData = reactive({
            walletBalance: '',
            transactionTypeId: '',
            amount: 0,
            remarks: ''
        });
        const closeButton = ref(null);
        const id = ref(0);
        const rows = ref([]);
        const pagination = reactive({
            currentPage: 1,
            perPage: 5,
            totalRows: 0
        });
        const sorting = reactive({
            sortBy: 'createdAt',
            sortOrder: 'desc'
        });
        const walletAdjustmentType = computed(() => {
            return Object.entries(WalletAdjustmentType).map(([label, value]) => ({ label, value }));
        });
        const creditType = walletAdjustmentType.value.find(type => type.label === 'Credit').value;

        const totalPages = computed(() =>
            Math.ceil(pagination.totalRows / pagination.perPage)
        );

        watch(() => props.memberId, (newRow) => {
            id.value = newRow;
            pagination.currentPage = 1;
            fetchWalletDetails(newRow);
        });

        const submitForm = async () => {


            if (walletData.transactionTypeId == WalletAdjustmentType.Debit && walletData.walletBalance < walletData.amount) {
                toast.error("Insufficient Balance to perform adjustment.");
                return;
            }
            const adjustmentData = {
                playerId: id.value,
                transactionTypeId: walletData.transactionTypeId,
                amount: walletData.amount,
                remarks: walletData.remarks
            };
            const response = await apiService.createWalletAdjustment(adjustmentData);
            if (response.status === 200) {
                fetchWalletDetails(id.value);
                hideModal();
                toast.success("Wallet Adjustment Success");
            } else {
                console.error('error:', response);
            }
        };

        const hideModal = () => {
            closeButton.value.click();
            resetForm();
            emit('modalClose');
        };

        const tableColumns = [
            { name: 'Created Timestamp', key: 'createdAt', isSortable: true, isAction: false, isDate: true },
            { name: 'Transaction Type', key: 'transactionSourceId', isSortable: true, isAction: false, slotName: 'transactionType', isCustom: true },
            { name: 'Debit/Credit', key: 'transactionTypeId', isSortable: true, isDate: false, slotName: 'adjustmentType', isCustom: true },
            { name: 'Amount', key: 'amount', isSortable: true, isDate: false, slotName: 'custom', isCustom: true },
            { name: 'Remark', key: 'remarks', isSortable: true, isDate: false },
        ]


        const resetForm = () => {
            Object.assign(walletData, {
                id: '',
                type: '',
                amount: '',
                remarks: ''
            });
        };

        const convertToDateTimeLocal = (dateString) => {
            if (!dateString) {
                return '';
            }
            const date = new Date(dateString);

            // Add 8 hours
            date.setHours(date.getHours() + 8);

            // Calculate the timezone offset in milliseconds
            const offset = date.getTimezoneOffset() * 60000;
            const localISOTime = (new Date(date.getTime() - offset)).toISOString().slice(0, -1);

            // Return the date in 'YYYY-MM-DDThh:mm' format
            return localISOTime.substring(0, 16);
        };


        onMounted(() => {
            resetForm();
        });
        async function fetchWalletDetails(mbId) {

            try {
                const memberWalletRes = await apiService.getMemberWalletById({ id: mbId });
                walletData.walletBalance = memberWalletRes.data.walletBalance;

                const skip = (pagination.currentPage - 1) * pagination.perPage;
                const params = {
                    id: mbId,
                    offset: skip,
                    limit: pagination.perPage,
                };

                // Only add sort and order if they have values
                if (sorting.sortBy) {
                    params.sort = sorting.sortBy;
                    params.order = sorting.sortOrder;
                }
                const response = await apiService.getMemberWalletLogById(params);
                rows.value = response.data.playerWalletLogs;
                pagination.totalRows = response.data.count;
            } catch (error) {
                toast.error(error.response.data.message);
            }

        }
        function sort(key) {
            if (sorting.sortBy === key) {
                sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                sorting.sortBy = key;
                sorting.sortOrder = 'asc';
            }
            fetchWalletDetails(id.value);
        }
        function onPageChange(newPage) {
            pagination.currentPage = newPage;
            fetchWalletDetails(id.value);
        }
        function onPerPageChange(newPerPage) {
            pagination.perPage = newPerPage;
            pagination.currentPage = 1;
            fetchWalletDetails(id.value);
        }

        function getWalletAdjustmentTypeLabel(adjustmentId) {
            return Object.keys(WalletAdjustmentType).find(key => WalletAdjustmentType[key] === adjustmentId);
        }


      
        return {
            walletData, creditType, closeButton, walletAdjustmentType, pagination, sorting,
            tableColumns, totalPages, rows
            , submitForm, hideModal, resetForm, convertToDateTimeLocal,
            sort, onPageChange, onPerPageChange,
            getWalletAdjustmentTypeLabel,
            getTransactionTypeLabel
        };
    }
};
</script>
<style>
@media (min-width: 768px) {
    .wallet-container {
        padding: 10px 10% !important;
        margin-bottom: 1.2em;
    }
}

.text-green {
    color: #28a745 !important;
}
</style>