
<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" style="max-width: 800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="presetData-ID" class="form-label">Half Time Team A Goals *</label>
              <!-- Simplified v-model binding by removing 'this' -->
              <input type="text" class="form-control" id="presetData-ID" v-model="presetData.halfHomeScore" required>
            </div>

            <div class="mb-3">
              <label for="presetData-ID" class="form-label">Half Time Team B Goals *</label>
              <!-- Simplified v-model binding by removing 'this' -->
              <input type="text" class="form-control" id="presetData-ID" v-model="presetData.halfAwayScore" required>
            </div>
            <div class="mb-3">
              <label for="presetData-ID" class="form-label">Full Time Team A Goals *</label>
              <!-- Simplified v-model binding by removing 'this' -->
              <input type="text" class="form-control" id="presetData-ID" v-model="presetData.fullHomeScore" required>
            </div>
            <div class="mb-3">
              <label for="presetData-ID" class="form-label">Full Time Team B Goals *</label>
              <!-- Simplified v-model binding by removing 'this' -->
              <input type="text" class="form-control" id="presetData-ID" v-model="presetData.fullAwayScore" required>
            </div>


            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
const toast = useToast();

export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: Boolean, // Default value is already false, no need to specify
    row: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      presetData: {
        ...this.row,
      },
      oddsHalf: [],
      oddsFull: [],
    };
  },
  methods: {
    async getOdds(typeId) {
      try {
        const response = await apiService.getOdds({ typeId });
        return response.data.odds;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    resetForm() {
      this.presetData = {
        halfHomeScore: '',
        halfAwayScore: '',
        fullHomeScore: '',
        fullAwayScore: '',
        // Add other properties as needed
      };
      this.$emit('modalClose');
    },
    async submitForm() {
      try {
        const response = await apiService.updateEventScore(
          {
            id: this.presetData.id,
            halfHomeScore: this.presetData.halfHomeScore,
            halfAwayScore: this.presetData.halfAwayScore,
            fullHomeScore: this.presetData.fullHomeScore,
            fullAwayScore: this.presetData.fullAwayScore,
          }
        );
        if (response.status === 200) {
        
          toast.success("Update Event Score Success");
          this.hideModal();

        } else {
          console.error('error:', response);
        }

      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();

      this.resetForm();
    }
  },
  watch: {
    // Whenever the row prop changes, update the data properties
    row(newRow) {
      this.presetData = { ...newRow };
    }
  }
};
</script>
