<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t" @submit.prevent="search">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="ibox-content m-b-sm border-bottom">

        <div class="row">

          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="status">Status</label>

              <SelectIncludeAll v-model="searchInput.statusId" :options="statusArray" labelField="label" valueField="id">
              </SelectIncludeAll>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="name">Login ID</label>
              <Autocomplete placeholder="Search Login ID" :suggestions="loginIdSuggestions.map(player => player.username)"
                @input="fetchLoginIdSuggestions" @select="onLoginIdSelect" />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="role">Team</label>

              <SelectIncludeAll v-model="searchInput.teamId" :options="teams" labelField="name" valueField="name">
              </SelectIncludeAll>

            </div>
          </div>


        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="role">Start Date</label>

              <input type="date" class="form-control" placeholder="Date of Birth" v-model="searchInput.fromDate">

            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="role">End Date</label>

              <input type="date" class="form-control" placeholder="Date of Birth" v-model="searchInput.toDate">

            </div>
          </div>
        </div>


        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
            <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
              button-icon="fa-search" />

            <Button button-type="button" button-class="btn-warning block  m-b" button-text="Add" button-icon="fa-plus"
              @click="add" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content table-responsive">
              <rows-per-page-select v-model="pagination.perPage" @update:value="onPerPageChange"></rows-per-page-select>

              <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                :sortOrder="sorting.sortOrder" @sort-requested="sort">
                <!-- Define slots for action buttons -->

                <template v-slot:actionButton="{ row }">
                  <!-- Your custom action button -->
                  <div class="actionDiv">
                    <Icon icon="fa-edit" class="edit-icon" tooltip="Edit" @click="editRow(row)"></Icon>
                  </div>
                </template>
                <template v-slot:teams="{ row }">
                 {{ row.homeTeamName }} vs {{ row.awayTeamName }}
                </template>
                <template v-slot:statusButton="{ row }">
                

                  <span  :classList="getStatusColor(row.statusId)">
                    {{ getStatusLabel(row.statusId) }}
                  </span>
                </template>

              </DynamicTable>
              <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                @page-change="onPageChange"></pagination-component>
            </div>
          </div>
        </div>
      </div>


    </div>
  </form>

  <dynamic-modal :title="modalTitle" :modal-id="'betModal'" :is-edit="isEdit" :row="activeRow"
    :loginIdSuggestions="loginIdSuggestions" @modalClose="modalClosedHandler" />
</template>



<script>
import { ref, reactive, computed } from 'vue';
import Button from '@/components/Element/Button.vue';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import DynamicModal from './DynamicModal.vue';
import { useToast } from 'vue-toastification';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import { EventStatusEnum } from '@/enum/eventStatus';
import apiService from '@/components/Services/apiService';
import Autocomplete from "@/components/Element/Autocomplete.vue";
import Icon from "@/components/Element/Icon.vue";
export default {
  components: {
    Button,
    DynamicModal,
    SelectIncludeAll,
    DynamicTable,
    PaginationComponent,
    RowsPerPageSelect,
    Autocomplete,
    Icon
  },
  setup() {

    const rows = ref([]);
    const teams = ref([]);
    const toast = useToast();
    const activeRow = ref({});

    const modalTitle = ref('Add Bet');
    const isEdit = ref();

    const debounce = (func, delay) => {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      }
    };

    const loginIdSuggestions = ref([]);
    const searchInput = reactive({
      statusId: 0,
      teamId: 0,
      playerUsername: '',
      fromDate: '',
      toDate: ''
    });

    const sorting = reactive({
      sortBy: 'id',
      sortOrder: 'desc'
    });

    const pagination = reactive({
      currentPage: 1,
      perPage: 5,
      totalRows: 0
    });

    const statusArray = computed(() =>
      Object.entries(EventStatusEnum).map(([label, id]) => ({ id, label }))
    );

    const totalPages = computed(() =>
      Math.ceil(pagination.totalRows / pagination.perPage)
    );

    const tableColumns = [
      { name: 'Action', key: 'id', isSortable: false, isAction: true, slotName: 'actionButton' },
      { name: 'Login ID', key: 'username', isSortable: true, isAction: false },
      { name: 'Event ID', key: 'eventId', isSortable: true },
      { name: 'Teams', key: 'teamName', isSortable: true, isAction: true, slotName:'teams' },
      { name: 'Kick-off Time', key: 'eventAt', isSortable: true, isAction: false, isDate: true },
      { name: 'Bet score', key: 'betScore', isSortable: true, isAction: false },
      { name: 'Bet amount', key: 'amount', isSortable: true, isAction: false },
      { name: 'Odds', key: 'profit', isSortable: true, isAction: false },
      { name: 'Processing fees', key: 'processingFee', isSortable: true, isAction: false },
      { name: 'Final score', key: 'score', isSortable: true, isAction: false },
      { name: 'Profit', key: 'profitAmount', isSortable: true, isAction: false },
      { name: 'Status', key: 'statusId', isSortable: false, isAction: false, isStatus: true, slotName: 'statusButton', }
    ]
   
    fetchMemberBets();
    fetchAllMembers();
    getTeams();

    async function fetchMemberBets() {
      const skip = (pagination.currentPage - 1) * pagination.perPage;
      const params = { ...searchInput, offset: skip, limit: pagination.perPage, sort: sorting.sortBy, order: sorting.sortOrder };
      try {
        const response = await apiService.getMemberBets(params);
        rows.value = response.data.playerBets;

        pagination.totalRows = response.data.count;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }




    const add = debounce(() => {
      modalTitle.value = 'Add Bet';
      isEdit.value = false;
      window.showModal('betModal', false);
    }, 300);


    async function editRow(row) {
    
      try {
        const userDetails = await apiService.getMemberBetById({ id: row.id });

        let data  =  { ...userDetails.data, eventId: row.eventId, username: row.username, statusId: row.statusId, homeTeamName: row.homeTeamName, awayTeamName: row.awayTeamName };
        modalTitle.value = 'Edit Bet';
        activeRow.value = data;

        isEdit.value = true;
        window.showModal('betModal', true);
      } catch (error) {
        console.error('Failed to fetch event details:', error);
      }

    }

    function sort(key) {
      if (sorting.sortBy === key) {
        sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        sorting.sortBy = key;
        sorting.sortOrder = 'asc';
      }
      fetchMemberBets();
    }

    function search() {
      fetchMemberBets();
    }

    function modalClosedHandler() {
      fetchMemberBets();
    }


    function onPageChange(newPage) {
      pagination.currentPage = newPage;
    }

    function onPerPageChange(newPerPage) {
      pagination.perPage = newPerPage;
      pagination.currentPage = 1;
      fetchMemberBets();
    }

    function getStatusLabel(statusId) {
      return Object.keys(EventStatusEnum).find(key => EventStatusEnum[key] === statusId);
    }

    function getStatusColor(statusId) {
      switch (statusId) {
        case EventStatusEnum.Draft:
          return 'status-label badge border-none  ';
        case EventStatusEnum.Pending:
          return 'status-label badge border-none  bg-warning';
        case EventStatusEnum.Running:
          return 'status-label badge border-none  bg-info';
        case EventStatusEnum.Completed:
          return 'status-label badge border-none  bg-success';
        case EventStatusEnum.Cancelled:
          return 'status-label badge border-none  bg-error';
        default:
          return 'status-label badge border-none  bg-secondary';
      }
    }

    async function fetchAllMembers() {
      try {
        const response = await apiService.getMembers();
        if (response.data && response.data.players) {
          loginIdSuggestions.value = response.data.players;
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    function fetchLoginIdSuggestions(query) {
      console.log(query);
    }

    function onLoginIdSelect(selectedOption) {
      searchInput.playerUsername = selectedOption;
    }

    async function getTeams() {
      try {
        const response = await apiService.getTeams();
        teams.value = response.data.teams;
      } catch (error) {
        console.error('error:', error);
      }
    }

    return {
      searchInput,
      sorting,
      pagination,
      rows,
      statusArray,
      totalPages,
      tableColumns,
      activeRow,
      teams,
      modalTitle,
      isEdit,
      loginIdSuggestions,
      search,
      modalClosedHandler,
      onPageChange,
      onPerPageChange,
      sort,
      getStatusColor,
      getStatusLabel,
      add,
      fetchLoginIdSuggestions,
      onLoginIdSelect,
      editRow
    };
  }
};
</script>

<style>
.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-success {
  background-color: #18a689 !important;
}
</style>