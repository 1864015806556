
<template>
    <div class="table-responsive">
        <rows-per-page-select v-model="pagination.perPage" @update:value="onPerPageChange"></rows-per-page-select>
        <table class="table table-hover table-bordered ">
            <thead class="table-light">
                <tr>
                    <th class="text-center">Action</th>
                    <th @click="sort('name')">
                        Team Name
                        <span v-if="sorting.sortBy === 'name'">
                            <span v-if="sorting.sortOrder === 'asc'">&#9650;</span> <!-- Up arrow -->
                            <span v-else>&#9660;</span> <!-- Down arrow -->
                        </span>
                    </th>
                    <th @click="sort('chineseName')">
                        Team Chinese Name
                        <span v-if="sorting.sortBy === 'chineseName'">
                            <span v-if="sorting.sortOrder === 'asc'">&#9650;</span> <!-- Up arrow -->
                            <span v-else>&#9660;</span> <!-- Down arrow -->
                        </span>
                    </th>

                    <th @click="sort('divisionName')">
                        Division
                        <span v-if="sorting.sortBy === 'divisionName'">
                            <span v-if="sorting.sortOrder === 'asc'">&#9650;</span> <!-- Up arrow -->
                            <span v-else>&#9660;</span> <!-- Down arrow -->
                        </span>
                    </th>

                    <th> Logo </th>

                    <th class="text-center" @click="sort('statusId')">
                        Status
                        <span v-if="sorting.sortBy === 'statusId'">
                            <span v-if="sorting.sortOrder === 'asc'">&#9650;</span> <!-- Up arrow -->
                            <span v-else>&#9660;</span> <!-- Down arrow -->
                        </span>
                    </th>


                </tr>
            </thead>
            <tbody>
                <tr v-for="row in rows" :key="row.id">
                    <td class="text-center actionDiv">
                        <Icon icon="fa-edit" class="edit-icon " tooltip="Edit" @click="editRow(row)"></Icon>
                    </td>
                    <td>{{ row.name }}</td>
                    <td>{{ row.chineseName }}</td>
                    <td>{{ row.divisionName }}</td>
                    <td class="text-center"><img v-if="row.filename" :src="row.imageUrl" :alt="row.name" class="team-logo" />
                        <span v-else>No Image</span>
                    </td>
                    <td class="text-center">
                        <span :class="[' status-label  badge', row.statusId === 1 ? 'bg-primary' : 'bg-danger']"
                            @click="updateStatus(row)">
                            {{ row.statusId === 1 ? 'Active' : 'Inactive' }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

        <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
            @page-change="onPageChange"></pagination-component>
    </div>
    <dynamic-modal :modalId="'editModal'" :title="'Edit Team'" :is-edit="true" :row="activeRow"
        @modalClose="modalClosedHandler" />
</template>


<script>
import { ref, reactive, watch, onMounted, nextTick } from 'vue';
import apiService from '@/components/Services/apiService';
import Icon from '@/components/Element/Icon.vue';
import placeholder from '@/assets/img/placeholder.gif';
import { Modal } from 'bootstrap';
import { useToast } from 'vue-toastification';
import DynamicModal from './DynamicModal.vue';

import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
export default {
    components: {
        Icon,
        DynamicModal,
        PaginationComponent,
        RowsPerPageSelect
    },
    computed: {
        totalPages() {
            return Math.ceil(this.pagination.totalRows / this.pagination.perPage);
        }
    },
    props: {
        searchCriteria: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props) {
        const toast = useToast();
        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const activeRow = ref({});
        const rows = ref([]);
        const pagination = reactive({
            currentPage: 1,
            perPage: 5,
            totalRows: 0
        });
        const sorting = reactive({
            sortBy: '',
            sortOrder: ''
        });
        watch([() => props.searchCriteria, () => pagination.currentPage], fetchTeams);

        onMounted(fetchTeams);

        const handleImageError = (row) => {
            row.imageUrl = placeholder; 
        };

        onMounted(async () => {
            await fetchTeams();
            rows.value.forEach(row => {
                if (row.filename) {
                    // Update imageUrl property for each row
                    row.imageUrl = `${baseUrl}/image/${row.filename}`;
                }
            });
        });

        async function fetchTeams() {
            const skip = (pagination.currentPage - 1) * pagination.perPage;
            const params = {
                ...props.searchCriteria,
                offset: skip,
                limit: pagination.perPage,
            };

            // Only add sort and order if they have values
            if (sorting.sortBy) {
                params.sort = sorting.sortBy;
                params.order = sorting.sortOrder;
            }

            try {
                const response = await apiService.getTeams(params);
                rows.value = response.data.teams;
                rows.value.forEach(row => {
                    if (row.filename) {
                        // Update imageUrl property for each row
                        row.imageUrl = `${baseUrl}/image/${row.filename}`;
                    }
                });
                pagination.totalRows = response.data.count;
            } catch (error) {
                toast.error(error.response.data.message);
            }
        }

        function modalClosedHandler() {
            fetchTeams();
        }


        function onPerPageChange(newPerPage) {

            pagination.perPage = newPerPage;
            pagination.currentPage = 1;
            fetchTeams();
        }

        function sort(field) {
            if (sorting.sortBy === field) {
                sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                sorting.sortBy = field;
                sorting.sortOrder = 'asc';
            }
            fetchTeams();
        }

        async function updateStatus(row) {
            try {
                const newStatus = row.statusId === 1 ? 2 : 1;

                const response = await apiService.updateTeamStatusById({
                    id: row.id,
                    status: newStatus
                });

                if (response.status === 200) {
                    row.statusId = newStatus;

                    toast.success(`Team status updated to ${newStatus === 1 ? 'Active' : 'Inactive'}`);
                } else {
                    toast.error('Failed to update team status');
                }
            } catch (error) {
                toast.error('Error occurred while updating team status');
            }
        }


        async function editRow(row) {

            try {
                const teamDetails = await apiService.getTeamById({ id: row.id });
                activeRow.value = { ...teamDetails.data };

                await nextTick();

                const modalElement = document.getElementById('editModal');
                const bootstrapModal = new Modal(modalElement);
                bootstrapModal.show();
            } catch (error) {
                toast.error(error.response.data.message);
            }

        }

        // Method to handle page change from pagination controls
        function onPageChange(newPage) {
            pagination.currentPage = newPage;
        }

        return {
            rows,
            pagination,
            sorting,
            activeRow,
            editRow,
            sort,
            updateStatus,
            onPageChange,
            onPerPageChange,
            modalClosedHandler,
            handleImageError
        };
    }
};
</script>


<style>
.text-center {
    text-align: center !important;
}

.edit-icon {
    cursor: pointer;
}

.status-label {
    padding: 0.55em 0.5em !important;
    border-radius: 4px;
    color: white;
    display: inline-block;
    cursor: pointer;
    width: 100px !important;
}

.status-label.active {
    background-color: #38b2ac;
}
</style>
