<template>
  <div class="dropdown" ref="dropdown">
    <button :class="classList" type="button" @click="toggleMenu">
      {{ selectedLabel }}
    </button>
    <div :class="['dropdown-menu', menuVisible ? 'show' : '']">
      <a class="dropdown-item" v-for="item in coordinatedOptions" :key="item.value" @click="selectItem(item)">
        {{ item.label }}
      </a>
    </div>
  </div>
</template>
  
<script>

import Swal from 'sweetalert2';
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    defaultValue: {
      required: false
    },
    labelField: {
      type: String,
      required: true
    },
    valueField: {
      type: String,
      required: true
    },
    classList: {
      type: String,
      required: true
    },
    rowId: {
      type: Number,
      required: false
    },
    isRequiredVerify: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      menuVisible: false,
      selectedValue: this.defaultValue,
      selectedLabel: '',
      coordinatedOptions: [],
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    selectItem(item) {
      if(item.value != this.defaultValue){
        if (this.isRequiredVerify) {
        Swal.fire({
          title: `Are you sure you want to proceed changes to ${item.label}?`,
          text: "Are you sure you want to proceed?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No, cancel it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.selectedValue = item.value;
            this.selectedLabel = item.label;
            this.closeMenu();
            this.$emit('update:value', this.selectedValue);
          } else {
            this.closeMenu();
          }
        });
      }else{
        this.selectedValue = item.value;
            this.selectedLabel = item.label;
            this.closeMenu();
            this.$emit('update:value', this.selectedValue);
      }
      }
    },
    closeMenu() {
      this.menuVisible = false;
    },
    handleClickOutside(event) {
      if (this.menuVisible && !this.$refs.dropdown.contains(event.target)) {
        this.closeMenu();
      }
    }
  },
  mounted() {
    this.coordinatedOptions = this.options.map(option => ({
      label: option[this.labelField],
      value: option[this.valueField]
    }));
  
    const defaultItem = this.coordinatedOptions.find(option => option.value === this.defaultValue);
    this.selectedLabel = defaultItem ? defaultItem.label : 'Select an option';
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>
  
<style scoped>
.dropdown-container {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  /* high z-index to ensure it overlays other elements */
  /* additional styles for the dropdown */
}
</style>
  