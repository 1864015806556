<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm" aria-label="Close"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm" autocomplete="off">
            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Login ID</label>
              <input type="text" class="form-control"  v-model="userData.username" required>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="full-name" class="form-label">Full Name</label>
              <input type="text" class="form-control"  v-model="userData.name" required>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="phone-number" class="form-label">Phone (Number)</label>
              <div class="input-group m-b">
                <div class="input-group-prepend">
                  <select class="form-control" v-model="userData.countryCallingCodeId" required>
                    <option v-for="(code, index) in countryCodes" :key="index" :value="code.id">
                      {{ code.name }}
                    </option>
                  </select>
                </div>
                <input type="text" class="form-control" v-model="userData.contactNumber" @keyup="checkContactNumber"
                  required>
                <!-- Display error message if contact number is invalid -->
                <div v-if="!isContactNumberValid" class="invalid-feedback d-block">
                  Only numeric values are allowed for contact number.
                </div>
              </div>

            </div>
            <div class="mb-3  grid-3-7">
              <label for="accessControlId" class="form-label">Role</label>

              <select class="form-control" v-model="userData.accessControlId" >
                <option value="">Please select a role</option>
                <option v-for="(role, index) in roles" :key="index" :value="role.id">
                  {{ role.name }}
                </option>
              </select>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="password" class="form-label">Password</label>
              <input type="password" class="form-control" v-model="userData.password" @keyup="checkPasswordStrength"
                :required="!isEdit">
              <div style="grid-column: 2;">

                <!-- Password Strength Progress Bar -->
                <div class="progress">
                  <div class="progress-bar" :class="passwordStrength.class" :style="{ width: passwordStrength.width }">
                  </div>
                </div>
                <!-- Password Requirements List -->
                <ul class="password-requirements">
                  <li :class="{ valid: passwordCriteria.minChar, invalid: !passwordCriteria.minChar }">
                    Minimum 6 characters
                  </li>
                  <li :class="{ valid: passwordCriteria.lowercase, invalid: !passwordCriteria.lowercase }">
                    At least one lowercase letter
                  </li>
                  <li :class="{ valid: passwordCriteria.uppercase, invalid: !passwordCriteria.uppercase }">
                    At least one uppercase letter
                  </li>

                </ul>
              </div>

            </div>
            <div class="mb-3  grid-3-7">
              <label for="confirmed-password" class="form-label">Confirmed Password</label>
              <input type="password" class="form-control"  v-model="userData.confirmedPassword" :required="!isEdit">
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

import apiService from '@/components/Services/apiService';
const toast = useToast();
export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    roles: {
      type: Object
    },
    // You can expand this object to include all the fields you need
    row: {
      type: Object,
      default: () => ({
        id: '',
        username: '',
        name: '',
        countryCallingCodeId: '',
        contactNumber: '',
        accessControlId: '',
        password: '',
        confirmedPassword: '',
      })
    }
  },
  created() {
    this.resetForm();
  },
  data() {
    return {
      // Create a local copy of the user data to edit
      userData: { ...this.row },
      passwordStrength: { width: '0%', class: 'bg-danger' },
      passwordMismatch: false,
      isPasswordValid: false,
      passwordCriteria: {
        minChar: false,
        lowercase: false,
        uppercase: false,
      },
      countryCodes: [],
      isContactNumberValid: true,
    };
  },
  methods: {
    async getCountryCallingCode() {
      try {
        const response = await apiService.getCountryCallingCode();
        this.countryCodes = response.data;
        if (this.countryCodes.length > 0) {
          this.userData.countryCallingCodeId = this.countryCodes[0].id;
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    checkContactNumber() {
      const regex = /^[0-9]*$/; // Allow empty string or numbers
      this.isContactNumberValid = regex.test(this.userData.contactNumber);
    },
    async submitForm() {
      if (this.validatePassword() && this.isContactNumberValid) {
        try {
          if (!this.isEdit) {
            const response = await apiService.createUser(this.userData);
            if (response.status === 200) {
              this.hideModal();
              toast.success("Create User Success");
            } else {
              console.error('error:', response);
            }
          } else {
            const response = await apiService.updateUserInformation(
              {
                id: this.userData.id,
                username: this.userData.username,
                name: this.userData.name,
                countryCallingCodeId: this.userData.countryCallingCodeId,
                contactNumber: this.userData.contactNumber,
                accessControlId: this.userData.accessControlId,
                password: this.userData.password,
              }
            );
            if (response.status === 200) {
              this.hideModal();
              toast.success("Update User Information Success");
            } else {
              console.error('error:', response);
            }
          }

        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error("Invalid password or the passwords do not match.");
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    checkPasswordStrength() {
      if (this.userData.password) {
        const strength = this.getPasswordStrength(this.userData.password);
        this.passwordStrength.width = strength.width;
        this.passwordStrength.class = strength.class;
        const password = this.userData.password;
        this.passwordCriteria.minChar = password.length >= 6;
        this.passwordCriteria.lowercase = /[a-z]/.test(password);
        this.passwordCriteria.uppercase = /[A-Z]/.test(password);
      }

    },
    getPasswordStrength(password) {
      let strength = { width: '25%', class: 'bg-danger' }; // Weak by default

      // Update the strength object based on password criteria
      if (password.length > 6) strength = { width: '50%', class: 'bg-warning' };
      if (password.length > 6 && /[A-Z]/.test(password)) strength = { width: '75%', class: 'bg-info' };
      if (password.length > 6 && /[A-Z]/.test(password)) {
        strength = { width: '100%', class: 'bg-success' };
      }

      return strength;
    },
    hasLowerCase(str) {
      return /[a-z]/.test(str);
    },
    hasUpperCase(str) {
      return /[A-Z]/.test(str);
    },

    validatePassword() {

      if (!this.isEdit || this.userData.password) {
        this.passwordMismatch = false;
        this.isPasswordValid = false;

        if (!this.isPasswordStrong(this.userData.password)) {
          return false;
        }
        if (this.userData.password !== this.userData.confirmedPassword) {
          this.passwordMismatch = true;
          return false;
        }
        this.isPasswordValid = true;
        return true;
      } else {
        return true;
      }
    },
    isPasswordStrong(password) {
      return password.length >= 6 && /[A-Z]/.test(password);
    },
    resetForm() {
      this.userData = {
        username: '',
        name: '',
        countryCallingCodeId: this.countryCodes.length > 0 ? this.countryCodes[0].id : '',
        contactNumber: ' ',
        accessControlId: '',
        password: '',
        confirmedPassword: '',
      };

      this.passwordStrength = { width: '0%', class: 'bg-danger' };
      this.passwordCriteria = {
        minChar: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false,
      };
    },
  },
  mounted() {
    this.getCountryCallingCode();
  },
  watch: {
    row(newRow) {
      if (this.isEdit) {

        this.userData = { ...newRow };
      }

      this.checkContactNumber();
    }
  }
};
</script>
<style scoped>
/* Style your progress bar */
.progress {
  height: 5px;
  margin-top: 5px;
}

.progress-bar {
  transition: width 0.5s ease;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.password-requirements {
  padding-left: 0;
  list-style-type: none;
}

.password-requirements li {
  position: relative;
  padding-left: 20px;
  /* Give enough space for the icon */
}

.password-requirements li.valid::before {
  content: '✓';
  color: green;
  position: absolute;
  left: 0;
}

.password-requirements li.invalid::before {
  content: '✕';
  color: red;
  position: absolute;
  left: 0;
}

select.form-control:not([size]):not([multiple]) {
  height: unset !important;
}
</style>