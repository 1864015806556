<template>
  <table class="table table-hover table-bordered">
    <thead class="table-light">
      <tr>
        <!-- Render column headers -->
        <th v-for="column in columns" :key="column.key" class="text-center"
          @click="column.isSortable ? requestSort(column.key) : null">

          <div v-if="column.isTickBox">
            <input type="checkbox" v-model="isTicked" @click="selectAll()">
          </div>
          <div v-else>
            {{ column.name }}
          <!-- Sorting arrows -->
          <span v-if="column.isSortable && sortingKey === column.key">
            <span v-if="sortOrder === 'asc'">&#9650;</span> <!-- Up arrow -->
            <span v-else>&#9660;</span> <!-- Down arrow -->
          </span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <!-- Loading Indicator Row -->
      <tr v-if="globalState.isLoading">
        <td :colspan="columns.length" class="text-center">
          <div class="table-overlay">
            <div class="loading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

        </td>
      </tr>
      <!-- Data Rows -->
      <tr v-for="row in rows" :key="row.id" @click="selectRow(row)" v-else>
        <td v-for="column in columns" :key="column.key" class="text-center">
          <slot v-if="column.isAction" :name="column.slotName" :row="row"></slot>
          <slot v-else-if="column.isStatus" :name="column.slotName" :row="row"></slot>
          <template v-else-if="column.isDate">{{ formatDate(row[column.key]) }}</template>
          <template v-else-if="column.isNumber">{{ row[column.key].toFixed(2) }}</template>
          <slot v-else-if="column.isCustom" :name="column.slotName" :row="row"></slot>
          <template v-else>{{ row[column.key] }}</template>
        </td>
      </tr>

    </tbody>
  </table>
</template>
  
<script>

import { globalState } from '/src/router.js';
export default {
  name: 'DynamicTableComponent',
  props: {
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    sortingKey: String,
    sortOrder: String,
    isAllSelected: Boolean,
  },
  data() {
    return {
      globalState,
      isTicked: this.isAllSelected
    };
  },
  emits: ['sort-requested', 'row-selected', 'all-row-selected'],
  methods: {
    requestSort(key) {
      this.$emit('sort-requested', key);
    },
    selectRow(row) {
      this.$emit('row-selected', row);
    },
    selectAll() {
      this.isTicked = !this.isTicked
    },
    formatDate(dateString) {

      if (dateString) {
        const date = new Date(dateString);

        date.setHours(date.getHours() + 8);

        return date.toLocaleDateString('en-US', {
          year: 'numeric', month: 'short', day: 'numeric',
          hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
        });
      } else {
        return '-'
      }

    }
  }, 
  watch: {
    // Watch for changes to isTicked
    isTicked(newVal) {
      // Emit 'tick-change' event with the new value
      this.$emit('all-row-selected', newVal);
    },
    isAllSelected(newVal) {
      // Watch for changes in isAllSelected and update isTicked accordingly
      this.isTicked = newVal;
    }
  }
};
</script>
<style scoped>
.table-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
</style>