<template>
  <div class="info-container" v-if="IsTwoline">
    <table class="info-table">
      <tbody>
        <tr v-for="(item, index) in oddIndexedDetails" :key="`odd-${index}`">
          <th>{{ item.name }}</th>
          <td :class="item.isActive ? 'text-active' : ''">{{ item.value }}</td>
        </tr>
      </tbody>
    </table>

    <table class="info-table">
      <tbody>
        <tr v-for="(item, index) in evenIndexedDetails" :key="`even-${index}`">
          <th>{{ item.name }}</th>
          <td :class="item.isActive ? 'text-active' : ''">{{ item.value }}</td>
        </tr>
      </tbody>
    </table>

  </div>

  <div v-else>
    <table class="info-table-two">
      <tbody>
        <tr v-for="(item, index) in indexedDetails" :key="`even-${index}`">
          <th>{{ item.name }}</th>
          <td :class="item.isActive ? 'text-active' : ''">{{ item.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'InfoTable',
  props: {
    items: {
      type: Object,
      required: true
    },
    IsTwoline: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    oddIndexedDetails() {
      return this.filterDetailsByIndex(true);
    },
    evenIndexedDetails() {
      return this.filterDetailsByIndex(false);
    }
    , indexedDetails() {
      return this.filterIndexedDetails();
    }
  },
  methods: {
    filterDetailsByIndex(isOdd) {
      return Object.entries(this.items)
        .map(([name, details], index) => ({
          name: name,
          value: details.value,
          isActive: details.isActive,
          index: index
        }))
        .filter(item => isOdd ? item.index % 2 !== 0 : item.index % 2 === 0);
    },
    filterIndexedDetails() {
      return Object.entries(this.items)
        .map(([name, details], index) => ({
          name: name,
          value: details.value,
          isActive: details.isActive,
          index: index
        }));
    }
  }
};
</script>

<style scoped>
.info-table {
  width: 100%;
  border-collapse: collapse;
}

.info-table-two {
  width: 100%;
  border-collapse: collapse;
}

.info-table-two th,
.info-table-two td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #f1f1f1;
}

.info-table th,
.info-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #f1f1f1;
}

.info-table th {
  background-color: #f9f9f9;
  font-weight: normal;
  width: 50%;
  /* Set the width of th to 35% */
}

.info-table-two th {
  background-color: #f9f9f9;
  font-weight: normal;
  width: 50% !important;
  /* Set the width of th to 35% */
}

.info-table-two td {
  width: 50% !important;
  /* Set the width of td to 65% */
}

.text-active {
  text-decoration: underline;
  font-weight: bold;
}

@media (min-width: 768px) {
  .info-container {
    display: flex;
  }
}

@media (max-width: 768px) {
  .info-table th {
    background-color: #f9f9f9;
    font-weight: normal;
    width: 100% !important;
    /* Set the width of th to 35% */
  }

  .info-table-two th {
    background-color: #f9f9f9;
    font-weight: normal;
    width: 100% !important;
    /* Set the width of th to 35% */
  }

  .info-table-two td {
    width: 100% !important;
    /* Set the width of td to 65% */
  }

}
</style>
