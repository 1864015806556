<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3" >
              <label for="login-id" class="form-label">To Currency</label>
              <input type="text" class="form-control" v-model="currencyExchangeData.toCurrency" disabled>
            </div>
            <div class="mb-3">
              <label for="full-name" class="form-label">From Currency</label>
              <input type="text" class="form-control" v-model="currencyExchangeData.currencyCode" required>
            </div>
            <div class="mb-3">
              <label for="full-name" class="form-label">Exchange Rate</label>
              <input type="text" class="form-control" v-model="currencyExchangeData.toUsdRate" required>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

import apiService from '@/components/Services/apiService';
const toast = useToast();
export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => ({
        id: '',
        toCurrency: 'USD',
        currencyCode: '',
        toUsdRate: '',
      })
    }
  },
  data() {
    return {
      // Create a local copy of the user data to edit
      currencyExchangeData: { ...this.row },
      permissions: []
    };
  },
  methods: {

    async submitForm() {
      try {

        if (!this.isEdit) {
          const response = await apiService.createCurrencyExchange(this.currencyExchangeData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Create Currency Exchange Success");
          } else {
            console.error('error:', response);
          }

        } else {
          const response = await apiService.updateCurrencyExchangeInformation(
            {
              id: this.currencyExchangeData.id,
              currencyCode: this.currencyExchangeData.currencyCode,
              toUsdRate: this.currencyExchangeData.toUsdRate,
            }
          );
          if (response.status === 200) {
            this.hideModal();
            toast.success("Update Currency Exchange Information Success");
          } else {
            console.error('error:', response);
          }
        }

      } catch (error) {
        console.error('error:', error);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    resetForm() {
      this.currencyExchangeData = {
        id: '',
        toCurrency: 'USD',
        currencyCode: '',
        toUsdRate: '',
      };


    },
  },
  watch: {
    row(newRow) {
      this.currencyExchangeData = { ...newRow };

      this.currencyExchangeData.toCurrency = 'USD'
    }
  }
};
</script>
<style>
.accessbox {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>