<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="paymentChannel-code" class="form-label">Code</label>
              <input type="text" class="form-control" id="paymentChannel-code" v-model="paymentChannelData.code"
                :disabled="isEdit">
            </div>
            <div class="mb-3">
              <label for="paymentChannel-name" class="form-label">Name</label>
              <input type="text" class="form-control" id="paymentChannel-name" v-model="paymentChannelData.name">
            </div>
            <div class="mb-3">
              <label for="paymentChannel-paymentMethodId" class="form-label">Payment Method</label>
              <SelectIncludeAll v-model="paymentChannelData.paymentMethodId" :options="paymentMethods"
                labelField="name" valueField="id" :includeAllOption="false">
              </SelectIncludeAll>
            </div>

            <div class="mb-3" v-if="isOnlinePayment && paymentGateway.length > 0">
              <label for="paymentChannel-paymentMethodId" class="form-label">Payment Gateway</label>
              <SelectIncludeAll v-model="paymentChannelData.paymentGatewayId" :options="paymentGateway"
                labelField="name" valueField="id" :includeAllOption="false">
              </SelectIncludeAll>
            </div>
            <div class="mb-3">
              <label for="file-upload" class="form-label">Upload File</label>
              <input type="file" class="form-control" id="file-upload" @change="handleFileUpload" :required="!isEdit">
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
const toast = useToast();

export default {
  name: 'DynamicModal',
  components: {
    SelectIncludeAll
  },
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => ({})
    },
    paymentMethods: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      paymentChannelData: { ...this.row },
      paymentGateway: [],
    };
  },
  computed: {
    isOnlinePayment() {
      const selectedMethod = this.paymentMethods.find(
        method => method.id === this.paymentChannelData.paymentMethodId
      );
      return selectedMethod ? selectedMethod.isOnlinePayment : false;
    }
  },
  watch: {
    paymentChannelData: {
      handler(newData) {
        console.log(newData)
        if (this.isOnlinePayment) {
          this.getPaymentGateway();
        }
      },
      deep: true,
    },
    row(newRow) {
      this.paymentChannelData = { ...newRow };
    }
  },
  methods: {
    createDefaultPaymentChannelData() {
      return {
        name: '',
        code: '',
        logoFileExtension: '',
        logoFile: '',
        paymentMethodId: '',
        paymentGatewayId: ''
      };
    },
    async getPaymentGateway() {
      try {
        const response = await apiService.getPaymentChannelGateways();
        if (response.status === 200 &&   response.data.length > 0) {
          this.paymentGateway = response.data;
        } else {
          console.error('No payment gateway found:', response);
        }
      } catch (error) {
        toast.error("Additional API Call Failed");
        console.error(error);
      }
    },
    async submitForm() {
      try {
        if (!this.isEdit) {
          const response = await apiService.createPaymentChannel(this.paymentChannelData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Create Payment Channel Success");
          } else {
            console.error('error:', response);
          }
        } else {
          const response = await apiService.updatePaymentChannelInformation({
            id: this.paymentChannelData.id,
            name: this.paymentChannelData.name,
            code: this.paymentChannelData.code,
            logoFileExtension: this.paymentChannelData.logoFileExtension,
            logoFile: this.paymentChannelData.logoFile,
            paymentMethodId: this.paymentChannelData.paymentMethodId,
            paymentGatewayId:this.paymentChannelData.paymentGatewayId,
          });

          if (response.status === 200) {
            this.hideModal();
            toast.success("Update Payment Channel Information Success");
          } else {
            console.error('error:', response);
          }
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    resetForm() {
      this.paymentChannelData = this.createDefaultPaymentChannelData();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const maxSize = 5245329;

      if (file) {
        if (file.size > maxSize) {
          toast.error('File size should not exceed 5MB');
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.paymentChannelData.logoFile = e.target.result.split(',').pop();
          this.paymentChannelData.logoFileExtension = file.name.split('.').pop();
        };
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>
