<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm" aria-label="Close"
                        ref="closeButton"></button>
                </div>
                <div class="modal-body">


                    <div class="row">

                        <div class="col-lg-12">
                            <div class="ibox">
                                <div class="ibox-content table-responsive">
                                    <rows-per-page-select v-model="pagination.perPage"
                                        @update:value="onPerPageChange"></rows-per-page-select>

                                    <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                                        :sortOrder="sorting.sortOrder" @sort-requested="sort">
                                        <!-- Define slots for action buttons -->

                                        <template v-slot:custom="{ row }">
                                            <span
                                                :class="row.transactionTypeId == creditType ? 'text-green' : 'text-danger'">

                                                {{ row.amount }}
                                            </span>
                                        </template>
                                        <template v-slot:teams="{ row }">
                 {{ row.homeTeamName }} vs {{ row.awayTeamName }}
                </template>
                                        <template v-slot:transactionType="{ row }">
                                            <span>

                                                {{ getTransactionTypeLabel(row.transactionSourceId) }}
                                            </span>
                                        </template>

                                        <template v-slot:adjustmentType="{ row }">
                                            <span>

                                                {{ getWalletAdjustmentTypeLabel(row.transactionTypeId) }}
                                            </span>
                                        </template>

                                    </DynamicTable>
                                    <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                                        @page-change="onPageChange"></pagination-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ref, reactive, watch, computed } from 'vue';
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
import { WalletAdjustmentType } from '@/enum/walletAdjustmentType';
import { getTransactionTypeLabel } from '@/enum/transactionType';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import DynamicTable from '@/components/Element/DynamicTable.vue';

export default {
    name: 'WalletManagement',
    components: {
        DynamicTable,
        PaginationComponent,
        RowsPerPageSelect
    },
    props: {
        title: String,
        modalId: {
            type: String,
            required: true
        },

        memberId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    setup(props, { emit }) {
        const toast = useToast();
        const walletData = reactive({
            walletBalance: '',
            transactionTypeId: '',
            amount: 0,
            remarks: ''
        });
        const closeButton = ref(null);
        const memberId = ref('');
        const rows = ref([]);
        const pagination = reactive({
            currentPage: 1,
            perPage: 5,
            totalRows: 0
        });
        const sorting = reactive({
            sortBy: 'id',
            sortOrder: 'desc'
        });
        const walletAdjustmentType = computed(() => {
            return Object.entries(WalletAdjustmentType).map(([label, value]) => ({ label, value }));
        });
        const creditType = walletAdjustmentType.value.find(type => type.label === 'Credit').value;

        const totalPages = computed(() =>
            Math.ceil(pagination.totalRows / pagination.perPage)
        );

        watch(() => props.memberId, (newRow) => {
            memberId.value = newRow;
            fetchBetUpgradeList(newRow);
        });


        const hideModal = () => {
            closeButton.value.click();
            emit('modalClose');
        };

        const tableColumns = [
            { name: 'Event ID', key: 'eventId', isSortable: true, isAction: false },
            { name: 'Teams', key: 'teamName', isSortable: true, isAction: true, slotName:'teams' },
            { name: 'Kick-off Time', key: 'eventAt', isSortable: true, isDate: true },
            { name: 'Bet Score', key: 'betScore', isSortable: true, isDate: false },
            { name: 'Bet Amount', key: 'betAmount', isSortable: true, isDate: false },
            { name: 'Final Score', key: 'eventScore', isSortable: true, isDate: false },
            { name: 'Profit', key: 'profitAmount', isSortable: true, isDate: false },
        ]

      
        const convertToDateTimeLocal = (dateString) => {
            if (!dateString) {
                return '';
            }
            const date = new Date(dateString);

            // Add 8 hours
            date.setHours(date.getHours() + 8);

            // Calculate the timezone offset in milliseconds
            const offset = date.getTimezoneOffset() * 60000;
            const localISOTime = (new Date(date.getTime() - offset)).toISOString().slice(0, -1);

            // Return the date in 'YYYY-MM-DDThh:mm' format
            return localISOTime.substring(0, 16);
        };


        async function fetchBetUpgradeList(memberId) {
            try {

                const skip = (pagination.currentPage - 1) * pagination.perPage;
                const params = {
                    id: memberId,
                    offset: skip,
                    limit: pagination.perPage,
                };

                // Only add sort and order if they have values
                if (sorting.sortBy) {
                    params.sort = sorting.sortBy;
                    params.order = sorting.sortOrder;
                }
                const response = await apiService.getMemberBetUpgradeList(params);
                rows.value = response.data.membershipUpgradeBets;
                pagination.totalRows = response.data.count;
            } catch (error) {
                toast.error(error.response.data.message);
            }

        }
        function sort(key) {
            if (sorting.sortBy === key) {
                sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                sorting.sortBy = key;
                sorting.sortOrder = 'asc';
            }
            fetchBetUpgradeList(memberId.value);
        }
        function onPageChange(newPage) {
            pagination.currentPage = newPage;
        }
        function onPerPageChange(newPerPage) {
            pagination.perPage = newPerPage;
            pagination.currentPage = 1;
            fetchBetUpgradeList(memberId.value);
        }

        function getWalletAdjustmentTypeLabel(adjustmentId) {
            return Object.keys(WalletAdjustmentType).find(key => WalletAdjustmentType[key] === adjustmentId);
        }



        return {
            walletData,
            creditType,
            closeButton,
            walletAdjustmentType,
            pagination,
            sorting,
            tableColumns,
            totalPages,
            rows,
            hideModal, convertToDateTimeLocal,
            sort, onPageChange, onPerPageChange,
            getWalletAdjustmentTypeLabel,
            getTransactionTypeLabel
        };
    }
};
</script>
<style>
@media (min-width: 768px) {
    .wallet-container {
        padding: 10px 10% !important;
        margin-bottom: 1.2em;
    }
}

.text-green {
    color: #28a745 !important;
}
</style>