<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal"
                        ref="closeButton"></button>
                </div>
                <div class="modal-body">
                    <div style="display: grid; grid-template-columns:60% 40%">
                        <Card title="Transaction Information">
                            <InfoTable :items="transDetails" :IsTwoline="false" />
                        </Card>
                        <div class="centralize">

                            <img alt="Receipt Image" class="receiptImg" :src="this.transData.receiptName" />
                            <div class="form-group row" style="padding-top: 1.5em;">
                                <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
                                    <Button button-type="button" button-class="btn-danger block   m-b" button-text="Reject"
                                        button-icon="fa-close" @click="reject" />

                                    <Button button-type="button" button-class="btn-primary block   m-b"
                                        button-text="Approve" button-icon="fa-check" @click="approved" />
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</template>
    
<script>

import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
import Card from '@/components/Element/Card.vue';
import InfoTable from '@/components/Element/InfoTable.vue';
import Button from '@/components/Element/Button.vue';
import { TransactionStatusEnum } from '@/enum/transactionStatus';
import debounce from 'lodash/debounce';
const toast = useToast();
export default {
    name: 'DynamicModal',
    props: {
        title: String,
        modalId: {
            type: String,
            required: true
        },
        row: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Card,
        InfoTable,
        Button
    },
    data() {
        return {
            transData: { ...this.row },
            transDetails: {
                "Transaction No": { value: '', isActive: false },
                "Login ID": { value: "", isActive: true },
                "Payment Method": { value: "", isActive: false },
                "Account Name": { value: "", isActive: false },
                "Transaction Date": { value: "", isActive: false },
                "Transaction Amount": { value: "", isActive: true },
                "Confirmed Amount": { value: "", isActive: true }
            },
            baseUrl: process.env.VUE_APP_API_BASE_URL,

        };
    },
    methods: {
        convertToDateTimeLocal(dateString) {
            if (!dateString) {
                return '';
            }
            const date = new Date(dateString);

            // Add 8 hours
            date.setHours(date.getHours() + 8);

            // Calculate the timezone offset in milliseconds
            const offset = date.getTimezoneOffset() * 60000;
            const localISOTime = (new Date(date.getTime() - offset)).toISOString().slice(0, -1);

            if (localISOTime) {
                const date = new Date(localISOTime.substring(0, 16)); return date.toLocaleDateString('en-US', {
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
                });
            }
            // Return the date in 'YYYY-MM-DDThh:mm' format
            return '-';
        },
        reject: debounce(async function () {
            try {
                const response = await apiService.updateDepositTransactionStatusById({
                    id: this.row.id,
                    status: TransactionStatusEnum.Rejected
                });

                if (response.status === 200) {
                    this.hideModal();
                    toast.error("Deposit Transaction Rejected");
                } else {
                    console.error('error:', response);
                }

            } catch (error) {
                toast.error(error.response.data.message);
            }
        }, 500), // Debounce for 500 milliseconds

        approved: debounce(async function () {
            try {

                const response = await apiService.updateDepositTransactionStatusById({
                    id: this.row.id,
                    status: TransactionStatusEnum.Approved
                });

                if (response.status === 200) {
                    this.hideModal();
                    toast.success("Deposit Transaction Approved");
                } else {
                    console.error('error:', response);
                }

            } catch (error) {
                toast.error(error.response.data.message);
            }
        }, 500),

        hideModal() {
            this.$emit('modalClose');
            this.$refs.closeButton.click();

        },
        updateTransDetails() {
            this.transDetails = {
                "Transaction No": { value: this.transData.id || '', isActive: false },
                "Login ID": { value: this.transData.playerUsername || "", isActive: true },
                "Payment Method": { value: this.transData.paymentMethodName || "", isActive: false },
                "Account Name": { value: this.transData.accountName || "", isActive: false },
                "Transaction Date": { value: this.convertToDateTimeLocal(this.transData.createdAt) || "", isActive: false },
                "Transaction Amount": { value: this.transData.amount || "", isActive: true },
                "Confirmed Amount": { value: this.transData.localAmount || "", isActive: true },
                
            };
        }
    },
    watch: {
        // Whenever the row prop changes, update the data properties
        row(newRow) {
            this.transData = { ...newRow };
            this.updateTransDetails();

            if (this.transData.receiptName) {
                this.transData.receiptName = `${this.baseUrl}/imageReceipt/${this.transData.receiptName}`;

            }

        }
    }
};
</script>
    
<style>
.centralize {
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
}

.receiptImg {
    width: 250px;
    height: 300px;
    padding-top: 10px;
}
</style>