<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        ref="closeButton"></button>
                </div>
                <div class="modal-body">
                    <!-- Dynamic content for your form inputs -->
                    <Card title="Basic Information">
                        <!-- Slotted content -->
                        <InfoTable :items="basicDetails" />
                    </Card>

                    <Card title="Referral Information">
                        <!-- Slotted content -->
                        <InfoTable :items="referralDetails" />
                    </Card>

                    <Card title="Compliance Analysis Info">
                        <!-- Slotted content -->
                        <InfoTable :items="complianceDetails" />
                    </Card>
                </div>


            </div>
        </div>
    </div>
</template>
    
<script>

import Card from '@/components/Element/Card.vue';
import InfoTable from '@/components/Element/InfoTable.vue';
export default {

    name: 'DynamicModal',
    props: {
        title: String,
        modalId: {
            type: String,
            required: true
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        row: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Card,
        InfoTable
    },
    computed: {
        memberData() {
            return this.row || {};
        },
        basicDetails() {
            const details = {
                "Login ID": { value: this.memberData.username || '', isActive: true },
                "Full Name": { value: this.memberData.name || '', isActive: false },
                "DOB": { value: this.convertToDateTimeLocal(this.memberData.dateOfBirth, false), isActive: false },
                "Phone": { value: this.memberData.fullContactNumber, isActive: false },
                "Email": { value: this.memberData.email, isActive: false },
                "Membership": { value: this.memberData.membershipName, isActive: true },
                "Referral Code": { value: this.memberData.referralCode, isActive: false }
            };
            return details
        },
        referralDetails() {
            return {
                "Referral": { value: this.memberData.refererUsername || '', isActive: false },
                "Direct Downline": { value: this.memberData.directDownline, isActive: false },
                "Group Size": { value: this.memberData.groupSize, isActive: false },
                "Commission": { value: this.memberData.totalCommission, isActive: false },
            };
        },
        complianceDetails() {
            return {
                "Registration Date/Time": { value: this.convertToDateTimeLocal(this.memberData.registerAt, true), isActive: false },
                "Last Login Date/Time": { value: this.convertToDateTimeLocal(this.memberData.lastLoginAt, true), isActive: false },
                "Registration IP": { value: this.memberData.registrationIp, isActive: false },
                "Last Login IP": { value: this.memberData.lastLoginIp, isActive: false },
                "Registration Source": { value: this.memberData.registrationDomain, isActive: false },
                "Last Login Source": { value: this.memberData.lastLoginDomain, isActive: false },
            };
        },
    }
    ,
    methods: {
        hideModal() {
            this.$refs.closeButton.click();
            this.$emit('modalClose');
        },
        convertToDateTimeLocal(dateString, isDatetime) {
            if (!dateString) {
                return '';
            }
            const date = new Date(dateString);

            // Add 8 hours
            date.setHours(date.getHours() + 8);

            // Calculate the timezone offset in milliseconds
            const offset = date.getTimezoneOffset() * 60000;
            const localISOTime = (new Date(date.getTime() - offset)).toISOString().slice(0, -1);

            if (localISOTime) {

                if (isDatetime) {
                    const date = new Date(localISOTime.substring(0, 16)); return date.toLocaleDateString('en-US', {
                        year: 'numeric', month: 'short', day: 'numeric',
                        hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
                    });
                } else {
                    const date = new Date(localISOTime.substring(0, 10)); return date.toLocaleDateString('en-US', {
                        year: 'numeric', month: 'short', day: 'numeric'
                    });
                }


            } else {
                return '-'
            }
        }
    }
};
</script>
    