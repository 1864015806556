<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3" v-if="isEdit">
              <label for="login-id" class="form-label">Role ID</label>
              <input type="text" class="form-control" v-model="roleData.id" disabled>
            </div>
            <div class="mb-3">
              <label for="full-name" class="form-label">Name</label>
              <input type="text" class="form-control" v-model="roleData.name" required>
            </div>
            <div class="mb-3">
              <label for="full-name" class="form-label">Access</label>
              <div style="display: flex;
    flex-wrap: wrap;
    gap: 5%;">
                <label v-for="permission in permissions" :key="permission.id" class="accessbox">
                  <input type="checkbox" class="form-check" v-model="permission.checked">
                  {{ permission.name }}
                </label>
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

import apiService from '@/components/Services/apiService';
const toast = useToast();
export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => ({
        id: '',
        name: '',
        pageIds: '',
      })
    }
  },
  created() {
    this.getPages();
  },
  data() {
    return {
      // Create a local copy of the user data to edit
      roleData: { ...this.row },
      permissions: []
    };
  },
  methods: {
    async getPages() {
      try {
        const response = await apiService.getRoleAccessPage();
        this.permissions = response.data;

      } catch (error) {
        console.error('error:', error);
      }
    },
    async submitForm() {
      try {
        const selectedPermissions = this.permissions
          .filter(permission => permission.checked)
          .map(permission => permission.id);

        if (!this.isEdit) {
          this.roleData.pageIds = selectedPermissions;
          const response = await apiService.createRole(this.roleData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Create Role Success");
          } else {
            console.error('error:', response);
          }

        } else {
          const response = await apiService.updateRoleAccess(
            {
              id: this.roleData.id,
              name: this.roleData.name,
              pageIds: selectedPermissions
            }
          );
          if (response.status === 200) {
            this.hideModal();
            toast.success("Update User Information Success");
          } else {
            console.error('error:', response);
          }
        }

      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    resetForm() {
      // Reset roleData to its default state
      this.roleData = {
        id: '',
        name: '',
        pageIds: '',
      };

      // Reset the checked state of each permission
      if (this.permissions && this.permissions.length > 0) {
        this.permissions.forEach(permission => {
          permission.checked = false;
        });
      }
    },
  },
  mounted() {
    this.getPages();
  },
  watch: {
    row(newRow) {
      this.roleData = { ...newRow };

      if (this.isEdit) {
        this.permissions.forEach(permission => {
          permission.checked = this.roleData.pageIds.includes(permission.id);
        });
      }

    }
  }
};
</script>
<style>
.accessbox {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>