<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t" @submit.prevent="search">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="ibox-content m-b-sm border-bottom">

        <div class="row">
          <!-- Status Dropdown -->
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="statusId">Membership</label>

              <SelectIncludeAll v-model="searchInput.membershipid" :options="memberships" labelField="name"
                valueField="id">
              </SelectIncludeAll>
            </div>
          </div>

          <!-- Status Dropdown -->
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="statusId">Status</label>
              <SelectIncludeAll v-model="searchInput.statusId" :options="statusArray" labelField="label" valueField="id">
              </SelectIncludeAll>
            </div>
          </div>
          <!-- Name Search Field (autocomplete functionality would need to be implemented) -->
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="name">Login ID</label>
              <Autocomplete placeholder="Search Login ID" :suggestions="loginIdSuggestions" @input="fetchLoginIdSuggestions($event)"  @select="onLoginIdSelect" />
              <!-- <InputField type="text" placeholder="Search Name" v-model="searchInput.username" /> -->
            </div>
          </div>
          <!-- Name Search Field (autocomplete functionality would need to be implemented) -->
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="name">Name</label>

              <InputField type="text" placeholder="Search Name" v-model="searchInput.name" />
            </div>
          </div>

        </div>

        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
            <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
              button-icon="fa-search" />

            <Button button-type="button" button-class="btn-warning block  m-b" button-text="Add" button-icon="fa-plus"
             @click="add" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content table-responsive">
              <rows-per-page-select v-model="pagination.perPage" @update:value="onPerPageChange"></rows-per-page-select>

              <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                :sortOrder="sorting.sortOrder" @sort-requested="sort">
                <!-- Define slots for action buttons -->
                <template v-slot:actionButton="{ row }">
                  <!-- Your custom action button -->
                  <div class="actionDiv">
                    <Icon icon="fa-edit" class="edit-icon" tooltip="Edit" @click="editRow(row)"></Icon>
                    <Icon icon="fa-info-circle" class="edit-icon" tooltip="Info" @click="viewRow(row)"></Icon>
                  </div>
                </template>

                <template v-slot:statusButton="{ row }">
                  <!-- Your custom action button -->
                  <span :class="[' status-label  badge', row.statusId === 1 ? 'bg-primary' : 'bg-danger']"
                    @click="updateStatus(row)">
                    {{ row.statusId === 1 ? 'Active' : 'Inactive' }}
                  </span>
                </template>
                <template v-slot:custom="{ row }">
                  <span class="text-active" @click="viewWallet(row)" >
                    {{ row.walletBalance }}
                  </span>
                </template>
              </DynamicTable>
              <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                @page-change="onPageChange"></pagination-component>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content">

              <Datatable :searchCriteria="searchCriteria"></Datatable>

            </div>
          </div>
        </div>
      </div> -->


    </div>
  </form>
  <!-- Include the modal component -->
  <dynamic-modal :title="modalTitle" :modal-id="'memberModal'" :is-edit="isEdit" :row="activeRow ? activeRow : null"  :memberships="memberships"
    @modalClose="modalClosedHandler" />
  <info-modal :modalId="'infoModal'" :title="'Member Info'" :is-edit="true" :row="activeRow" 
         />
        <WalletManagement :modalId="'walletModal'" :title="walletTitle" :is-edit="true" :memberId="memberId"
        @modalClose="modalClosedHandler" />
</template>
  
<script>
import { ref, computed, reactive, onMounted } from 'vue';
import InputField from '@/components/Element/InputField.vue';
import Button from '@/components/Element/Button.vue';
import DynamicModal from '@/components/Views/Member/MemberList/DynamicModal.vue';
import WalletManagement from '@/components/Views/Member/MemberList/WalletManagement.vue';
import { useToast } from 'vue-toastification';
import Autocomplete from "@/components/Element/Autocomplete.vue";
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import apiService from '@/components/Services/apiService';
import { StatusEnum } from '@/enum/status';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import Icon from '@/components/Element/Icon.vue';
import InfoModal from '@/components/Views/Member/MemberList/InfoModal.vue';

export default {
  components: {
    InputField,
    Button,
    DynamicModal,
    Autocomplete,
    SelectIncludeAll,
    DynamicTable,
    PaginationComponent,
    RowsPerPageSelect,
    Icon,
    InfoModal,
    WalletManagement
  },
  setup() {
    const loading = ref(false);
    const modalTitle = ref('Create Event');
    const walletTitle = ref('');
    const memberId = ref();
    const isEdit = ref();
    const toast = useToast();
    const activeRow = ref({});
    const rows = ref([]); 
    const memberships = ref([]);
    const pagination = reactive({
      currentPage: 1,
      perPage: 5,
      totalRows: 0
    });
    const sorting = reactive({
      sortBy: 'id',
      sortOrder: 'desc'
    });

    const loginIdSuggestions = ref([]);

    async function fetchAllMembers() {
      try {
        const response = await apiService.getMembers();
        if (response.data && response.data.players) {
          loginIdSuggestions.value = response.data.players.map(player => player.username);
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    onMounted(async () => {
      await fetchAllMembers();
    });
 
    const debounce = (func, delay) => {
      let debounceTimer;
      return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      }
    };

    const searchInput = reactive({
      membershipId: 0,
      statusId: 0,
      username: '',
      name: ''
    });


    fetchMembers();
    getMemberships();

    const totalPages = computed(() =>
      Math.ceil(pagination.totalRows / pagination.perPage)
    );


    const tableColumns = [
      { name: 'Action', key: 'action', isSortable: false, isAction: true, slotName: 'actionButton' },
      { name: 'Name', key: 'name', isSortable: true, isAction: false },
      { name: 'LoginId', key: 'username', isSortable: true, isAction: false },
      { name: 'Membership', key: 'membershipName', isSortable: true, isDate: false },
      { name: 'Phone', key: 'fullContactNumber', isSortable: true, isDate: false },
      { name: 'Wallet Balance', key: 'walletBalance', isSortable: true, isDate: false, slotName: 'custom', isCustom: true },
      { name: 'Status', key: 'statusId', isSortable: false, isAction: false, isStatus: true, slotName: 'statusButton', }
    ]

    async function getMemberships() {
      try {
        const response = await apiService.getMemberships();
        memberships.value = response.data.memberships;
      } catch (error) {
        console.error('error:', error);
      }
    }


    const statusArray = computed(() => Object.entries(StatusEnum).map(([label, id]) => ({ id, label })));

    async function editRow(row) {
      try {
        const memberDetails = await apiService.getMemberById({ id: row.id });
        activeRow.value = { ...memberDetails.data };
        modalTitle.value = 'Edit Member';
        isEdit.value = true;
        window.showModal('memberModal', true);
      } catch (error) {
        console.error('Failed to fetch role details:', error);
      }
    }

    async function viewRow(row) {
      try {
        const memberDetails = await apiService.getPlayerInfoById({ id: row.id });
        activeRow.value = { ...memberDetails.data };
        window.showModal('infoModal', true);
      } catch (error) {
        console.error('Failed to fetch role details:', error);
      }
    }

    async function viewWallet(row) {
      try {
        memberId.value = row.id;
        walletTitle.value = 'Member Wallet - ' + row.username;
        window.showModal('walletModal', true);
      } catch (error) {
        console.error('Failed to fetch role details:', error);
      }
    }

    function search() {
      fetchMembers()
    }

    function modalClosedHandler() {
      fetchMembers()
    }

    function sort(key) {
      if (sorting.sortBy === key) {
        sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        sorting.sortBy = key;
        sorting.sortOrder = 'asc';
      }
      fetchMembers();
    }

    async function fetchMembers() {
      if (loading.value) return; // Prevent duplicate calls
      loading.value = true;
      const skip = (pagination.currentPage - 1) * pagination.perPage;
      const params = {
        ...searchInput,
        offset: skip,
        limit: pagination.perPage,
      }; 

      // Only add sort and order if they have values
      if (sorting.sortBy) {
        params.sort = sorting.sortBy;
        params.order = sorting.sortOrder;
      }

      try {
        const response = await apiService.getMembers(params);
        rows.value = response.data.players;
        pagination.totalRows = response.data.count;
      } catch (error) {
        console.error('Error fetching users:', error);
      }

      loading.value = false;
    }



    async function updateStatus(row) {
      try {
        const newStatus = row.statusId === 1 ? 2 : 1;

        const response = await apiService.updateMemberStatusById({
          id: row.id,
          status: newStatus
        });

        if (response.status === 200) {
          toast.success(`Member status updated to ${newStatus === 1 ? 'Active' : 'Inactive'}`);
          fetchMembers();
        } else {
          toast.error('Failed to update member status');
        }
      } catch (error) {
        toast.error('Error occurred while updating member status');
      }
    }

    const add = debounce(() => {
      activeRow.value = null;
      modalTitle.value = 'Create Member';
      isEdit.value = false;
      window.showModal('memberModal', false);
    }, 300);

    function onLoginIdSelect(selectedOption) {
      searchInput.username = selectedOption;
    }

    function onPageChange(newPage) {
      pagination.currentPage = newPage;
    }

    function onPerPageChange(newPerPage) {
      pagination.perPage = newPerPage;
      pagination.currentPage = 1; // Reset to first page
      fetchMembers();
    }

    function fetchLoginIdSuggestions(selectedOption) {
      searchInput.username = selectedOption;
    }

    return {
      toast,
      searchInput,
      sorting,
      pagination,
      rows,
      statusArray,
      tableColumns,
      activeRow,
      loginIdSuggestions,
      totalPages,
      memberships,
      modalTitle,
      isEdit,
      walletTitle,
      memberId,
      editRow,
      viewRow,
      viewWallet,
      search,
      modalClosedHandler,
      onLoginIdSelect,
      onPageChange,
      onPerPageChange,
      sort,
      updateStatus,
      add,
      fetchLoginIdSuggestions
    };
  }
};
</script>