<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div :class="isEditModalShow ? 'modal-content anothermodal' : 'modal-content'">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm" aria-label="Close"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">

          <div class="tabs-container">
            <ul class="nav nav-tabs" role="tablist">
              <li>
                <a class="nav-link" :class="{ 'active': activeTab === 'tab-1' }" @click="setActiveTab('tab-1')">Basic
                  Info</a>
              </li>
              <li v-if="isEdit">
                <a class="nav-link" :class="{ 'active': activeTab === 'tab-2' }" @click="setActiveTab('tab-2')">Bank
                  Setup</a>
              </li>
            </ul>
            <div class="tab-content">
              <div role="tabpanel" id="tab-1" class="tab-pane" :class="{ 'active': activeTab === 'tab-1' }">
                <div class="p-1-2em">
                  <form @submit.prevent="submitForm" autocomplete="off">

                    <div class="mb-3  grid-3-7">
                      <label for="full-name" class="form-label">Full Name</label>
                      <input type="text" class="form-control" v-model="memberData.name" required>
                    </div>
                    <div class="mb-3  grid-3-7">
                      <label for="phone-number" class="form-label">Phone (Number)</label>
                      <div class="input-group m-b">
                        <div class="input-group-prepend">
                          <select class="form-control" v-model="memberData.countryCallingCodeId" required>
                            <option v-for="(code, index) in countryCodes" :key="index" :value="code.id"
                              autocomplete="off">
                              {{ code.name }}
                            </option>
                          </select>
                        </div>
                        <input type="text" class="form-control" v-model="memberData.contactNumber"
                          @keyup="checkContactNumber" required>
                        <!-- Display error message if contact number is invalid -->
                        <div v-if="!isContactNumberValid" class="invalid-feedback d-block">
                          Only numeric values are allowed for contact number.
                        </div>
                      </div>

                    </div>
                    <div class="mb-3  grid-3-7">
                      <label for="email" class="form-label">Email</label>
                      <input type="email" class="form-control" v-model="memberData.email">
                    </div>

                    <div class="mb-3  grid-3-7" v-if="isEdit">
                      <label for="presetData-name" class="form-label">Date of Birth</label>
                      <!-- Simplified v-model binding by removing 'this' -->
                      <input type="date" class="form-control" placeholder="Date of Birth"
                        v-model="memberData.dateOfBirth">

                    </div>
                    <div class="mb-3  grid-3-7">
                      <label for="username" class="form-label">Login ID</label>
                      <input type="text" class="form-control" v-model="memberData.username" required :disabled="isEdit">
                    </div>
                    <div class="mb-3  grid-3-7">
                      <label for="membershipId" class="form-label">Membership</label>
                      <SelectIncludeAll v-model="memberData.membershipId"
                        :options="memberships.map(membership => ({ label: membership.name, value: membership.id }))"
                        labelField="label" valueField="value" :includeAllOption="false">
                      </SelectIncludeAll>

                    </div>
                    <div class="mb-3  grid-3-7">
                      <label for="password" class="form-label">Password</label>

                      <div class="input-group m-b">
                        <input :type="showPassword ? 'text' : 'password'" class="form-control"
                          v-model="memberData.password" @keyup="checkPasswordStrength" :required="!isEdit"
                          autocomplete="off">

                        <div class="input-group-append">
                          <button type="button" class="btn btn-outline-secondary" @click="showPassword = !showPassword">
                            <span v-if="showPassword">  <Icon icon="fa-eye-slash" /></span>
                            <span v-else>  <Icon icon="fa-eye" /></span>
                          </button>
                        </div>
                      </div>


                      <div style="grid-column: 2;">


                        <!-- Password Strength Progress Bar -->
                        <div class="progress">
                          <div class="progress-bar" :class="passwordStrength.class"
                            :style="{ width: passwordStrength.width }">
                          </div>
                        </div>
                        <!-- Password Requirements List -->
                        <ul class="password-requirements">
                          <li :class="{ valid: passwordCriteria.minChar, invalid: !passwordCriteria.minChar }">
                            Minimum 6 characters
                          </li>


                        </ul>
                      </div>
                    </div>
                    <div class="mb-3  grid-3-7">
                      <label for="confirmed-password" class="form-label">Confirmed Password</label>

                      <div class="input-group m-b">
                        <input :type="showConfirmPassword ? 'text' : 'password'" class="form-control"
                          v-model="memberData.confirmedPassword"  :required="!isEdit"
                          autocomplete="off">

                        <div class="input-group-append">
                          <button type="button" class="btn btn-outline-secondary" @click="showConfirmPassword = !showConfirmPassword">
                            <span v-if="showConfirmPassword">  <Icon icon="fa-eye-slash" /></span>
                            <span v-else>  <Icon icon="fa-eye" /></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3  grid-3-7">
                      <label for="isOptForMarketing" class="form-label">Marketing Usage</label>
                      <div class="switch" @click="switchClick">
                        <div class="onoffswitch">
                          <input type="checkbox" class="onoffswitch-checkbox" v-model="memberData.isOptForMarketing">
                          <label class="onoffswitch-label" for="isOptForMarketing">
                            <span class="onoffswitch-inner"></span>
                            <span class="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3  grid-3-7">
                      <label for="referrerReferralCode" class="form-label">Referral Code</label>
                      <input type="text" class="form-control" v-model="memberData.referrerReferralCode">
                    </div>
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </form>
                </div>

              </div>
              <div role="tabpanel" id="tab-2" class="tab-pane" :class="{ 'active': activeTab === 'tab-2' }">
                <div class="panel-body">
                  <div class="p-1-2em">
                    <div class="row">
                      <div class="col-lg-12 wallet-container">
                        <form @submit.prevent="submitBankForm" autocomplete="off">

                          <div class="mb-3  grid-3-7">
                            <label for="full-name" class="form-label">Payment Channel</label>
                            <SelectIncludeAll v-model="bankData.paymentChannelId" :options="paymentChannels"
                              labelField="name" valueField="id" :includeAllOption="false">
                            </SelectIncludeAll>
                          </div>
                          <div class="mb-3  grid-3-7">
                            <label for="referrerReferralCode" class="form-label">Account
                              Name</label>
                            <input type="text" class="form-control" v-model="bankData.accountName">
                          </div>
                          <div class="mb-3  grid-3-7">
                            <label for="referrerReferralCode" class="form-label">Account
                              Number</label>
                            <input type="text" class="form-control" v-model="bankData.accountNumber">
                          </div>



                          <button type="submit" class="btn btn-primary">Submit</button>
                        </form>
                      </div>
                    </div>

                    <div class="row" v-if="bankRows">

                      <div class="col-lg-12">
                        <div class="ibox">
                          <div class="ibox-content table-responsive">
                            <rows-per-page-select v-model="pagination.perPage"
                              @update:value="onPerPageChange"></rows-per-page-select>

                            <DynamicTable :columns="tableColumns" :rows="bankRows" :sortingKey="sorting.sortBy"
                              :sortOrder="sorting.sortOrder" @sort-requested="sort">
                              <template v-slot:actionButton="{ row }">
                                <!-- Your custom action button -->
                                <div class="actionDiv">
                                  <Icon icon="fa-edit" class="edit-icon" tooltip="Edit" @click="editRow(row)"></Icon>
                                  <Icon icon="fa-times" class="edit-icon" tooltip="Inactive"
                                    @click="updateStatus(2, row)">
                                  </Icon>
                                </div>
                              </template>


                            </DynamicTable>
                            <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                              @page-change="onPageChange"></pagination-component>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>



          </div>

        </div>
      </div>
    </div>
  </div>
  <MemberBankModal :title="'Edit Member Bank'" :modal-id="'memberBankModal'" :row="activeRow"
    :paymentChannels="paymentChannels" @modalClose="modalClosedHandler" />
</template>
<script>
import { ref, reactive, watch, computed } from 'vue';
import { useToast } from 'vue-toastification';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import apiService from '@/components/Services/apiService';
import axios from 'axios';
import MemberBankModal from '@/components/Views/Member/MemberList/MemberBankModal.vue';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import Icon from '@/components/Element/Icon.vue';
export default {
  name: 'DynamicModal',
  components: {
    SelectIncludeAll,
    PaginationComponent,
    RowsPerPageSelect,
    DynamicTable,
    Icon,
    MemberBankModal
  },

  emits: ['modalClose'],
  props: {
    title: String,
    modalId: String,
    isEdit: {
      type: Boolean,
      default: false
    },
    memberships: Object,
    row: {
      type: Object,
      default: () => ({
        id: '',
        name: '',
        countryCallingCodeId: 1,
        contactNumber: '',
        email: '',
        username: '',
        membershipId: '',
        password: '',
        confirmedPassword: '',
        dateOfBirth: '',
        isOptForMarketing: false,
        isCreatedByBackoffice: true,
        referrerReferralCode: '',
        registrationIp: '',
        registrationDomain: '',
      })
    }
  },
  setup(props, { emit }) {
    const toast = useToast();
    const activeTab = ref('tab-1');
    const memberData = reactive(props.isEdit ? { ...props.row } : {});
    const bankData = reactive({ ...props.row });
    const passwordStrength = reactive({ width: '0%', class: 'bg-danger' });
    const passwordCriteria = reactive({
      minChar: false,
      lowercase: false,
      uppercase: false,
    });
    const isPasswordValid = ref(false);
    const isEditModalShow = ref(false);
    const passwordMismatch = ref(false);
    const isContactNumberValid = ref(true);
    const countryCodes = ref([]);
    const closeButton = ref(null);
    const activeRow = ref({});
    const bankRows = ref([]);
    const paymentChannels = ref([]);
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);

    const pagination = reactive({
      currentPage: 1,
      perPage: 5,
      totalRows: 0
    });
    const sorting = reactive({
      sortBy: 'createdAt',
      sortOrder: 'desc'
    });

    const totalPages = computed(() =>
      Math.ceil(pagination.totalRows / pagination.perPage)
    );

    const tableColumns = [
      { name: 'Payment Channel', key: 'paymentChannelCode', isSortable: true, isAction: false },
      { name: 'Account Name', key: 'accountName', isSortable: true, isAction: false },
      { name: 'Account Number', key: 'accountNumber', isSortable: true, isDate: false },
      { name: 'Action', key: 'action', isSortable: false, isAction: true, slotName: 'actionButton' },

    ]

    const getCountryCallingCode = async () => {
      try {
        const response = await apiService.getCountryCallingCode();
        countryCodes.value = response.data;
        if (countryCodes.value.length > 0) {
          memberData.countryCallingCodeId = countryCodes.value[0].id;
        }
      } catch (error) {
        console.error('error:', error);
      }
    };

    const switchClick = () => {
      memberData.isOptForMarketing = !memberData.isOptForMarketing;
    };

    const checkContactNumber = () => {
      const regex = /^[0-9]*$/; // Allow empty string or numbers
      isContactNumberValid.value = regex.test(memberData.contactNumber);
    };

    const submitBankForm = async () => {
      try {
        const submitData = {
          playerId: memberData.id,
          accountName: bankData.accountName,
          accountNumber: bankData.accountNumber,
          paymentChannelId: bankData.paymentChannelId,
        };
        const response = await apiService.createPlayerBankAccount(submitData);
        if (response.status === 200) {
          fetchBankDetails();
          toast.success("Create Member Bank Account Success");
        } else {
          console.error('error:', response);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };


    const submitForm = async () => {
      try {
        if (validatePassword() && isContactNumberValid) {

          if (!props.isEdit) {
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const ip = ipResponse.data.ip;
            const domain = window.location.hostname;
            const userData = {
              name: memberData.name,
              countryCallingCodeId: memberData.countryCallingCodeId,
              contactNumber: memberData.contactNumber,
              email: memberData.email,
              dateOfBirth: memberData.dateOfBirth,
              membershipId: memberData.membershipId,
              password: memberData.password,
              isOptForMarketing: memberData.isOptForMarketing,
              username: memberData.username,
              isCreatedByBackoffice: true,
              referrerReferralCode: memberData.referrerReferralCode,
              registrationIp: ip,
              registrationDomain: domain
            };


            const response = await apiService.createMember(userData);
            if (response.status === 200) {
              hideModal();
              toast.success("Create Member Success");
            } else {
              console.error('error:', response);
            }
          } else {
            const response = await apiService.updateMemberInformation(
              {
                id: memberData.id,
                name: memberData.name,
                countryCallingCodeId: memberData.countryCallingCodeId,
                contactNumber: memberData.contactNumber,
                email: memberData.email,
                dateOfBirth: memberData.dateOfBirth,
                membershipId: memberData.membershipId,
                password: memberData.password,
                isOptForMarketing: memberData.isOptForMarketing,
              }
            );
            if (response.status === 200) {
              hideModal();
              toast.success("Update Member Information Success");
            } else {
              console.error('error:', response);
            }
          }
        } else {
          toast.error("Invalid password or the passwords do not match.");
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };

    async function editRow(row) {

      try {
        const userDetails = await apiService.getPlayerBankAccountById({ id: row.id });
        isEditModalShow.value = true;
        activeRow.value = { ...userDetails.data };
        window.showModal('memberBankModal', true);
      } catch (error) {
        isEditModalShow.value = false;
        console.error('Failed to fetch member bank details:', error);
      }

    }


    async function updateStatus(value, row) {

      try {

        const newStatus = value;

        const response = await apiService.updatePlayerBankAccountStatusById({
          id: row.id,
          status: newStatus
        });

        if (response.status === 200) {
          row.statusId = newStatus;
          fetchBankDetails();
          toast.success(`Member Bank Account status updated to [${newStatus === 1 ? 'Active' : 'Inactive'}]`);
        } else {
          toast.error('Failed to update Member Bank Account status');
        }
      } catch (error) {
        toast.error('Error occurred while updating Member Bank Account status');
      }
    }

    function modalClosedHandler() {

      isEditModalShow.value = false;
      fetchBankDetails();
    }

    const hideModal = () => {
      closeButton.value.click();
      resetForm();
      emit('modalClose');
    };

    const checkPasswordStrength = () => {
      if (memberData.password) {
        const strength = getPasswordStrength(memberData.password);
        passwordStrength.width = strength.width;
        passwordStrength.class = strength.class;
        const password = memberData.password;
        passwordCriteria.minChar = password.length >= 6;
      }
    };

    const getPasswordStrength = (password) => {
      let strength = { width: '25%', class: 'bg-danger' }; // Weak by default

      // Update the strength object based on password criteria
      if (password.length <= 6) strength = { width: '50%', class: 'bg-warning' };
      if (password.length >= 6) {
        strength = { width: '100%', class: 'bg-success' };
      }

      return strength;
    };


    const validatePassword = () => {
      if (!props.isEdit && memberData.password) {
        passwordMismatch.value = false;
        isPasswordValid.value = false;

        if (!isPasswordStrong(memberData.password)) {
          return false;
        }
        if (memberData.password !== memberData.confirmedPassword) {
          passwordMismatch.value = true;
          return false;
        }
        isPasswordValid.value = true;
        return true;
      } else {

        return true;
      }
    };

    const isPasswordStrong = (password) => {
      return password.length >= 6;
    };

    const resetForm = () => {

      for (const key in memberData) {
        if (Object.hasOwnProperty.call(memberData, key)) {
          memberData[key] = null; // or set to default values as required
        }
      }
      memberData.countryCallingCodeId = countryCodes.value.length > 0 ? countryCodes.value[0].id : '',
        passwordStrength.value = { width: '0%', class: 'bg-danger' };
      passwordCriteria.value = {
        minChar: false,
      };
    };

    const convertToDateTimeLocal = (dateString) => {
      if (!dateString) {
        return '';
      }
      const date = new Date(dateString);

      // Calculate the timezone offset in milliseconds
      const offset = date.getTimezoneOffset() * 60000;
      const localISOTime = (new Date(date.getTime() - offset)).toISOString().slice(0, -1);

      // Return the date in 'YYYY-MM-DDThh:mm' format
      return localISOTime.substring(0, 10);
    };

    const setActiveTab = (tabId) => {
      activeTab.value = tabId;

    };



    watch(() => props.row, (newRow) => {

      if (props.isEdit) {

        Object.assign(memberData, newRow);
        checkContactNumber();

        memberData.dateOfBirth = convertToDateTimeLocal(newRow.dateOfBirth);

        fetchBankDetails();
      } else {
        resetForm();
      }
    }, { deep: true });




    async function getPaymentChannels() {
      try {
        const response = await apiService.getPaymentChannels();
        paymentChannels.value = response.data.paymentChannels;
      } catch (error) {
        console.error('error:', error);
      }
    }
    async function fetchBankDetails() {

      try {

        if (memberData.id) {
          const skip = (pagination.currentPage - 1) * pagination.perPage;
          const params = {
            playerId: memberData.id,
            statusId: 1,
            offset: skip,
            limit: pagination.perPage,
          };

          if (sorting.sortBy) {
            params.sort = sorting.sortBy;
            params.order = sorting.sortOrder;
          }
          const response = await apiService.getPlayerBankAccounts(params);
          bankRows.value = response.data.playerBankAccounts;
          pagination.totalRows = response.data.count;
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }

    }
    function sort(key) {
      if (sorting.sortBy === key) {
        sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        sorting.sortBy = key;
        sorting.sortOrder = 'asc';
      }
      fetchBankDetails();
    }
    function onPageChange(newPage) {
      pagination.currentPage = newPage;
    }
    function onPerPageChange(newPerPage) {
      pagination.perPage = newPerPage;
      pagination.currentPage = 1;
      fetchBankDetails();
    }
    getCountryCallingCode();
    getPaymentChannels();
    fetchBankDetails();

    return {
      showPassword,
      showConfirmPassword,
      activeTab,
      memberData,
      passwordStrength,
      passwordCriteria,
      isPasswordValid,
      passwordMismatch,
      isContactNumberValid,
      countryCodes,
      closeButton,
      bankData,
      bankRows,
      pagination,
      sorting,
      totalPages,
      tableColumns,
      paymentChannels,
      activeRow,
      isEditModalShow,
      editRow,
      // Methods
      getCountryCallingCode,
      switchClick,
      checkContactNumber,
      submitBankForm,
      submitForm,
      hideModal,
      checkPasswordStrength,
      getPasswordStrength,
      validatePassword,
      isPasswordStrong,
      resetForm,
      convertToDateTimeLocal,
      setActiveTab,
      onPageChange,
      onPerPageChange,
      sort,
      modalClosedHandler,
      updateStatus

    };
  }
};
</script>

<style scoped>
.progress {
  height: 5px;
  margin-top: 5px;
}

.progress-bar {
  transition: width 0.5s ease;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.password-requirements {
  padding-left: 0;
  list-style-type: none;
}

.password-requirements li {
  position: relative;
  padding-left: 20px;
  /* Give enough space for the icon */
}

.password-requirements li.valid::before {
  content: '✓';
  color: green;
  position: absolute;
  left: 0;
}

.password-requirements li.invalid::before {
  content: '✕';
  color: red;
  position: absolute;
  left: 0;
}

select.form-control:not([size]):not([multiple]) {
  height: unset !important;
}

.p-1-2em {
  padding: 1.2em !important;
}

.anothermodal {
  opacity: 0.6;
}
</style>