<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t" @submit.prevent="search">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="ibox-content m-b-sm border-bottom">

        <div class="row">

          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="name">Login ID</label>
              <Autocomplete placeholder="Search Login ID" :suggestions="loginIdSuggestions.map(player => player.username)"
                @input="fetchLoginIdSuggestions" @select="onLoginIdSelect" />
            </div>
          </div>

        </div>


        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
            <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
              button-icon="fa-search" />

            <Button button-type="button" button-class="btn-warning block  m-b" button-text="Add" button-icon="fa-plus"
              @click="add" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content table-responsive">
              <rows-per-page-select v-model="pagination.perPage" @update:value="onPerPageChange"></rows-per-page-select>

              <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                :sortOrder="sorting.sortOrder" @sort-requested="sort">
                <!-- Define slots for action buttons -->


                <template v-slot:statusButton="{ row }">
                  <!-- Your custom action button -->

                  <button :class="getStatusColor(row.statusId)" @click="verify(row)">
                    {{ getStatusLabel(row.statusId) }}
                  </button>
                </template>
              </DynamicTable>
              <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                @page-change="onPageChange"></pagination-component>
            </div>
          </div>
        </div>
      </div>


    </div>
  </form>

  <dynamic-modal :title="modalTitle" :modal-id="'transferModal'" :is-edit="isEdit" :row="activeRow" :loginIdSuggestions="loginIdSuggestions"
    @modalClose="modalClosedHandler" />

</template>



<script>
import { ref, reactive, computed } from 'vue';
import Button from '@/components/Element/Button.vue';
import DynamicModal from './DynamicModal.vue';
import { useToast } from 'vue-toastification';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import { TransactionStatusEnum } from '@/enum/transactionStatus';
import apiService from '@/components/Services/apiService';
import Autocomplete from "@/components/Element/Autocomplete.vue";
export default {
  components: {
    Button,
    DynamicModal,
    DynamicTable,
    PaginationComponent,
    RowsPerPageSelect,
    Autocomplete,
  },
  setup() {

    const rows = ref([]);
    const teams = ref([]);
    const toast = useToast();
    const activeRow = ref({});

    const modalTitle = ref('Create Transfer');
    const transferTransactionNo = ref('');
    const isEdit = ref();
    
    const debounce = (func, delay) => {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      }
    };

    const loginIdSuggestions = ref([]);
    const searchInput = reactive({
      loginId: ''
    });

    const sorting = reactive({
      sortBy: 'id',
      sortOrder: 'desc'
    });

    const pagination = reactive({
      currentPage: 1,
      perPage: 5,
      totalRows: 0
    });

    const statusArray = computed(() =>
      Object.entries(TransactionStatusEnum).map(([label, id]) => ({ id, label }))
    );

    const totalPages = computed(() =>
      Math.ceil(pagination.totalRows / pagination.perPage)
    );

    const tableColumns = [
      { name: 'Transaction No', key: 'id', isSortable: true, isAction: false },
      { name: 'Created Timestamp', key: 'createdAt', isSortable: true, isDate: true },
      { name: 'Transfer From', key: 'sourcePlayerUsername', isSortable: true, isAction: false },
      { name: 'Transfer To', key: 'destinationPlayerUsername', isSortable: true, isAction: false },
      { name: 'Amount', key: 'amount', isSortable: true, isAction: false, isNumber: true },
    ]

    fetchTransfers();
    fetchAllMembers();

    async function fetchTransfers() {
      const skip = (pagination.currentPage - 1) * pagination.perPage;
      // const params = { ...searchInput, offset: skip, limit: pagination.perPage, sort: sorting.sortBy, order: sorting.sortOrder };
      const params = { 
        offset: skip, limit: pagination.perPage, sort: sorting.sortBy, order: sorting.sortOrder ,
        playerUsername: searchInput.loginId
      };
      try {
        const response = await apiService.getTransferTransactions(params);
        rows.value = response.data.transferTransactions;

        pagination.totalRows = response.data.count;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }




    const add = debounce(() => {
      modalTitle.value = 'Create Transfer';
      isEdit.value = false;
      window.showModal('transferModal', false);
    }, 300);


    async function verify(row) {
      if (row.statusId == TransactionStatusEnum.Approved || row.statusId == TransactionStatusEnum.Rejected) {
        toast.warning('The [Approved / Rejected] transfer cannot be verify again.');
        return;
      }

      transferTransactionNo.value = row.id;
      try {
         const transactionDetails = await apiService.getTransferTransactionById({ id: row.id });
         activeRow.value = { ...transactionDetails.data };

        window.showModal('verifyModal', true);
        fetchTransfers();
      } catch (error) {
        console.error('Failed to fetch transaction (Transfer) details:', error);
      }

    }

    function sort(key) {
      if (sorting.sortBy === key) {
        sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        sorting.sortBy = key;
        sorting.sortOrder = 'asc';
      }
      fetchTransfers();
    }

    function search() {
      fetchTransfers();
    }

    function modalClosedHandler() {
      fetchTransfers();
    }


    function onPageChange(newPage) {
      pagination.currentPage = newPage;
    }

    function onPerPageChange(newPerPage) {
      pagination.perPage = newPerPage;
      pagination.currentPage = 1; 
      fetchTransfers();
    }

    function getStatusLabel(statusId) {
      return Object.keys(TransactionStatusEnum).find(key => TransactionStatusEnum[key] === statusId);
    }

    function getStatusColor(statusId) {
      var newStatus = Number(statusId);
      switch (newStatus) {
        case TransactionStatusEnum.Pending:
          return 'status-label badge border-none  bg-warning';
        case TransactionStatusEnum.Processing:
          return 'status-label badge border-none  bg-info';
        case TransactionStatusEnum.Approved:
          return 'status-label badge border-none  bg-primary';
        case TransactionStatusEnum.Rejected:
          return 'status-label badge border-none  bg-danger';

        default:
          return 'status-label badge border-none dropdown-toggle bg-secondary';
      }
    }

    async function fetchAllMembers() {
      try {
        const response = await apiService.getMembers();
        if (response.data && response.data.players) {
          loginIdSuggestions.value = response.data.players;
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    function fetchLoginIdSuggestions(query) {
      console.log(query);
    }

    function onLoginIdSelect(selectedOption) {
      searchInput.loginId = selectedOption;
    }

    return {
      searchInput,
      sorting,
      pagination,
      rows,
      statusArray,
      totalPages,
      tableColumns,
      activeRow,
      teams,
      modalTitle,
      isEdit,
      loginIdSuggestions,
      transferTransactionNo,
      search,
      modalClosedHandler,
      onPageChange,
      onPerPageChange,
      sort,
      getStatusColor,
      getStatusLabel,
      add,
      fetchLoginIdSuggestions,
      onLoginIdSelect,
      verify
    };
  }
};
</script>

<style>
.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-success {
  background-color: #18a689 !important;
}</style>