
    
<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <table class="table table-hover table-bordered">
            <thead class="table-light">
              <tr>
                <th>
                  Login ID
                </th>
                <th>
                  Registration Date
                </th>
                <th>
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in players" :key="row.id">

                <td>{{ row.username }}</td>
                <td>{{ formatDate(row.createdAt) }}</td>
                <td class="text-center">
                  <span :class="[' status-label  badge', row.statusId === 1 ? 'bg-primary' : 'bg-danger']">
                    {{ row.statusId === 1 ? 'Active' : 'Inactive' }}
                  </span>
                </td>
              </tr>

            </tbody>
          </table>



        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    players: {
  type: [Array, Object], // Accept either an array or an object
  default: () => [] // Default to an empty array if no value is provided
}
  },
  methods: {
    hideModal() {
      this.$refs.closeButton.click();
      this.$emit('modalClose');
    },
    formatDate(dateString) {

      if (dateString) {
        const date = new Date(dateString); 
        
        date.setHours(date.getHours() + 8);
        return date.toLocaleDateString('en-US', {
          year: 'numeric', month: 'short', day: 'numeric',
          hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
        });
      } else {
        return '-'
      }
    }
  }
};
</script>
  
