<template>
    <nav class="navbar-default navbar-static-side" role="navigation">
        <div class="sidebar-collapse">
            <ul id="side-menu" class="nav metismenu">
                <!-- Profile and other static elements omitted for brevity -->
                <li class="nav-header">
                    <div class="dropdown profile-element">
                        <img alt="Profile Image" class="rounded-circle" :src="require('@/assets/img/profile_small.jpg')" />

                        <div class="dropdown" :class="{ show: isDropdownOpen }">
                            <a class="dropdown-toggle" href="#" role="button" aria-haspopup="true"
                                aria-expanded="false">
                                <span class="block m-t-xs font-bold">{{this.loginName}}</span>
                                <span class="text-muted text-xs block">{{this.role}}
                                  </span>
                            </a>
                            <ul class="dropdown-menu animated fadeInRight m-t-xs" :class="{ show: isDropdownOpen }">
                                <li><a class="dropdown-item" href="profile.html">Profile</a></li>
                                <!-- <li><a class="dropdown-item" href="contacts.html">Contacts</a></li>
                                <li><a class="dropdown-item" href="mailbox.html">Mailbox</a></li> -->
                                <li class="dropdown-divider"></li>
                                <li><a class="dropdown-item" href="login.html">Logout</a></li>
                            </ul>
                        </div>

                    </div>
                    <div class="logo-element">
                        InvPro
                    </div>
                </li>
                <!-- Dynamic Routes -->
                <li v-for="route in sidebarRoutes" :key="route.path"
                    :class="{ 'active': isActive(route), 'has-children': hasChildren(route), 'isExpand' : isExpanded(route.path) || isActive(route) }">
                    <a v-if="hasChildren(route)" @click.prevent="toggleExpand(route)">
                        <Icon :icon="route.meta.icon" class="side-menu-icon" />
                        <span class="nav-label">{{ route.meta.title }}</span>
                        <span class="fa arrow"></span>
                    </a>
                    <router-link v-else :to="route.fullPath || route.path">
                        <Icon :icon="route.meta.icon" class="side-menu-icon" />
                        <span class="nav-label">{{ route.meta.title }}</span>
                    </router-link>

                    <!-- Sub-menu -->
                    <ul v-if="hasChildren(route)" class="nav nav-second-level collapse"
                        :class="{ 'in': isActive(route) || isExpanded(route.path)  }">
                        <li v-for="childRoute in route.children" :key="childRoute.path"
                            :class="{ 'active': isActiveChild(childRoute) }">
                            <router-link :to="childRoute.fullPath || childRoute.path">
                                {{ childRoute.meta.title }}
                            </router-link>
                        </li>
                    </ul>
                </li>

            </ul>
        </div>
    </nav>
</template>
  
<script>

import Icon from '@/components/Element/Icon.vue';
import apiService from '@/components/Services/apiService';
export default {
    components: {
        Icon
    },
    data() {
        return {
            expandedRoutes: {},
            isDropdownOpen: false,
            userPermissions: [],
            loginName:  localStorage.getItem('username'),
            role:  localStorage.getItem('role')
        };
    },
    mounted() {
        this.checkActiveRoute();
         this.getUserPermissions();
        document.addEventListener('click', this.outsideClickHandler);
    },
    beforeUnmount() {
        this.checkActiveRoute();
        document.addEventListener('click', this.outsideClickHandler);
    },
    computed: {
        sidebarRoutes() {
            return this.buildSidebarRoutes(this.$router.options.routes);
        },
    },
    methods: {
        async getUserPermissions() {
            const response = await apiService.checkUserSession();

            if(response.data.pageIds){
                this.userPermissions = response.data.pageIds;
            }
        },
        userCanAccessRoute(route) {
            const pageId = route.meta.pageId;
            return this.userPermissions.includes(pageId);
        },
        buildSidebarRoutes(routes, basePath = '') {
           
            return routes.reduce((acc, route) => {
                if (route.meta && route.meta.sidebar && this.userCanAccessRoute(route)) {
                    const fullPath = basePath + route.path;
                    acc.push({ ...route, fullPath });
                    if (route.children) {
                        // Filter out children routes that the user doesn't have access to
                        const children = route.children.filter(child => this.userCanAccessRoute(child));
                        acc = acc.concat(this.buildSidebarRoutes(children, fullPath));
                    }
                }
                return acc;
            }, []);
        },
        hasChildren(route) {
            return route.children && route.children.length > 0;
        },
        isActive(route) {
            return this.$route.path.startsWith(route.fullPath);
        },
        isActiveChild(route) {
            return this.$route.path.startsWith(route.path);
        },
        isExpanded(path) {
            return !!this.expandedRoutes[path];
        },
        toggleExpand(route) {
            this.expandedRoutes[route.path] = !this.expandedRoutes[route.path];
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        closeDropdown() {
            if (this.isDropdownOpen) {
                this.isDropdownOpen = false;
            }
        },
        outsideClickHandler(event) {
            if (!this.$el.contains(event.target)) {
                this.closeDropdown();
            }
        },
        checkActiveRoute() {
            // Assuming sidebarRoutes have been computed already
            const activeRoute = this.sidebarRoutes.find(route => {
                return this.isActive(route);
            });

            if (activeRoute && this.hasChildren(activeRoute)) {
                // If there is an active route and it has children, expand it
                this.expandedRoutes[activeRoute.path] = true;
            }
        },
    },
};
</script>
  