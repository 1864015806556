<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t" @submit.prevent="search">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="ibox-content m-b-sm border-bottom">

        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="role">Team</label>

              <SelectIncludeAll v-model="searchInput.teamId" :options="teams" labelField="name" valueField="id">
              </SelectIncludeAll>

            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="col-form-label" for="status">Status</label>

              <SelectIncludeAll v-model="searchInput.statusId" :options="statusArray" labelField="label" valueField="id">
              </SelectIncludeAll>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="col-form-label" for="name"> Name</label>

              <InputField type="text" placeholder="Name" v-model="searchInput.name" />
            </div>
          </div>


        </div>

        <DateRangeSelector @date-range-selected="onDateRangeSelected" />

        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
            <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
              button-icon="fa-search" />

            <Button button-type="button" button-class="btn-warning block  m-b" button-text="Add" button-icon="fa-plus"
              @click="add" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content table-responsive">
              <rows-per-page-select v-model="pagination.perPage" @update:value="onPerPageChange"></rows-per-page-select>

              <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                :sortOrder="sorting.sortOrder" @sort-requested="sort" >
                <!-- Define slots for action buttons -->
                <template v-slot:actionButton="{ row }">
                  <!-- Your custom action button -->
                  <div class="actionDiv">
                    <Icon icon="fa-edit" class="edit-icon" tooltip="Edit" @click="editRow(row)"></Icon>
                    <Icon icon="fa-square-caret-up" class="edit-icon" tooltip="Post" @click="updateStatus(2, row)">
                    </Icon>
                  </div>
                </template>
                <template v-slot:teams="{ row }">
                 {{ row.homeTeamName }} vs {{ row.awayTeamName }}
                </template>
                <template v-slot:statusButton="{ row }">
                  <!-- Your custom action button -->
                  <dropdown-menu-component v-if="Number(row.statusId) < 4" :options="statusArray" :isRequiredVerify="true"
                    :default-value="row.statusId" @update:value="value => updateStatus(value, row)"
                    :classList="getStatusColor(row.statusId)" labelField="label"
                    valueField="id"></dropdown-menu-component>


                  <span v-else :class="[' status-label  badge', row.statusId === 4 ? 'bg-primary' : 'bg-danger']">
                    {{ getStatusLabel(row.statusId) }}
                  </span>
                </template>
              </DynamicTable>
              <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                @page-change="onPageChange"></pagination-component>
            </div>
          </div>
        </div>
      </div>


    </div>
  </form>

  <CompletedModal :modalId="'resultModal'" :title="'Event Result'" :row="activeRow" @modalClose="modalClosedHandler" />
  <dynamic-modal :title="modalTitle" :modal-id="'eventModal'" :is-edit="isEdit" :row="isEdit ? activeRow : null"
    @modalClose="modalClosedHandler" />
</template>



<script>
import { ref, reactive, computed, nextTick } from 'vue';
import InputField from '@/components/Element/InputField.vue';
import Button from '@/components/Element/Button.vue';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import DynamicModal from './DynamicModal.vue';
import { useToast } from 'vue-toastification';
import DateRangeSelector from '@/components/Element/DateRangeSelector.vue';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import DropdownMenuComponent from '@/components/Element/DropdownMenuComponent.vue';
import { EventStatusEnum } from '@/enum/eventStatus';
import CompletedModal from './CompletedModal.vue';
import apiService from '@/components/Services/apiService';
import Icon from '@/components/Element/Icon.vue';
export default {
  components: {
    InputField,
    Button,
    DynamicModal,
    SelectIncludeAll,
    DynamicTable,
    Icon,
    PaginationComponent,
    RowsPerPageSelect,
    CompletedModal,
    DropdownMenuComponent,
    DateRangeSelector
  },
  setup() {

    const rows = ref([]);
    const teams = ref([]);
    const toast = useToast();
    const activeRow = ref({});

    const modalTitle = ref('Create Event');
    const isEdit = ref();

    const debounce = (func, delay) => {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      }
    };

    const searchInput = reactive({
      teamId: 0,
      statusId: 0,
      startEventAt: '',
      endEventAt: '',
      name: ''
    });

    const sorting = reactive({
      sortBy: 'id',
      sortOrder: 'desc'
    });

    const pagination = reactive({
      currentPage: 1,
      perPage: 5,
      totalRows: 0
    });

    const statusArray = computed(() =>
      Object.entries(EventStatusEnum).map(([label, id]) => ({ id, label }))
    );

    const totalPages = computed(() =>
      Math.ceil(pagination.totalRows / pagination.perPage)
    );

    const tableColumns = [
      { name: 'Action', key: 'action', isSortable: false, isAction: true, slotName: 'actionButton' },
      { name: 'Event ID', key: 'id', isSortable: true, isAction: false },
      { name: 'Teams', key: 'teams', isSortable: true, isAction: true, slotName: 'teams' },
      { name: 'Kick-off time', key: 'eventAt', isSortable: true, isDate: true },
      { name: 'Creation Time', key: 'createdAt', isSortable: true, isDate: true },
      { name: 'Status', key: 'statusId', isSortable: false, isAction: false, isStatus: true, slotName: 'statusButton', }
    ]

    fetchEvents();
    getTeams();

    async function getTeams() {
      try {
        const response = await apiService.getTeams();
        teams.value = response.data.teams;
      } catch (error) {
        console.error('error:', error);
      }
    }


    async function fetchEvents() {
      const skip = (pagination.currentPage - 1) * pagination.perPage;
      const params = { ...searchInput, offset: skip, limit: pagination.perPage, sort: sorting.sortBy, order: sorting.sortOrder };
      try {
        const response = await apiService.getEvents(params);
        rows.value = [];
        await nextTick();
        rows.value = response.data.events;

        pagination.totalRows = response.data.count;
      } catch (error) {
        toast.error('Error fetching events');
        console.error('Error fetching events:', error);
      }
    }

    async function updateStatus(value, row) {

      if (row.statusId == EventStatusEnum.Cancelled || row.statusId == EventStatusEnum.Completed) {
        toast.warning('The [Completed / Cancelled] event cannot be posted again.');
        return;
      }

      try {
        var newStatus = Number(value);
        if (newStatus != EventStatusEnum.Completed) {
          const response = await apiService.updateEventStatusById({
            id: row.id,
            status: newStatus
          });

          var str = getStatusLabel(newStatus);

          if (response.status === 200) {
            row.statusId = newStatus;

            toast.success(`Event status updated to ${str}`);
            fetchEvents();
          } else {
            toast.error('Failed to update event status');
          }
        } else if (newStatus == EventStatusEnum.Completed) {
          activeRow.value = { ...row };
          window.showModal('resultModal', true);
        }

      } catch (error) {
        toast.error('Error occurred while updating event status');
      }
    }

    async function editRow(row) {
      if (row.statusId == EventStatusEnum.Completed) {
        toast.warning('Event has been completed');
        return;
      }

      if (row.statusId == EventStatusEnum.Cancelled) {
        toast.warning('Event has been cancelled');
        return;
      }
      const today = new Date();
       const eventDate = new Date(row.eventAt).add;

      // Remove the time part of the current date to compare only the dates
      today.setHours(today.getHours() - 8);

      if (eventDate < today) {
         toast.warning('The kick-off time already in the past and cannot be edited.');
         return;
       }

      try {
        const userDetails = await apiService.getEventById({ id: row.id });
        activeRow.value = { ...userDetails.data };
        modalTitle.value = 'Edit Event';
        isEdit.value = true;
        window.showModal('eventModal', false);
      } catch (error) {
        console.error('Failed to fetch event details:', error);
      }

    }

    const add = debounce(() => {
      activeRow.value = null
      modalTitle.value = 'Create Event';
      isEdit.value = false;
      window.showModal('eventModal', false);
    }, 300);

    function sort(key) {
      if (sorting.sortBy === key) {
        sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        sorting.sortBy = key;
        sorting.sortOrder = 'asc';
      }
      fetchEvents();
    }

    function search() {
      fetchEvents();
    }

    function modalClosedHandler() {
      fetchEvents();
    }

    function onDateRangeSelected(dateRange) {
      searchInput.startEventAt = dateRange.start;
      searchInput.endEventAt = dateRange.end;
    }

    function onPageChange(newPage) {
      pagination.currentPage = newPage;
    }

    function onPerPageChange(newPerPage) {
      pagination.perPage = newPerPage;
      pagination.currentPage = 1; // Reset to first page
      fetchEvents();
    }

    function getStatusLabel(statusId) {
      return Object.keys(EventStatusEnum).find(key => EventStatusEnum[key] === statusId);
    }

    function getStatusColor(statusId) {
      switch (statusId) {
        case EventStatusEnum.Draft:
          return 'status-label badge border-none dropdown-toggle ';
        case EventStatusEnum.Pending:
          return 'status-label badge border-none dropdown-toggle bg-warning';
        case EventStatusEnum.Running:
          return 'status-label badge border-none dropdown-toggle bg-info';
        case EventStatusEnum.Completed:
          return 'status-label badge border-none dropdown-toggle bg-success';
        case EventStatusEnum.Cancelled:
          return 'status-label badge border-none dropdown-toggle bg-error';
        default:
          return 'status-label badge border-none dropdown-toggle bg-secondary';
      }
    }

    return {
      searchInput,
      sorting,
      pagination,
      rows,
      statusArray,
      totalPages,
      tableColumns,
      activeRow,
      teams,
      modalTitle,
      isEdit,
      search,
      modalClosedHandler,
      updateStatus,
      editRow,
      onPageChange,
      onPerPageChange,
      sort,
      getStatusColor,
      getStatusLabel,
      onDateRangeSelected,
      add
    };
  }
};
</script>

<style scoped>
::v-deep(.table-responsive) {
  min-height: 550px;
}
</style>