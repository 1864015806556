<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t" @submit.prevent="search">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="ibox-content m-b-sm border-bottom">



        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
       
            <Button button-type="button" button-class="btn-warning block  m-b" button-text="Add" button-icon="fa-plus"
              data-bs-toggle="modal" data-bs-target="#createRoleModal" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content">

              <Datatable  :searchCriteria="searchCriteria"></Datatable>

            </div>
          </div>
        </div>
      </div>


    </div>
  </form>
  <!-- Include the modal component -->
  <dynamic-modal title="Create Currency Exchange" modal-id="createRoleModal"  :is-edit="false" @modalClose="modalClosedHandler"/>
</template>
  
<script>
// Import your layout components
import Datatable from './Datatable.vue';
import Button from '@/components/Element/Button.vue';
import DynamicModal from './DynamicModal.vue';
import { useToast } from 'vue-toastification';

export default {
  components: {
    Datatable,
    Button,
    DynamicModal,
  },
  data() {
    return {
      searchInput: {
        statusId: 0,
        name: ''
      },
      searchCriteria: {
        statusId: 0,
        name: ''
      },
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    search() {
      this.searchCriteria = { ...this.searchInput };
    },
    modalClosedHandler() {
      this.searchCriteria = { ...this.searchInput };
    }
  }
};
</script>
