import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js'; // Import the router

import VueSelect from 'vue-select';


// Importing Font Awesome functionality and all solid icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


// Importing VueToastify and its styles
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';


//Sweet Alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// Importing styles
import './assets/css/bootstrap.min.css';
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/font-awesome/css/font-awesome.min.css';
import 'vue-select/dist/vue-select.css';
import { Modal } from 'bootstrap';

window.showModal = function(modalId, isBackdrop) {
  const modalElement = document.getElementById(modalId);
  const bootstrapModal = new Modal(modalElement, {
    backdrop: isBackdrop ? 'static' : true,
    keyboard: false
  });
  bootstrapModal.show();
};



// Adding all solid icons to the library
library.add(fas);



// Create the app instance
const app = createApp(App);

// Register FontAwesomeIcon globally
app.component('font-awesome-icon', FontAwesomeIcon);

//Register Vue Select
app.component("v-select", VueSelect);

// Setup VueToastify with options
const toastOptions = {
  // You can set your toast options here
};
app.use(Toast, toastOptions);

// Use the sweetalert2 vue wrapper
app.use(VueSweetalert2);


// Use the router
app.use(router);

// Mount the app
app.mount('#app');
