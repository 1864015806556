<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ currentRouteName }}</h2>
      <ol class="breadcrumb">
        <li v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb-item">
          <a :href="crumb.href">{{ crumb.text }}</a>
        </li>
      </ol>
    </div>
    <div class="col-lg-2">
      <!-- Additional content if needed -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // Assuming you have a route name you can use as the heading
      currentRouteName: this.$route.name,
      breadcrumbs: []
    };
  },
  watch: {
    $route(to) {
      // Update the breadcrumb every time the route changes
      this.updateBreadcrumbs(to);
    }
  },
  created() {
    // Initialize the breadcrumb when the component is created
    this.updateBreadcrumbs(this.$route);
  },
  methods: {
    updateBreadcrumbs(route) {
      // Reset the breadcrumbs array
      this.breadcrumbs = [];

      // Variable to keep track of the accumulated path
      let pathAccumulator = '';


      // Add other breadcrumbs based on the current route
      route.matched.forEach((matched) => {
        // Ignore the root path ('/')
        if (matched.path !== '/') {
          // Accumulate the path
          pathAccumulator += `/${matched.path.split('/')[1]}`;

          // Push the breadcrumb with the accumulated path
          this.breadcrumbs.push({
            text: matched.meta.title, // Assuming you have a title in your route meta
            href: pathAccumulator,
          });
        }
      });

      // Set the current route name
      this.currentRouteName = route.meta.title; // Assuming you have a title in your route meta
    }
  }
};
</script>
  