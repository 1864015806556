<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="m-b-sm border-bottom">

        <div class="row">
          <div class="ibox ">
            <div class="ibox-title">
              <h5>Commission Setup</h5>

            </div>
            <div class="ibox-content">
              <form @submit.prevent="submitForm">

                <div class="form-group row"><label class="col-lg-2 col-form-label">Processing fees</label>

                  <div class="col-lg-10">
                    <div class="input-group m-b">

                      <input type="text" class="form-control" v-model="formData.processingFee" @input="restrictToNumbers">
                      <div class="input-group-append">
                        <span class="input-group-addon">%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-2 col-form-label">Agent Commission <br> (Based on processing fees)</label>

                  <div class="col-lg-10">
                    <table class="table table-hover table-bordered">
                      <thead class="table-light">
                        <tr>
                          <th>Membership Level</th>
                          <th>Commission rate %</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(membership) in memberships" :key="membership.id">
                          <td>
                            {{ membership.name }}
                          </td>
                          <td>
                            <input type="text" class="form-control" :value="getRateByMembershipId(membership.id)"
                              @input="bindAgentRateInput($event, membership.id)">
                          </td>
                          <!-- Additional columns can be added here if needed -->
                        </tr>


                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-lg-2 col-form-label">Generation Rewards <br> (Based on agent comm.)</label>

                  <div class="col-lg-10">
                    <table class="table table-hover table-bordered">
                      <thead class="table-light">
                        <tr>
                          <th>Membership Level</th>
                          <th>Number of Generation</th>
                          <th>Rate %</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(membership) in memberships" :key="membership.id">
                          <td>
                            {{ membership.name }}

                          </td>
                          <td>
                            <input type="text" class="form-control"
                              :value="getLevelGenerationByMembershipId(membership.id)"
                              @input="bindLevelGenerationInput($event, membership.id)">
                          </td>
                          <td>
                            <input type="text" class="form-control" :value="getLevelRateByMembershipId(membership.id)"
                              @input="bindLevelRateInput($event, membership.id)">
                          </td>
                          <!-- Additional columns can be added here if needed -->
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-lg-2 col-form-label">Ranking Bonus</label>

                  <div class="col-lg-10">
                    <div class="input-group m-b">

                      <input type="text" class="form-control" v-model="formData.rankingBonus" @input="restrictToNumbers">
                      <div class="input-group-append">
                        <span class="input-group-addon">%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-2 col-form-label">Global Bonus</label>

                  <div class="col-lg-10">
                    <div class="input-group m-b">

                      <input type="text" class="form-control" v-model="formData.globalBonus" @input="restrictToNumbers">
                      <div class="input-group-append">
                        <span class="input-group-addon">%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12 col-sm-offset-2" style="float: right;">
                    <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Save"
                      button-icon="fa-save" />

                  </div>
                </div>
              </form>
            </div>
          </div>



        </div>


      </div>




    </div>
  </form>
</template>



<script>
import { ref } from 'vue';
import Button from '@/components/Element/Button.vue';
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
export default {
  components: {
    Button,
    // Icon
  },
  setup() {

    const toast = useToast();

    const memberships = ref([]);

    const formData = ref({
      processingFee: 0,
      rankingBonus: 0,
      globalBonus: 0,
      commissionAgentRate: [
        { membershipId: 0, rate: 0 },
      ],
      commissionGeneration: [
        { membershipId: 0, generationLevel: 0, rate: 0 },
      ],
    });

    getMemberships();
    getCommission();
    const defaultOptions = [
      { value: '0', text: '0' },
    ];

    async function getMemberships() {
      try {
        const response = await apiService.getMemberships();
        memberships.value = response.data.memberships.filter(item => item.statusId == 1);
      } catch (error) {
        console.error('error:', error);
      }
    }

    async function getCommission() {
      try {
        const response = await apiService.getCommissionSettings();
        formData.value = response.data;
      } catch (error) {
        console.error('error:', error);
      }
    }

    function bindAgentRateInput(event, membershipId) {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '');

      // Avoid more than one dot
      event.target.value = event.target.value.replace(/(\..*)\./g, '$1');

      const value = event.target.value;
      const index = formData.value.commissionAgentRate.findIndex(s => s.membershipId === membershipId);

      if (index !== -1) {
        formData.value.commissionAgentRate[index].rate = Number(value);
      } else {
        // Push a new object since the membershipId was not found
        formData.value.commissionAgentRate.push({ membershipId: membershipId, rate: 0 });
        console.error('No matching membershipId found in commissionAgentRate array');
      }
    }

    function bindLevelRateInput(event, membershipId) {

      event.target.value = event.target.value.replace(/[^0-9.]/g, '');

      // Avoid more than one dot
      event.target.value = event.target.value.replace(/(\..*)\./g, '$1');
      const value = event.target.value;
      const index = formData.value.commissionGeneration.findIndex(s => s.membershipId === membershipId);

      if (index !== -1) {
        formData.value.commissionGeneration[index].rate = Number(value);
      } else {
        // Push a new object since the membershipId was not found
        formData.value.commissionGeneration.push({ membershipId: membershipId, rate: 0, generationLevel: 0 });
        console.error('No matching membershipId found in commissionGeneration array');
      }
    }

    function bindLevelGenerationInput(event, membershipId) {

      event.target.value = event.target.value.replace(/[^0-9.]/g, '');

      // Avoid more than one dot
      event.target.value = event.target.value.replace(/(\..*)\./g, '$1');
      const value = event.target.value;
      const index = formData.value.commissionGeneration.findIndex(s => s.membershipId === membershipId);

      if (index !== -1) {
        formData.value.commissionGeneration[index].generationLevel = Number(value);
      } else {
        // Push a new object since the membershipId was not found
        formData.value.commissionGeneration.push({ membershipId: membershipId, rate: 0, generationLevel: 0 });
        console.error('No matching membershipId found in commissionGeneration array');
      }
    }


    function getRateByMembershipId(membershipId) {
      const setting = this.formData.commissionAgentRate.find(s => s.membershipId === membershipId);
      return setting ? setting.rate : 0; // Return null or a default value if no rate is found
    }

    function getLevelRateByMembershipId(membershipId) {
      const setting = this.formData.commissionGeneration.find(s => s.membershipId === membershipId);
      return setting ? setting.rate : 0; // Return null or a default value if no rate is found
    }

    function getLevelGenerationByMembershipId(membershipId) {
      const setting = this.formData.commissionGeneration.find(s => s.membershipId === membershipId);
      return setting ? setting.generationLevel : 0; // Return null or a default value if no rate is found
    }


    async function submitForm() {

      const response = await apiService.updateCommissionSettings(formData.value);
      if (response.status === 200) {

        toast.success("Commission Setting Saved.");
      } else {
        console.error('error:', response);
      }


    }

    function restrictToNumbers(event) {

      event.target.value = event.target.value.replace(/[^0-9.]/g, '');

      // Avoid more than one dot
      event.target.value = event.target.value.replace(/(\..*)\./g, '$1');
    }



    return {
      defaultOptions,
      memberships,
      formData,
      submitForm,
      getRateByMembershipId,
      bindLevelRateInput,
      bindAgentRateInput,
      getLevelRateByMembershipId,
      restrictToNumbers,
      bindLevelGenerationInput,
      getLevelGenerationByMembershipId
    };
  }
};
</script>

<style scoped>
::v-deep(.table-responsive) {
  min-height: 550px;
}

.ibox-content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  >form {
    width: 70%;
  }
}

.btn-primary {
  float: right;
}
</style>