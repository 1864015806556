<template>
    <transition name="slide">
      <div class="drawer-backdrop" v-if="isOpen" @click="close">
        <div class="right-drawer" @click.stop>
          <!-- Slot for content -->
          <slot></slot>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import { ref, watch, defineComponent } from 'vue';
  
  export default defineComponent({
    props: {
      modelValue: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const isOpen = ref(props.modelValue);
  
      watch(() => props.modelValue, (newValue) => {
        isOpen.value = newValue;
      });
  
      const close = () => {
        emit('update:modelValue', false);
      };
  
      return { isOpen, close };
    }
  });
  </script>
  
  <style scoped>
  .slide-enter-active, .slide-leave-active {
    transition: opacity 0.5s, transform 0.5s;
  }
  .slide-enter-from, .slide-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }
  .drawer-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: flex-end; /* Align drawer container to the right */
  }
  .right-drawer {
    min-width: 40%;
    background-color: white;
    padding: 20px;
    overflow-y: auto;
    height: 100%;
    transform: translateX(100%); /* Start hidden to the right */
  }
  .drawer-backdrop .right-drawer {
    transform: translateX(0); /* Slide in to view */
  }
  </style>
  