<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ title }} : {{ totalCommission.toFixed(2) }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        ref="closeButton"></button>
                </div>
                <div class="modal-body">


                    <div class="row">

                        <div class="col-sm-4">
                            <div class="form-group custom-flex">
                                <span>Login ID</span><input type="text" class="form-control" v-model="name" disabled />
                            </div>

                        </div>
                        <div class="col-lg-12">
                            <div class="ibox">
                                <div class="ibox-content table-responsive">
                                    <rows-per-page-select v-model="pagination.perPage"
                                        @update:value="onPerPageChange"></rows-per-page-select>

                                    <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                                        :sortOrder="sorting.sortOrder" @sort-requested="sort">
                                        <!-- Define slots for action buttons -->
                                        <template v-slot:teams="{ row }">
                                            {{ row.homeTeamName }} vs {{ row.awayTeamName }}
                                        </template>
                                        <template v-slot:custom="{ row }">
                                            <span>
                                                {{ getCommissionTypeLabel(row.commissionTypeId) }}
                                            </span>
                                        </template>

                                    </DynamicTable>
                                    <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                                        @page-change="onPageChange"></pagination-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ref, reactive, watch, computed } from 'vue';
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
import { getCommissionTypeLabel } from '@/enum/commType';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import DynamicTable from '@/components/Element/DynamicTable.vue';

export default {
    name: 'CommissionModal',
    components: {
        DynamicTable,
        PaginationComponent,
        RowsPerPageSelect
    },
    props: {
        title: String, memberName: String,
        modalId: {
            type: String,
            required: true
        },
        memberId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    setup(props, { emit }) {
        const toast = useToast();
        const closeButton = ref(null);
        const id = ref(0);
        const name = ref('');
        const totalCommission = ref(0);
        const rows = ref([]);
        const pagination = reactive({
            currentPage: 1,
            perPage: 5,
            totalRows: 0
        });
        const sorting = reactive({
            sortBy: 'createdAt',
            sortOrder: 'desc'
        });

        const totalPages = computed(() =>
            Math.ceil(pagination.totalRows / pagination.perPage)
        );

        watch(() => props.memberId, (newRow) => {
            id.value = newRow;
            pagination.currentPage = 1;
            fetchCommissionDetails(id.value);
        });



        const hideModal = () => {
            closeButton.value.click();
            emit('modalClose');
        };

        const tableColumns = [
            { name: 'Transaction No.', key: 'id', isSortable: true, isAction: false },
            { name: 'Login ID', key: 'sourceUsername', isSortable: true, isDate: false },
            { name: 'Transaction Date/Time', key: 'createdAt', isSortable: true, isDate: true },
            { name: 'Event Name', key: 'teamName', isSortable: true, isDate: false, slotName: 'teams', isCustom: true },
            { name: 'Comm. Type', key: 'commissionTypeId', isSortable: true, isDate: false, slotName: 'custom', isCustom: true },
            { name: 'Comm. %', key: 'commissionRate', isSortable: true, isDate: false },
            { name: 'Comm. Amount', key: 'commissionAmount', isSortable: true, isDate: false },
            { name: 'Remarks', key: 'remarks', isSortable: true, isDate: false },
        ]



        async function fetchCommissionDetails(mbId) {
            try {
                name.value = props.memberName;
                const skip = (pagination.currentPage - 1) * pagination.perPage;
                const params = {
                    id: mbId,
                    offset: skip,
                    limit: pagination.perPage,
                };

                if (sorting.sortBy) {
                    params.sort = sorting.sortBy;
                    params.order = sorting.sortOrder;
                }
                const response = await apiService.getPlayerCommissionListbyId(params);
                rows.value = response.data.playerCommissions;
                totalCommission.value = rows.value.reduce((acc, row) => acc + row.commissionAmount, 0);
                pagination.totalRows = response.data.count;
            } catch (error) {
                toast.error(error.response.data.message);
            }

        }
        function sort(key) {
            if (sorting.sortBy === key) {
                sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                sorting.sortBy = key;
                sorting.sortOrder = 'asc';
            }
            fetchCommissionDetails(id.value);
        }
        function onPageChange(newPage) {
            pagination.currentPage = newPage;
            fetchCommissionDetails(id.value);
        }
        function onPerPageChange(newPerPage) {
            pagination.perPage = newPerPage;
            pagination.currentPage = 1;
            fetchCommissionDetails(id.value);
        }




        return {
            closeButton, pagination, sorting,
            tableColumns, totalPages, rows,
            totalCommission,
            name
            , hideModal,
            sort, onPageChange, onPerPageChange,
            getCommissionTypeLabel
        };
    }
};
</script>
<style scoped>
@media (min-width: 768px) {
    .modal-dialog {
        min-width: 1200px !important;
    }
}

.custom-flex {
    display: flex;
    white-space: nowrap;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
}
</style>