
<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog" style="max-width: 800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">

            <div class="mb-3">
              <label for="presetData-ID" class="form-label">Division</label>
              <v-select multiple :options="divisions.map(division => ({ label: division.name, value: division.id }))"
                :reduce="division => division.value" v-model="presetData.divisionIds">
              </v-select>

              <div v-if="!validationState.divisionIdsValid" class="text-danger">
                Please select at least one division.
              </div>

            </div>
            <div class="mb-3">
              <label for="presetData-name" class="form-label">Team A</label>

              <SelectIncludeAll v-model="presetData.homeTeamId"
                :options="teamA.map(team => ({ label: team.name, value: team.id }))" labelField="label" valueField="value"
                :includeAllOption="false">
              </SelectIncludeAll>

              <div v-if="!validationState.homeTeamIdValid" class="text-danger">
                Please select the Home Team.
              </div>
            </div>

            <div class="mb-3">
              <label for="presetData-name" class="form-label">Team B</label>


              <SelectIncludeAll v-model="presetData.awayTeamId"
                :options="filteredTeamB.map(team => ({ label: team.name, value: team.id }))" labelField="label"
                valueField="value" :includeAllOption="false">
              </SelectIncludeAll>
              <div v-if="!validationState.awayTeamIdValid" class="text-danger">
                Please select the Away Team.
              </div>
            </div>


            <div class="mb-3">
              <label for="presetData-name" class="form-label">Kick-off Date</label>
              <!-- Simplified v-model binding by removing 'this' -->
              <input type="datetime-local" class="form-control" placeholder="Kick-off Date" v-model="presetData.eventAt"
                :min="today">
              <div v-if="!validationState.eventAtValid" class="text-danger">
                Please choose the Kick-off Date.
              </div>
            </div>


            <!-- Table for Half Time Odds -->

            <div v-if="isEdit">

              <div style="display: flex;gap: 10px;">
                <h5 style="font-size: 15px;">Half Time </h5>

                <div>
                  <dropdown-menu-component v-if="presetList.length > 0"
                    :options="[{ label: 'Select preset data', value: 0 }].concat(presetList.map(data => ({ label: data.name, value: data.id })))"
                    :isRequiredVerify=false :default-value="presetHalf" @update:value="onSelectHalf"
                    classList="status-label label border-none dropdown-toggle bg-info w-100" labelField="label"
                    valueField="value"></dropdown-menu-component>
                </div>
              </div>

              <div class="table-responsive" style="padding: 10px;">
                <table class="table table-hover table-bordered table-bordered">
                  <thead>
                    <tr>
                      <th>Hot</th>
                      <th>Goals</th>
                      <th>Profit</th>
                      <th>Total Volume</th>
                      <th>Fake Volume</th>
                      <th>Ratio</th>
                      <th style="width: 250px;">Created Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Table for Half Time Odds -->
                    <tr v-for="(item, index) in presetData.details.filter(detail => detail.oddTypeId === 1)"
                      :key="`half-${item.id}-${index}`">
                      <td> <input type="checkbox" v-model="item.isHot"></td>
                      <td>{{ item.goal }}</td>
                      <td><input type="text" class="form-control" v-model="item.profit"></td>
                      <td><input type="text" class="form-control" v-model="item.volume" @keyup="calculateRatio(item)">
                      </td>
                      <td><input type="text" class="form-control" v-model="item.fakeVolume" @keyup="calculateRatio(item)">
                      </td>
                      <td><input type="text" class="form-control" v-model="item.ratio" disabled></td>
                      <td><input type="text" class="form-control" :value="formatDate(item.createdAt)" disabled></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Table for Full Time Odds -->
              <div style="display: flex;gap: 10px;">
                <h5 style="font-size: 15px;">Full Time </h5>



                <div>
                  <dropdown-menu-component v-if="presetList.length > 0"
                    :options="[{ label: 'Select preset data', value: 0 }].concat(presetList.map(data => ({ label: data.name, value: data.id })))"
                    :isRequiredVerify=false :default-value="presetFull" @update:value="onSelect"
                    classList="status-label label border-none dropdown-toggle bg-info w-100" labelField="label"
                    valueField="value"></dropdown-menu-component>
                </div>

              </div>
              <div class="table-responsive" style="padding: 10px;">

                <table class="table table-hover table-bordered table-bordered">
                  <thead>
                    <tr>
                      <th>Hot</th>
                      <th>Goals</th>
                      <th>Profit</th>
                      <th>Total Volume</th>
                      <th>Fake Volume</th>
                      <th>Ratio</th>
                      <th style="width: 250px;">Created Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Table for Half Time Odds -->
                    <tr v-for="(item, index) in presetData.details.filter(detail => detail.oddTypeId === 2)"
                      :key="`full-${item.id}-${index}`">
                      <td><input type="checkbox" v-model="item.isHot"></td>
                      <td>{{ item.goal }}</td>
                      <td><input type="text" class="form-control" v-model="item.profit" @input="handleDecimalInput($event, item)"></td>
                      <td><input type="text" class="form-control" v-model="item.volume" @keyup="calculateRatio(item)">
                      </td>
                      <td><input type="text" class="form-control" v-model="item.fakeVolume" @keyup="calculateRatio(item)">
                      </td>
                      <td><input type="text" class="form-control" v-model="item.ratio" disabled></td>
                      <td><input type="text" class="form-control" :value="formatDate(item.createdAt)" disabled></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';

import { ref } from 'vue';
import DropdownMenuComponent from '@/components/Element/DropdownMenuComponent.vue';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
const toast = useToast();

export default {
  name: 'DynamicModal',
  components: {
    DropdownMenuComponent,
    SelectIncludeAll
  },
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: Boolean,
    row: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      presetData: {
        ...this.row,
        details: []
      },
      oddsHalf: [],
      oddsFull: [],
      teamA: [],
      teamB: [],
      divisions: [],
      presetList: ref([]),
      validationState: {
        divisionIdsValid: true,
        homeTeamIdValid: true,
        awayTeamIdValid: true,
        eventAtValid: true,
      },
      presetHalf: null,
      presetFull: null,

    };
  },
  methods: {
    onSelectHalf(value) {
      this.presetHalf = value;
    },
    onSelect(value) {
      this.presetFull = value;
    },
    validateForm() {
      this.validationState.divisionIdsValid = this.presetData.divisionIds.length > 0;
      this.validationState.homeTeamIdValid = !!this.presetData.homeTeamId;
      this.validationState.awayTeamIdValid = !!this.presetData.awayTeamId;
      this.validationState.eventAtValid = !!this.presetData.eventAt;

      return this.validationState.divisionIdsValid &&
        this.validationState.homeTeamIdValid &&
        this.validationState.awayTeamIdValid &&
        this.validationState.eventAtValid;
    },
    formatDate(dateString) {

      if (dateString) {
        const date = new Date(dateString); return date.toLocaleDateString('en-US', {
          year: 'numeric', month: 'short', day: 'numeric',
          hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
        });
      } else {
        return '-'
      }

    },
    convertToDateTimeLocal(dateString) {
      if (!dateString) {
        return '';
      }
      const date = new Date(dateString);

      // Add 8 hours
      date.setHours(date.getHours() + 8);

      // Calculate the timezone offset in milliseconds
      const offset = date.getTimezoneOffset() * 60000;
      const localISOTime = (new Date(date.getTime() - offset)).toISOString().slice(0, -1);

      // Return the date in 'YYYY-MM-DDThh:mm' format
      return localISOTime.substring(0, 16);
    },
    calculateRatio(item) {
      // Ensure both volume and fakeVolume are numbers and not empty
      const volume = parseFloat(item.volume);
      const fakeVolume = parseFloat(item.fakeVolume);

      if (!isNaN(volume) && !isNaN(fakeVolume) && fakeVolume !== 0) {
        item.ratio = (fakeVolume / volume).toFixed(2); // Keeping two decimal places
      } else {
        item.ratio = 0; // Reset ratio if inputs are invalid
      }
    },
    async getOdds(typeId) {
      try {
        const response = await apiService.getOdds({ typeId });
        return response.data.odds;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    async submitForm() {
      try {


        if (!this.validateForm()) {
          toast.error('Please fill all required fields.');
          return;
        }
        //eslint-disable-next-line no-unused-vars
        const detailsWithoutGoals = this.presetData.details.map(({ goal, ...rest }) => {

          return rest;
        });

        // Subtract 8 hours from eventAt
        const adjustedEventAt = new Date(this.presetData.eventAt);
        // adjustedEventAt.setHours(adjustedEventAt.getHours() - 8);

        // Convert to ISO string or any desired format
        const formattedEventAt = adjustedEventAt.toISOString();

        const createData = {
          divisionIds: this.presetData.divisionIds,
          homeTeamId: this.presetData.homeTeamId,
          awayTeamId: this.presetData.awayTeamId,
          eventAt: formattedEventAt,
        };

        const response = await (this.isEdit
          ? apiService.updateEventInformation({ id: this.presetData.id, divisionIds: this.presetData.divisionIds, homeTeamId: this.presetData.homeTeamId, awayTeamId: this.presetData.awayTeamId, eventAt: formattedEventAt, odds: detailsWithoutGoals })
          : apiService.createEvent(createData));

        if (response.status === 200) {

          this.hideModal();
          toast.success(`${this.isEdit ? 'Update' : 'Create'} Event Success`);
        } else {
          console.error('error:', response);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
    },
    async getDivisions() {
      try {
        const response = await apiService.getDivisions();
        this.divisions = response.data.divisions;
      } catch (error) {
        console.error('error:', error);
      }
    },
    getRandomValue(minProfit, maxProfit) {
      console.log(minProfit);
      console.log(maxProfit);
      return Math.random() * (maxProfit - minProfit) + minProfit;
    },
    async getTeamsForDivisions() {
      const defaultOption = { id: '', name: 'Please select' };
      this.teamA = [defaultOption];
      this.teamB = [defaultOption];

      if (this.presetData && Array.isArray(this.presetData.divisionIds)) {
        try {
          // Create an array of promises for each divisionId
          const teamPromises = this.presetData.divisionIds.map(async divisionId => {
            const params = { divisionId };
            const response = await apiService.getTeams(params);
            return response.data.teams;
          });

          // Wait for all promises to resolve
          const teamsArray = await Promise.all(teamPromises);

          // Concatenate all teams to teamA and teamB
          teamsArray.forEach(teams => {
            this.teamA = this.teamA.concat(teams);
            this.teamB = this.teamB.concat(teams);
          });

          // Add the default option to the start of the arrays (if not already present)
          if (this.teamA[0].id !== '') {
            this.teamA.unshift(defaultOption);
          }
          if (this.teamB[0].id !== '') {
            this.teamB.unshift(defaultOption);
          }

        } catch (error) {
          console.error('error:', error);
        }
      } else {
        this.teamA = [defaultOption];
        this.teamB = [defaultOption];
      }
    },
    async getPresetData() {
      try {
        const response = await apiService.getOddPresets();
        this.presetList = response.data.oddPresets;
        await this.$nextTick();
      } catch (error) {
        console.error('error:', error);
      }
    },
    async setPresetData(id, type) {
      try {
        const response = await apiService.getOddPresetById({ id: id });
        if (type === 1) {
          const halfTimeDetails = response.data.details.filter(detail => detail.oddTypeId === 1);

          this.presetData.details = this.presetData.details.map(detail => {
            if (detail.oddTypeId === 1) {
              const temp = halfTimeDetails.find(hDetail => hDetail.oddId === detail.oddId);
              const ranValue = this.getRandomValue(temp.minProfit, temp.maxProfit);
              temp.profit = ranValue;
              const updatedDetail = temp;

              return updatedDetail ? { ...detail, ...updatedDetail } : { ...detail, profit: ranValue, volume: 0, fakeVolume: 0 };
            }
            return detail;
          });
        }

        if (type === 2) {
          const halfTimeDetails = response.data.details.filter(detail => detail.oddTypeId === 2);

          this.presetData.details = this.presetData.details.map(detail => {
            if (detail.oddTypeId === 2) {
              const temp = halfTimeDetails.find(hDetail => hDetail.oddId === detail.oddId);
              const ranValue = this.getRandomValue(temp.minProfit, temp.maxProfit);
              temp.profit = ranValue;
              const updatedDetail = temp;
              return updatedDetail ? { ...detail, ...updatedDetail } : { ...detail, profit: ranValue, volume: 0, fakeVolume: 0 };
            }
            return detail;
          });
        }


      } catch (error) {
        console.error('error:', error);
      }

    },
    resetForm() {
      this.presetData.divisionIds = [];
      this.presetData.awayTeamId = null;
      this.presetData.homeTeamId = null;
      this.presetData.eventAt = null;
      this.presetHalf = null,
        this.presetFull = null
      this.$emit('modalClose');
    },
    handleDecimalInput(event, item) {
      let value = event.target.value;
      value = value.replace(/,/g, '.');
      const match = value.match(/^\d*(\.\d{0,2})?/);
      item.profit = match ? match[0] : '';
    }, 
   
  },
  async mounted() {
    this.getPresetData();
    this.getDivisions();

    const halfOdds = await this.getOdds(1);
    const fullOdds = await this.getOdds(2);
    this.presetData.details = [
      ...halfOdds.map(odd => ({
        goal: odd.name,
        oddId: odd.id,
        oddTypeId: 1,
        profit: 0,
        volume: 0,
        fakeVolume: 0,
        ratio: 0,
        createdAt: '',
        isHot: false
      })),
      ...fullOdds.map(odd => ({
        goal: odd.name,
        oddId: odd.id,
        oddTypeId: 2,
        profit: 0,
        volume: 0,
        fakeVolume: 0,
        ratio: 0,
        createdAt: '',
        isHot: false
      }))
    ];
    if (this.isEdit) {

      if (this.row && this.row.eventAt) {
        this.presetData.eventAt = this.convertToDateTimeLocal(this.row.eventAt);
      }


    }


  },
  watch: {
    'presetData.divisionIds': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getTeamsForDivisions();
        }
      },
      deep: true
    },
    'presetHalf': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setPresetData(newVal, 1);
        }
      },
      deep: true
    },
    'presetFull': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setPresetData(newVal, 2);
        }
      },
      deep: true
    },
    async row(newRow) {
      // Update the main properties of presetData with those from newRow
      if (newRow && this.isEdit) {
        this.presetData = {
          ...this.presetData,
          ...newRow,
          divisionIds: Array.isArray(newRow.divisionIds) ? newRow.divisionIds.join(',') : this.presetData.divisionIds,
          eventAt: this.convertToDateTimeLocal(newRow.eventAt)
        };

        if (Array.isArray(newRow.eventOdds) && newRow.eventOdds.length > 0) {

          const newDetailsMap = new Map(newRow.eventOdds.map(detail => [detail.oddId, detail]));

          const existingDetailsMap = new Map(this.presetData.details.map(detail => [detail.oddId, detail]));

          const mergedDetails = newRow.eventOdds.map(detail => {
            return { ...existingDetailsMap.get(detail.oddId), ...detail };
          });

          this.presetData.details.forEach(detail => {
            if (!newDetailsMap.has(detail.oddId)) {
              mergedDetails.push(detail);
            }
          });

          this.presetData.details = mergedDetails;
          this.presetData.details.forEach(detail => {
            detail.createdAt = this.convertToDateTimeLocal(detail.createdAt);
          });

          if (Array.isArray(newRow.divisionIds)) {
            this.presetData.divisionIds = [...newRow.divisionIds];
          } else {
            this.presetData.divisionIds = [];
          }


        } else {
          const halfOdds = await this.getOdds(1);
          const fullOdds = await this.getOdds(2);
          this.presetData.details = [
            ...halfOdds.map(odd => ({
              goal: odd.name,
              oddId: odd.id,
              oddTypeId: 1,
              profit: 0,
              volume: 0,
              fakeVolume: 0,
              ratio: 0,
              createdAt: '',
              isHot: false
            })),
            ...fullOdds.map(odd => ({
              goal: odd.name,
              oddId: odd.id,
              oddTypeId: 2,
              profit: 0,
              volume: 0,
              fakeVolume: 0,
              ratio: 0,
              createdAt: '',
              isHot: false
            }))
          ];
        }
        this.presetData.homeTeamId = newRow.homeTeamId || null;
        this.presetData.awayTeamId = newRow.awayTeamId || null;
        this.presetData.divisionIds = Array.isArray(newRow.divisionIds) ? [...newRow.divisionIds] : [];
      } else {
        this.resetForm();
      }
    }

  },


  computed: {
    today() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const year = today.getFullYear();

      // Add a time component of 00:00 to make it compatible with datetime-local
      return `${year}-${month}-${day}T00:00`;
    },
    filteredTeamB() {
      // Return all teams except the one selected for Team A
      return this.teamB.filter(team => team.id !== this.presetData.homeTeamId);
    }
  }
};
</script>
<style scoped>
.w-100 {
  width: 100%;
}
</style>