<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t" @submit.prevent="search">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="ibox-content m-b-sm border-bottom">

        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="status">Membership</label>

              <SelectIncludeAll v-model="searchInput.membershipId" :options="memberships" labelField="name"
                valueField="id">
              </SelectIncludeAll>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="name">Login ID</label>

                <Autocomplete placeholder="Search Login ID" :suggestions="loginIdSuggestions.map(player => player.username)" @input="fetchLoginIdSuggestions($event)"  @select="onLoginIdSelect" />
              <!-- <InputField type="text" placeholder="Search Name" v-model="searchInput.playerUsername" /> -->
            </div>
          </div>


        </div>

        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
            <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
              button-icon="fa-search" />


          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content table-responsive">
              <rows-per-page-select v-model="pagination.perPage" @update:value="onPerPageChange"></rows-per-page-select>
              <!-- <Datatable :searchInput="searchInput"></Datatable> -->
              <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                :sortOrder="sorting.sortOrder" @sort-requested="sort">
                <!-- Define slots for action buttons -->
                <template v-slot:betAmount="{ row }">
                  <span class="text-active" @click="viewTransactions(row)">
                    {{ row.betAmount }}
                  </span>
                </template>

              </DynamicTable>
              <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                @page-change="onPageChange"></pagination-component>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
  <DownlineBettingTransaction :modalId="'downlineBettingTransactionModal'" :title="'Downline Betting Transaction'"
    :memberId="memberId" @modalClose="modalClosedHandler" />
</template>

<script>
import Button from '@/components/Element/Button.vue';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import apiService from '@/components/Services/apiService';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import { StatusEnum } from '@/enum/status';
import { useToast } from 'vue-toastification';
import { ref, reactive, computed } from 'vue';

import DownlineBettingTransaction from '@/components/Views/Member/MembershipUpgrade/DownlineBettingTransaction.vue';
import Autocomplete from "@/components/Element/Autocomplete.vue";
export default {
  components: {
    Button,
    SelectIncludeAll,
    DynamicTable,
    PaginationComponent,
    RowsPerPageSelect,
    Autocomplete,
    DownlineBettingTransaction
  },
  setup() {
    const searchInput = reactive({
      membershipId: 0,
      playerUsername: ''
    });

    const sorting = reactive({
      sortBy: '',
      sortOrder: ''
    });

    const pagination = reactive({
      currentPage: 1,
      perPage: 5,
      totalRows: 0
    });

    const memberId = ref(0);

    const rows = ref([]);
    const toast = useToast();
    const activeRow = ref({});

    const memberships = ref([]);
    const loginIdSuggestions = ref([]);
    const statusArray = computed(() =>
      Object.entries(StatusEnum).map(([label, id]) => ({ id, label }))
    );


    function sort(key) {
      if (sorting.sortBy === key) {
        sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        sorting.sortBy = key;
        sorting.sortOrder = 'asc';
      }

      fetchAllMemberUpgrade();
    }

    const totalPages = computed(() =>
      Math.ceil(pagination.totalRows / pagination.perPage)
    );

    const tableColumns = [
      { name: 'Login ID', key: 'playerUsername', isSortable: true, isAction: false },
      { name: 'Level From', key: 'fromMembershipName', isSortable: true, isAction: false },
      { name: 'Level To', key: 'toMembershipName', isSortable: true, isAction: false },
      { name: 'Downline Total Betting Amount', key: 'betAmount', isSortable: true, isAction: false, slotName: 'betAmount', isCustom: true },
      { name: 'Upgrade timestamp', key: 'createdAt', isSortable: true, isAction: false, isDate: true },
    ]

    fetchAllMembers();
    fetchAllMemberUpgrade();
    getMemberships();

    function search() {
      fetchAllMemberUpgrade();
    }

    function modalClosedHandler() {
      fetchAllMemberUpgrade();
    }
    async function fetchAllMembers() {
      try {
        const response = await apiService.getMembers();
        if (response.data && response.data.players) {
          loginIdSuggestions.value = response.data.players;
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }

    async function getMemberships() {
      try {
        const response = await apiService.getMemberships();
        memberships.value = response.data.memberships;
      } catch (error) {
        console.error('error:', error);
      }
    }

    async function fetchAllMemberUpgrade() {
      const skip = (pagination.currentPage - 1) * pagination.perPage;
      const params = {
        ...searchInput,
        offset: skip,
        limit: pagination.perPage,
        statusId: searchInput.statusId,
        name: searchInput.name
      };

      // Only add sort and order if they have values
      if (sorting.sortBy) {
        params.sort = sorting.sortBy;
        params.order = sorting.sortOrder;
      }

      try {
        const response = await apiService.getMembershipUpgradeList(params);

        rows.value = response.data.membershipUpgrades;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }

    async function viewTransactions(row) {
      try {
        memberId.value = row.id;

        console.log( row);
        window.showModal('downlineBettingTransactionModal', false);
      } catch (error) {
        console.error('Failed to fetch role details:', error);
      }
    }

    function onPageChange(newPage) {
      pagination.currentPage = newPage;
    }

    function onPerPageChange(newPerPage) {
      pagination.perPage = newPerPage;
      pagination.currentPage = 1; // Reset to first page

      fetchAllMemberUpgrade();
    }

    function onLoginIdSelect(selectedOption) {
      searchInput.playerUsername = selectedOption;
    }

    function fetchLoginIdSuggestions(selectedOption) {
      searchInput.playerUsername = selectedOption;
    }
    return {
      searchInput,
      sorting,
      pagination,
      rows,
      statusArray,
      totalPages,
      loginIdSuggestions,
      tableColumns,
      activeRow,
      memberships,
      memberId,
      search,
      modalClosedHandler,
      onPageChange,
      onPerPageChange,
      sort,
      fetchLoginIdSuggestions,
      onLoginIdSelect,
      viewTransactions
    };
  }
};
</script>
