<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t" @submit.prevent="search">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="ibox-content m-b-sm border-bottom">

        <div class="row">

          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="role">Role</label>
              
              <SelectIncludeAll v-model="searchInput.accessControlId" :options="roles" labelField="name" valueField="id">
              </SelectIncludeAll>
            </div>
          </div>

          <!-- Status Dropdown -->
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="statusId">Status</label>
             
              <SelectIncludeAll v-model="searchInput.statusId" :options="statusArray" labelField="label" valueField="id">
              </SelectIncludeAll>
            </div>
          </div>

          <!-- Login ID Search Field (autocomplete functionality would need to be implemented) -->
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="username">Login ID</label>

              <InputField type="text" placeholder="Search Login ID" v-model="searchInput.username" />
            </div>
          </div>

          <!-- Name Search Field (autocomplete functionality would need to be implemented) -->
          <div class="col-sm-3">
            <div class="form-group">
              <label class="col-form-label" for="name">Name</label>

              <InputField type="text" placeholder="Search Name" v-model="searchInput.name" />
            </div>
          </div>

        </div>

        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
            <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
              button-icon="fa-search" />

            <Button button-type="button" button-class="btn-warning block  m-b" button-text="Add" button-icon="fa-plus"
              data-bs-toggle="modal" data-bs-target="#createUserModal" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content">

              <Datatable :searchCriteria="searchCriteria" :roles="roles"></Datatable>

            </div>
          </div>
        </div>
      </div>


    </div>
  </form>
  <!-- Include the modal component -->
  <dynamic-modal title="Create User" modal-id="createUserModal" :is-edit="false" :roles="roles" @modalClose="modalClosedHandler" />
</template>
  
<script>
// Import your layout components
import Datatable from './Datatable.vue';
import InputField from '@/components/Element/InputField.vue';
import Button from '@/components/Element/Button.vue';
import DynamicModal from './DynamicModal.vue';
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import { StatusEnum } from '@/enum/status';
export default {
  components: {
    Datatable,
    InputField,
    Button,
    DynamicModal,
    SelectIncludeAll
  },
  data() {
    return {
      searchInput: {
        accessControlId: 0,
        statusId: 0,
        username: '',
        name: ''
      },
      searchCriteria: {
        accessControlId:0,
        statusId: 0,
        username: '',
        name: ''
      },
      roles: [],
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    this.getRole();
  },
  computed: {
    statusArray() {
      return Object.entries(StatusEnum).map(([label, id]) => ({ id, label }));
    }
  },
  methods: {
    search() {
      this.searchCriteria = { ...this.searchInput };
    },
    modalClosedHandler() {
      this.searchCriteria = { ...this.searchInput };
    },
    async getRole() {
      try {
        const response = await apiService.getRole();
        this.roles = response.data.accessControls;
        
      } catch (error) {
        console.error('error:', error);
      }
    }
  }
};
</script>
