<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog" style="margin-top: 15%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <!-- Dynamic content for your form inputs -->
          <form @submit.prevent="submitForm" autocomplete="off">

            <div class="mb-3  grid-3-7">
              <label for="full-name" class="form-label">Payment Channel</label>
              <SelectIncludeAll v-model="bankData.paymentChannelId" :options="paymentChannels" labelField="name"
                valueField="id" :includeAllOption="false">
              </SelectIncludeAll>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="referrerReferralCode" class="form-label">Account
                Name</label>
              <input type="text" class="form-control"  v-model="bankData.accountName">
            </div>
            <div class="mb-3  grid-3-7">
              <label for="referrerReferralCode" class="form-label">Account
                Number</label>
              <input type="text" class="form-control"  v-model="bankData.accountNumber">
            </div>
            <!-- <div class="mb-3  grid-3-7">
              <label for="levelUpDay" class="form-label">Status</label>
              <span :class="[' status-label  badge', this.bankData.statusId === 1 ? 'bg-primary' : 'bg-danger']"
                @click="updateStatus(this.bankData)">
                {{ this.bankData.statusId === 1 ? 'Active' : 'Inactive' }}
              </span>
            </div> -->


            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
const toast = useToast();
export default {
  name: 'DynamicModal',
  components: {
    SelectIncludeAll
  },
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },

    row: {
      type: Object,
      default: () => ({})
    },
    paymentChannels: {
      type: Object,
    }
  },
  data() {
    return {
      bankData: { ...this.row },
    };
  },
  methods: {

    async submitForm() {
      try {


        const response = await apiService.updatePlayerBankAccountInformation(
          {
            playerId: this.bankData.id,
            accountName: this.bankData.accountName,
            accountNumber: this.bankData.accountNumber,
            paymentChannelId: this.bankData.paymentChannelId,
          }
        );
        if (response.status === 200) {
          this.hideModal();
          toast.success("Update Payment Method Information Success");
        } else {
          console.error('error:', response);
        }

      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.$emit('modalClose');
    },
    async updateStatus(row) {
      try {
        const newStatus = row.statusId === 1 ? 2 : 1;

        const response = await apiService.updatePlayerBankAccountStatusById({
          id: row.id,
          status: newStatus
        });

        if (response.status === 200) {
          row.statusId = newStatus;

          toast.success(`Member Bank Account status updated to [${newStatus === 1 ? 'Active' : 'Inactive'}]`);
        } else {
          toast.error('Failed to update Member Bank Account status');
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }

  },
  watch: {
    // Whenever the row prop changes, update the data properties
    row(newRow) {
      this.bankData = { ...newRow };
    }
  }
};
</script>
  