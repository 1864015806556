<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <form class="m-t" @submit.prevent="search">
        <div class="wrapper wrapper-content animated fadeInRight ecommerce">


            <div class="ibox-content m-b-sm border-bottom">

                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="col-form-label" for="status">Competition</label>


                            <v-select multiple
                                :options="competitions.map(item => ({ label: item.name, value: item.competitionId }))"
                                :reduce="item => item.value" v-model="importInput.competitionIds">
                            </v-select>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="col-form-label" for="role">Start Date</label>

                            <input type="date" class="form-control" placeholder="Date of Birth"
                                v-model="importInput.startDateTime">

                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="col-form-label" for="role">End Date</label>

                            <input type="date" class="form-control" placeholder="Date of Birth"
                                v-model="importInput.endDateTime">

                        </div>
                    </div>


                </div>

                <div class="form-group row">
                    <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">


                        <Button button-type="button" button-class="btn-info block  m-b" button-text="Import"
                            button-icon="fa-plus" @click="add" />
                    </div>
                </div>
            </div>
            <div class="ibox-content m-b-sm border-bottom">

                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label class="col-form-label" for="status">Status</label>

                            <SelectIncludeAll v-model="searchInput.statusId" :options="statusArray" labelField="label"
                                valueField="id" @change="onStatusChange">
                            </SelectIncludeAll>
                        </div>
                    </div>



                </div>

                <div class="form-group row">
                    <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">

                        <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
                            button-icon="fa-search" />

                        <Button button-type="button" v-if="isAnyRowSelected" button-class="btn-info block  m-b"
                            button-text="Import" button-icon="fa-upload" @click="importSelected" />

                        <Button button-type="button" v-if="isAnyRowSelected" button-class="btn-danger block  m-b"
                            button-text="Cancel" button-icon="fa-times" @click="cancelSelected" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="ibox">
                        <div class="ibox-content table-responsive">
                            <rows-per-page-select v-model="pagination.perPage"
                                @update:value="onPerPageChange"></rows-per-page-select>
                            <!-- <Datatable :searchInput="searchInput"></Datatable> -->
                            <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                                :sortOrder="sorting.sortOrder" @sort-requested="sort" :isAllSelected="isAllSelected" @all-row-selected="handleAllRowSelected">
                                <!-- Define slots for action buttons -->
                                <template v-slot:actionButton="{ row }">
                                    <!-- Your custom action button -->
                                    <div class="actionDiv">
                                        <input type="checkbox" v-model="row.isSelect">

                                    </div>
                                </template>

                                <template v-slot:statusButton="{ row }">


                                    <span :classList="getStatusColor(row.statusId)">
                                        {{ getStatusLabel(row.statusId) }}
                                    </span>
                                </template>

                                <template v-slot:editButton="{ row }">
                                    <!-- Your custom action button -->
                                    <div class="actionDiv" v-if="row.statusId == 1">
                                        <Icon icon="fa-edit" class="edit-icon" tooltip="Edit" @click="editRow(row)">
                                        </Icon>
                                    </div>


                                </template>
                            </DynamicTable>
                            <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                                @page-change="onPageChange"></pagination-component>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </form>

    <dynamic-modal :title="modalTitle" :modal-id="'eventModal'" :is-edit="isEdit" :row="isEdit ? activeRow : null"
        @modalClose="modalClosedHandler" />


</template>

<script>
import Button from '@/components/Element/Button.vue';
import DynamicModal from './DynamicModal.vue';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import Icon from '@/components/Element/Icon.vue';
import apiService from '@/components/Services/apiService';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import { ImportedStatusEnum } from '@/enum/importedStatus';
import { useToast } from 'vue-toastification';
import { ref, reactive, computed } from 'vue';

import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
export default {
    components: {
        Button,
        DynamicModal,
        DynamicTable,
        Icon,
        PaginationComponent,
        RowsPerPageSelect,
        SelectIncludeAll
    },
    setup() {
        const searchInput = reactive({
            statusId: ImportedStatusEnum.Draft,
            name: ''
        });

        const isAllSelected = computed({
            get: () => rows.value.every(row => row.isSelect),
            set: (value) => {
                rows.value.forEach(row => row.isSelect = value);
            }
        });
        const importInput = reactive({
            competitionIds: '',
            startDateTime: '',
            endDateTime: '',
        });

        const sorting = reactive({
            sortBy: '',
            sortOrder: ''
        });

        const pagination = reactive({
            currentPage: 1,
            perPage: 5,
            totalRows: 0
        });

        const rows = ref([]);
        const toast = useToast();
        const competitions = ref([]);
        const activeRow = ref({});
        const modalTitle = ref('Import / List Import event');
        const isEdit = ref();
        const statusArray = computed(() =>
            Object.entries(ImportedStatusEnum).map(([label, id]) => ({ id, label }))
        );


        const isAnyRowSelected = computed(() => {
            return rows.value.some(row => row.isSelect);
        });


        const debounce = (func, delay) => {
            let debounceTimer;
            return function () {
                const context = this;
                const args = arguments;
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => func.apply(context, args), delay);
            }
        };

        function sort(key) {
            if (sorting.sortBy === key) {
                sorting.sortOrder = sorting.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                sorting.sortBy = key;
                sorting.sortOrder = 'asc';
            }
            fetchImportedMatch();
        }

        const totalPages = computed(() =>
            Math.ceil(pagination.totalRows / pagination.perPage)
        );

        function handleAllRowSelected(value){
            isAllSelected.value = value; 
        }

        const tableColumns = [
            { name: '', key: 'isSelect', isSortable: false, isAction: true, slotName: 'actionButton', isTickBox: true },
            { name: 'Division Name', key: 'divisionName', isSortable: true, isAction: false },
            { name: 'Division Chinese Name', key: 'divisionChineseName', isSortable: true, isAction: false },
            { name: 'Home Team Name', key: 'homeTeamName', isSortable: true, isAction: false },
            { name: 'Home Team Chinese Name', key: 'homeTeamChineseName', isSortable: true, isAction: false },
            { name: 'Away Team Name', key: 'awayTeamName', isSortable: true, isAction: false },
            { name: 'Away Team Chinese Name', key: 'awayTeamChineseName', isSortable: true, isAction: false },
            { name: 'Kick-off Time', key: 'eventAt', isSortable: true, isAction: false, isDate: true },
            { name: 'Status', key: 'statusId', isSortable: false, isAction: false, isStatus: true, slotName: 'statusButton', },
            { name: 'Action', key: 'action', isSortable: false, isAction: true, slotName: 'editButton' },
        ]

        fetchImportedMatch();
        fetchCompetition();

        function search() {
            fetchImportedMatch();
        }

        function modalClosedHandler() {
            fetchImportedMatch();
        }

        async function fetchImportedMatch() {
            const skip = (pagination.currentPage - 1) * pagination.perPage;
            const params = {
                offset: skip,
                limit: pagination.perPage,
                statusId: searchInput.statusId,
                name: searchInput.name
            };

            // Only add sort and order if they have values
            if (sorting.sortBy) {
                params.sort = sorting.sortBy;
                params.order = sorting.sortOrder;
            }

            try {
                const response = await apiService.getImportedMatch(params);
                const patchedRows = response.data.importEvents.map(event => ({
                    ...event,
                    isSelect: false
                }));
                rows.value = patchedRows;
                pagination.totalRows = response.data.count;
            } catch (error) {
                toast.error(error.response.data.message);
            }
        }

        async function fetchCompetition() {

            try {
                const response = await apiService.getCompetition();
                competitions.value = response.data;
            } catch (error) {
                toast.error(error.response.data.message);
            }
        }

        async function onStatusChange(newId) {
      if (newId) {
        try {
            pagination.currentPage = 1;
            fetchImportedMatch() ;
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    }

        async function editRow(row) {
            try {
                const details = await apiService.getImportEventById({ id: row.id });
                activeRow.value = { ...details.data };
                modalTitle.value = 'Edit Draft Event';
                isEdit.value = true;
                window.showModal('eventModal', true);
            } catch (error) {
                toast.error(error.response.data.message);
            }
        }
        function getStatusLabel(statusId) {
            return Object.keys(ImportedStatusEnum).find(key => ImportedStatusEnum[key] === statusId);
        }

        function getStatusColor(statusId) {
            switch (statusId) {
                case ImportedStatusEnum.Draft:
                    return 'status-label badge border-none  ';
                case ImportedStatusEnum.Imported:
                    return 'status-label badge border-none  bg-info';
                case ImportedStatusEnum.Cancelled:
                    return 'status-label badge border-none  bg-danger';
                default:
                    return 'status-label badge border-none  bg-secondary';
            }
        }


        const add = debounce(async () => {
            try {
                if (!importInput.competitionIds || importInput.competitionIds.length === 0 ||
                    !importInput.startDateTime || !importInput.endDateTime) {

                    // Show an error message
                    toast.error("Please ensure all fields are filled out: competition IDs, start date/time, and end date/time.");
                    return; // Exit the function early
                }
                var data = {
                    competitionIds: importInput.competitionIds.map(id => String(id)), // Convert each ID to a string
                    startDateTime: importInput.startDateTime,
                    endDateTime: importInput.endDateTime
                }
               const resp =  await apiService.importEvent(data);
                if (resp.status == 200) {
                    toast.success("Success" +  resp.data.importCount + " row of data imported"); 
                    fetchImportedMatch();
                }

            } catch (error) {
                toast.error(error.response.data.message);
            }

        }, 300);

        function getSelectedRowIds() {
            // Filter rows where isSelect is true and then map to get their IDs
            const selectedRowIds = rows.value.filter(row => row.isSelect === true).map(row => row.id);
            return selectedRowIds;
        }

        const importSelected = debounce(async () => {
            try {
                var items = getSelectedRowIds();

                var data = {
                    importEventIds: items,
                    statusId: ImportedStatusEnum.Imported,
                }
                const resp = await apiService.updateMultipleImportEvent(data);

                if (resp.status == 200) {
                    isAllSelected.value = false;
                    toast.success("Success"); fetchImportedMatch();
                }


            } catch (error) {
                toast.error(error.response.data.message);
            }

        }, 300);

        const cancelSelected = debounce(async () => {
            try {
                var items = getSelectedRowIds();

                var data = {
                    importEventIds: items,
                    statusId: ImportedStatusEnum.Cancelled,
                }
                const resp = await apiService.updateMultipleImportEvent(data);
                if (resp.status == 200) {
                    isAllSelected.value = false;
                    toast.success("Success"); fetchImportedMatch();
                }

            } catch (error) {
                toast.error(error.response.data.message);
            }

        }, 300);


        function onPageChange(newPage) {
            pagination.currentPage = newPage;
        }

        function onPerPageChange(newPerPage) {
            pagination.perPage = newPerPage;
            pagination.currentPage = 1; // Reset to first page
            fetchImportedMatch();
        }

        return {
            searchInput,
            importInput,
            sorting,
            pagination,
            modalTitle,
            isEdit,
            rows,
            statusArray,
            competitions,
            totalPages,
            tableColumns,
            activeRow,
            search,
            modalClosedHandler,
            editRow,
            onPageChange,
            onPerPageChange,
            sort,
            add,
            getStatusColor,
            getStatusLabel,
            importSelected,
            cancelSelected,
            isAnyRowSelected,
            onStatusChange,
            handleAllRowSelected,
            isAllSelected
        };
    }
};
</script>