<template>
  <div id="wrapper">
    <LoadingScreen v-if="globalState.isLoading" />
    <SidebarNav />
    <div id="page-wrapper" class="gray-bg" >
      <div class="row border-bottom">
        <HeaderNav />

      </div>
      <BreadCrumb />
      <router-view />
    </div>
    <!-- <RightSideBarNav/> -->
  </div>
</template>

<script>
// Import your layout components
import SidebarNav from './SidebarNav.vue';
import HeaderNav from './HeaderNav.vue';
import BreadCrumb from './BreadCrumb.vue';
import { globalState } from '/src/router.js';
import LoadingScreen from '@/components/Page/LoadingScreen.vue';
export default {
  components: {
    SidebarNav,
    HeaderNav,
    BreadCrumb,
    LoadingScreen
    // RightSideBarNav
  },
  data() {
    return {
      resizeListener: null,
      globalState
    };
  },
  mounted() {
    this.handleResize();
    this.resizeListener = window.addEventListener('resize', this.handleResize);
  },
  methods: {
   
    handleResize() {
      const width = window.innerWidth;
      // Define the width threshold for "small" screens, e.g., 768px for tablets
      if (width <= 768) {
        document.body.classList.add('body-small');
      } else {
        document.body.classList.remove('body-small');
      }
    },
  },
};
</script>
  <style>
.gray-bg::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

</style>