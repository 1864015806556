
<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog" style="max-width: 800px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="mb-3" v-if="isEdit">
              <label for="presetData-ID" class="form-label">ID</label>
              <!-- Simplified v-model binding by removing 'this' -->
              <input type="text" class="form-control" id="presetData-ID" v-model="presetData.id" disabled>
            </div>
            <div class="mb-3">
              <label for="presetData-name" class="form-label">Name</label>
              <!-- Simplified v-model binding by removing 'this' -->
              <input type="text" class="form-control" id="presetData-name" v-model="presetData.name" required>
            </div>
            <!-- Table for Half Time Odds -->

            <h5 style="font-size: 15px;">Half Time</h5>
            <div class="table-responsive" style="padding: 10px;">
              <table class="table table-hover table-bordered table-bordered">
                <thead>
                  <tr>
                    <th>Goals</th>
                    <th>Min.Profit</th>
                    <th>Max.Profit</th>
                    <th>Total Volume</th>
                    <th>Fake Volume</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Table for Half Time Odds -->
                  <tr v-for="(item, index) in presetData.details.filter(detail => detail.oddTypeId === 1)"
                    :key="`half-${item.id}-${index}`">
                    <td>{{ item.goal }}</td>
                    <td><input type="text" class="form-control" v-model="item.minProfit" @input="handleDecimalMinInput($event, item)"></td>
                    <td><input type="text" class="form-control" v-model="item.maxProfit"  @input="handleDecimalMaxInput($event, item)"></td>
                    <td><input type="text" class="form-control" v-model="item.volume"></td>
                    <td><input type="text" class="form-control" v-model="item.fakeVolume"></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Table for Full Time Odds -->
            <h5 style="font-size: 15px;">Full Time</h5>
            <div class="table-responsive" style="padding: 10px;">

              <table class="table table-hover table-bordered table-bordered">
                <thead>
                  <tr>
                    <th>Goals</th>
                    <th>Min.Profit</th>
                    <th>Max.Profit</th>
                    <th>Total Volume</th>
                    <th>Fake Volume</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Table for Half Time Odds -->
                  <tr v-for="(item, index) in presetData.details.filter(detail => detail.oddTypeId === 2)"
                    :key="`full-${item.id}-${index}`">
                    <td>{{ item.goal }}</td>
                    <td><input type="text" class="form-control" v-model="item.minProfit" @input="handleDecimalMinInput($event, item)"></td>
                    <td><input type="text" class="form-control" v-model="item.maxProfit"  @input="handleDecimalMaxInput($event, item)"></td>
                    <td><input type="text" class="form-control" v-model="item.volume"></td>
                    <td><input type="text" class="form-control" v-model="item.fakeVolume"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
const toast = useToast();

export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: Boolean, // Default value is already false, no need to specify
    row: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      presetData: {
        ...this.row,
        details: this.row.details || [{ goal: '', oddId: '', oddTypeId: '', minProfit: '', maxProfit: '', volume: '', fakeVolume: '' }] // default detail row
      },
      oddsHalf: [],
      oddsFull: [],
    };
  },
  methods: {
    async resetForm() {
      // Reset presetData to its default state
      this.presetData = {
        id: '',
        name: '',
        details: []
      };

      const halfOdds = await this.getOdds(1);
      const fullOdds = await this.getOdds(2);

      this.presetData.details = [
        ...halfOdds.map(odd => ({
          goal: odd.name,
          oddId: odd.id,
          oddTypeId: 1,
          maxProfit: 0,
          minProfit: 0,
          volume: 0,
          fakeVolume: 0
        })),
        ...fullOdds.map(odd => ({
          goal: odd.name,
          oddId: odd.id,
          oddTypeId: 2,
          maxProfit: 0,
          minProfit: 0,
          volume: 0,
          fakeVolume: 0
        }))
      ];

    },
    async getOdds(typeId) {
      try {
        const response = await apiService.getOdds({ typeId });
        return response.data.odds;
      } catch (error) {
        console.error('error:', error);
      }
    },
    async submitForm() {
      try {
        //eslint-disable-next-line no-unused-vars
        const detailsWithoutGoals = this.presetData.details.map(({ goal, ...rest }) => {

          return rest;
        });

        // Prepare the data for submission, without the 'goals' property
        const submissionData = {
          ...this.presetData,
          details: detailsWithoutGoals,
        };

        const response = await (this.isEdit
          ? apiService.updateOddPresetInformation({ id: this.presetData.id, name: this.presetData.name, details: detailsWithoutGoals })
          : apiService.createOddPreset(submissionData));

        if (response.status === 200) {
          this.hideModal();
          toast.success(`${this.isEdit ? 'Update' : 'Create'} Pre-set Data Success`);
        } else {
          console.error('error:', response);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    handleDecimalMinInput(event, item) {
      let value = event.target.value;
      value = value.replace(/,/g, '.');
      const match = value.match(/^\d*(\.\d{0,2})?/);
      item.minProfit = match ? match[0] : '';
    }, 
    handleDecimalMaxInput(event, item) {
      let value = event.target.value;
      value = value.replace(/,/g, '.');
      const match = value.match(/^\d*(\.\d{0,2})?/);
      item.maxProfit = match ? match[0] : '';
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();

      this.$emit('modalClose');
    }
  },
  async mounted() {
    const halfOdds = await this.getOdds(1);
    const fullOdds = await this.getOdds(2);

    this.presetData.details = [
      ...halfOdds.map(odd => ({
        goal: odd.name,
        oddId: odd.id,
        oddTypeId: 1,
        maxProfit: 0,
        minProfit: 0,
        volume: 0,
        fakeVolume: 0
      })),
      ...fullOdds.map(odd => ({
        goal: odd.name,
        oddId: odd.id,
        oddTypeId: 2,
        maxProfit: 0,
        minProfit: 0,
        volume: 0,
        fakeVolume: 0
      }))
    ];
  },
  watch: {
    row: {
      handler(newRow) {
        // Create a map of new details for quick lookup by oddId
        const newDetailsMap = new Map(newRow.details.map(detail => [detail.oddId, detail]));

        // Map of existing details by oddId
        const existingDetailsMap = new Map(this.presetData.details.map(detail => [detail.oddId, detail]));

        // Merge details from newRow into existing details
        const mergedDetails = newRow.details.map(detail => {
          return { ...existingDetailsMap.get(detail.oddId), ...detail };
        });

        // Include details from the existing set that are not in the new row
        this.presetData.details.forEach(detail => {
          if (!newDetailsMap.has(detail.oddId)) {
            mergedDetails.push(detail);
          }
        });

        // Update presetData.details with merged details
        this.presetData.details = mergedDetails;

        // Update other properties
        this.presetData.id = newRow.id;
        this.presetData.name = newRow.name;
      },
      deep: true // To ensure nested objects are reactive
    }
  }
};
</script>
