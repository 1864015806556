<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm" aria-label="Close"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm" autocomplete="off">


            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Login ID</label>
              <Autocomplete ref="autocompleteRef" placeholder="Search Login ID" :suggestions="loginIdSuggestions.map(player => player.username)"
                @select="onLoginIdSelect" @input="fetchLoginIdSuggestions" />
            </div>
            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Name</label>
              <input type="text" class="form-control"  v-model="transactionData.name" disabled>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Balance</label>
              <input type="text" class="form-control"  v-model="transactionData.balance" disabled>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="accessControlId" class="form-label">Member Bank</label>

              <SelectIncludeAll v-model="this.transactionData.playerBankAccountId"
                :options="memberBankAccounts && memberBankAccounts.length > 0 ? memberBankAccounts.map(account => ({ label: account.accountName + '-' + account.accountNumber, value: account.id })) : []"
                labelField="label" valueField="value" :includeAllOption="false">
              </SelectIncludeAll>

            </div>
            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Amount</label>
              <input type="number" class="form-control"  v-model="transactionData.amount" required>
            </div>


            <button type="submit" class="btn btn-primary" :disabled="!isEnabled">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import Autocomplete from "@/components/Element/Autocomplete.vue";
import apiService from '@/components/Services/apiService';
const toast = useToast();
export default {
  name: 'DynamicModal',
  components: {
    Autocomplete,
    SelectIncludeAll
  },
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    loginIdSuggestions: {
      type: Object
    },
    // You can expand this object to include all the fields you need
    row: {
      type: Object,
      default: () => ({
        id: '',
        username: '',
        amount: '',
        playerBankAccountId: '',
      })
    }
  },
  created() {
    this.resetForm();
  },
  data() {
    return {
      // Create a local copy of the user data to edit
      transactionData: { ...this.row },
      isEnabled: false,
      memberBankAccounts: []

    };
  },
  methods: {
    async submitForm() {
      try {
        if (this.transactionData.playerBankAccountId && this.transactionData.playerBankAccountId > 0) {

          if (this.transactionData.balance < this.transactionData.amount) {
            toast.error("Insufficient Balance");
          } else {
            const response = await apiService.createWithdrawalTransaction(this.transactionData);
            if (response.status === 200) {
              this.hideModal();
              toast.success("Create Withdrawal Success");
            } else {
              console.error('error:', response);
            }
          }

        } else {
          toast.error("Member bank account is required");
        }





      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    fetchLoginIdSuggestions(selectedOption) {
      if (selectedOption) {
        const selectedId = this.loginIdSuggestions
          .filter(permission => permission.username == selectedOption).map(permission => permission.id);


        if (selectedId) {
          this.onLoginIdSelect(selectedOption);
        } else {
          this.isEnabled = false;
        }

      } else {
        this.isEnabled = false;
      }
    },

    async onLoginIdSelect(selectedOption) {
      if (selectedOption) {
        this.transactionData.loginId = selectedOption;
        const selectedName = this.loginIdSuggestions
          .filter(permission => permission.username == selectedOption).map(permission => permission.name);
        const selectedId = this.loginIdSuggestions
          .filter(permission => permission.username == selectedOption).map(permission => permission.id);

        if (selectedName && selectedId) {



          if (selectedId[0]) {
            this.transactionData.name = selectedName[0];
            this.transactionData.playerId = selectedId[0];
            const memberWalletRes = await apiService.getMemberWalletById({ id: selectedId[0] });
            this.transactionData.balance = memberWalletRes.data.walletBalance;

            const params = {
              playerId: selectedId[0],
              statusId: 1,
            };
            const response = await apiService.getPlayerBankAccounts(params);
            this.memberBankAccounts = response.data.playerBankAccounts;

            this.isEnabled = true;
          }

        }
      }


    },
    resetForm() {
     
      if (this.$refs.autocompleteRef) {
                this.$refs.autocompleteRef.clearSearch();
            }
      for (const key in  this.transactionData) {
        if (Object.hasOwnProperty.call( this.transactionData, key)) {
          this.transactionData[key] = null; // or set to default values as required
        }
      }
    },
    async getPaymentChannelAccounts() {
      try {
        const response = await apiService.getPaymentChannelAccounts();
        this.paymentChannelAccounts = response.data.paymentChannelAccounts;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }

  },
  watch: {
    row(newRow) {
      if (this.isEdit) {

        this.transactionData = { ...newRow };
      }

    }
  }
};
</script>
