<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm" aria-label="Close"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm" autocomplete="off">


            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Login ID</label>
              <Autocomplete placeholder="Search Login ID" :suggestions="loginIdSuggestions.map(player => player.username)"
                @select="onLoginIdSelect" />
            </div>
            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Name</label>
              <input type="text" class="form-control"  v-model="transactionData.name" disabled>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="accessControlId" class="form-label">Merchant Bank</label>

              <SelectIncludeAll v-model="this.transactionData.paymentChannelAccountId"
                :options="paymentChannelAccounts && paymentChannelAccounts.length > 0 ? paymentChannelAccounts.map(account => ({ label: account.accountName + '-' + account.accountNumber, value: account.id })) : []"
                labelField="label" valueField="value" :includeAllOption="false">
              </SelectIncludeAll>

            </div>
            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Amount</label>
              <input type="number" class="form-control"  v-model="transactionData.amount" required>
            </div>
            <div class="mb-3 grid-3-7">
              <label for="file-upload" class="form-label">Upload File</label>
              <input type="file" class="form-control" id="file-upload" @change="handleFileUpload" required>
            </div>


            <button type="submit" class="btn btn-primary" :disabled="!isEnabled">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import Autocomplete from "@/components/Element/Autocomplete.vue";
import apiService from '@/components/Services/apiService';
const toast = useToast();
export default {
  name: 'DynamicModal',
  components: {
    Autocomplete,
    SelectIncludeAll
  },
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    loginIdSuggestions: {
      type: Object
    },
    // You can expand this object to include all the fields you need
    row: {
      type: Object,
      default: () => ({
        id: '',
        username: '',
        amount: '',
        paymentChannelAccountId: '',
      })
    }
  },
  created() {
    this.resetForm();
    this.getPaymentChannelAccounts();
  },
  data() {
    return {
      // Create a local copy of the user data to edit
      transactionData: { ...this.row },
      isEnabled: false,
      paymentChannelAccounts: []

    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await apiService.createDepositTransaction(this.transactionData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Create Deposit Success");
          } else {
            console.error('error:', response);
          }

      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },
    onLoginIdSelect(selectedOption) {
      this.transactionData.loginId = selectedOption;
      const selectedName = this.loginIdSuggestions
        .filter(permission => permission.username == selectedOption).map(permission => permission.name);
      const selectedId = this.loginIdSuggestions
        .filter(permission => permission.username == selectedOption).map(permission => permission.id);

      if (selectedName && selectedId) {
        this.transactionData.name = selectedName[0];
        this.transactionData.playerId = selectedId[0];
        this.isEnabled = true
      }

    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const maxSize = 5245329;

      if (file) {
        if (file.size > maxSize) {
          toast.error('File size should not exceed 5MB');
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          this.transactionData.receiptFile = e.target.result.split(',').pop();
          this.transactionData.receiptFileExtension = file.name.split('.').pop(); // file extension
        };
        reader.readAsDataURL(file);
      }
    },
    resetForm() {
      this.transactionData = {
        playerId: '',
        username: '',
        name: '',
        paymentChannelAccountId: ' ',
        amount: '',
        receiptFileExtension: '',
        receiptFile: '',
      };
    },
    async getPaymentChannelAccounts() {
      try {
        const response = await apiService.getPaymentChannelAccounts();
        this.paymentChannelAccounts = response.data.paymentChannelAccounts;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }

  },
  watch: {
    row(newRow) {
      if (this.isEdit) {

        this.transactionData = { ...newRow };
      }

    }
  }
};
</script>
