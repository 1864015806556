<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <form class="m-t" @submit.prevent="search">
    <div class="wrapper wrapper-content animated fadeInRight ecommerce">


      <div class="ibox-content m-b-sm border-bottom">

        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="col-form-label" for="status">Status</label>

              <SelectIncludeAll v-model="searchInput.statusId" :options="statusArray" labelField="label" valueField="id">
              </SelectIncludeAll>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="col-form-label" for="name"> Name</label>

              <InputField type="text" placeholder="Name" v-model="searchInput.name" />
            </div>
          </div>


        </div>

        <div class="form-group row">
          <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
            <Button button-type="submit" button-class="btn-primary block  m-b" button-text="Search"
              button-icon="fa-search" />

            <Button button-type="button" button-class="btn-warning block  m-b" button-text="Add" button-icon="fa-plus"
              data-bs-toggle="modal" data-bs-target="#createDivisionModal" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content table-responsive">
              <rows-per-page-select v-model="pagination.perPage" @update:value="onPerPageChange"></rows-per-page-select>
              <!-- <Datatable :searchInput="searchInput"></Datatable> -->
              <DynamicTable :columns="tableColumns" :rows="rows" :sortingKey="sorting.sortBy"
                :sortOrder="sorting.sortOrder" @sort-requested="sort">
                <!-- Define slots for action buttons -->
                <template v-slot:actionButton="{ row }">
                  <!-- Your custom action button -->
                  <div class="actionDiv">

                    <Icon icon="fa-edit" class="edit-icon" tooltip="Edit" @click="editRow(row)"></Icon>
                  </div>
                </template>

                <template v-slot:statusButton="{ row }">
                  <!-- Your custom action button -->
                  <span :class="[' status-label  badge', row.statusId === 1 ? 'bg-primary' : 'bg-danger']"
                    @click="updateStatus(row)">
                    {{ row.statusId === 1 ? 'Active' : 'Inactive' }}
                  </span>
                </template>
              </DynamicTable>
              <pagination-component :current-page="pagination.currentPage" :total-pages="totalPages"
                @page-change="onPageChange"></pagination-component>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>

  <dynamic-modal title="Create Division" modal-id="createDivisionModal" :is-edit="false"
    @modalClose="modalClosedHandler" />

  <dynamic-modal :title="'Edit Division'" :modalId="'editModal'" :is-edit="true" :row="activeRow"
    @modalClose="modalClosedHandler" />
</template>

<script>
import InputField from '@/components/Element/InputField.vue';
import Button from '@/components/Element/Button.vue';
import DynamicModal from './DynamicModal.vue';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import DynamicTable from '@/components/Element/DynamicTable.vue';
import Icon from '@/components/Element/Icon.vue';
import apiService from '@/components/Services/apiService';
import PaginationComponent from '@/components/Element/PaginationComponent.vue';
import RowsPerPageSelect from '@/components/Element/RowsPerPageSelect.vue';
import { StatusEnum } from '@/enum/status';
import { useToast } from 'vue-toastification';
import { ref, reactive, onMounted, computed } from 'vue';

export default {
  components: {
    InputField,
    Button,
    DynamicModal,
    SelectIncludeAll,
    DynamicTable,
    Icon,
    PaginationComponent,
    RowsPerPageSelect
  },
  setup() {
    const searchInput = reactive({
      statusId: 0,
      name: ''
    });

    const sorting = reactive({
      sortBy: '',
      sortOrder: ''
    });

    const pagination = reactive({
      currentPage: 1,
      perPage: 5,
      totalRows: 0
    });

    const rows = ref([]);
    const toast = useToast();
    const activeRow = ref({});

    const statusArray = computed(() =>
      Object.entries(StatusEnum).map(([label, id]) => ({ id, label }))
    );

    function sort(key) {
      if (sorting.sortBy === key) {
       sorting.sortOrder =sorting.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
       sorting.sortBy = key;
       sorting.sortOrder = 'asc';
      }
      fetchDivisions();
    }

    const totalPages = computed(() =>
      Math.ceil(pagination.totalRows / pagination.perPage)
    );

    const tableColumns = [
        { name: 'Action', key: 'action', isSortable: false, isAction: true, slotName: 'actionButton' },
        { name: 'Division Name', key: 'name', isSortable: true, isAction: false },
        { name: 'Division Chinese Name', key: 'chineseName', isSortable: true, isAction: false },
        { name: 'Status', key: 'statusId', isSortable: false, isAction: false, isStatus: true, slotName: 'statusButton', }
      ]

    onMounted(fetchDivisions);


    function search() {
      fetchDivisions();
    }

    function modalClosedHandler() {
      fetchDivisions();
    }

    async function fetchDivisions() {
      const skip = (pagination.currentPage - 1) * pagination.perPage;
      const params = {
        offset: skip,
        limit: pagination.perPage,
        statusId: searchInput.statusId,
        name: searchInput.name
      };

      // Only add sort and order if they have values
      if (sorting.sortBy) {
        params.sort = sorting.sortBy;
        params.order = sorting.sortOrder;
      }

      try {
        const response = await apiService.getDivisions(params);
        rows.value = response.data.divisions;
        pagination.totalRows = response.data.count;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }

    async function updateStatus(row) {
      try {
        const newStatus = row.statusId === 1 ? 2 : 1;

        const response = await apiService.updateDivisionStatusById({
          id: row.id,
          status: newStatus
        });

        if (response.status === 200) {
          row.statusId = newStatus;

          toast.success(`Division status updated to ${newStatus === 1 ? 'Active' : 'Inactive'}`);
        } else {
          toast.error('Failed to update division status');
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }

    async function editRow(row) {
      try {
        const userDetails = await apiService.getDivisionById({ id: row.id });
        activeRow.value = { ...userDetails.data };
        window.showModal('editModal', true);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }

    function onPageChange(newPage) {
      pagination.currentPage = newPage;
    }

    function onPerPageChange(newPerPage) {
      pagination.perPage = newPerPage;
      pagination.currentPage = 1; // Reset to first page
      fetchDivisions();
    }

    return {
      searchInput,
      sorting,
      pagination,
      rows,
      statusArray,
      totalPages,
      tableColumns,
      activeRow,
      search,
      modalClosedHandler,
      updateStatus,
      editRow,
      onPageChange,
      onPerPageChange,
      sort
    };
  }
};
</script>
