<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <!-- Dynamic content for your form inputs -->
          <form @submit.prevent="submitForm">
            <div class="mb-3  grid-3-7">
              <label for="level" class="form-label">Membership Level *</label>
              <input type="number" class="form-control"  v-model="membershipData.level" required>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="name" class="form-label">Membership Name *</label>
              <input type="text" class="form-control" v-model="this.membershipData.name">
            </div>
            <div class="mb-3  grid-3-7">
              <label for="levelUpBetAmount" class="form-label">Up Level Condition *</label>
              <div class="input-font">
                Total bet amount with X days > &nbsp;
                <input type="number" class="form-control" style="flex: 1;" v-model="this.membershipData.levelUpBetAmount">
              </div>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="levelUpDay" class="form-label">X days *</label>
              <input type="number" class="form-control" v-model="this.membershipData.levelUpDay">
            </div>
            <div class="mb-3  grid-3-7" v-if="isEdit">
              <label for="levelUpDay" class="form-label">Status</label>
              <span :class="[' status-label  badge', this.membershipData.statusId === 1 ? 'bg-primary' : 'bg-danger']"
                @click="updateStatus(this.membershipData)">
                {{  this.membershipData.statusId === 1 ? 'Active' : 'Inactive' }}
              </span>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
const toast = useToast();
export default {
  name: 'DynamicModal',
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      membershipData: { ...this.row },
    };
  },
  methods: {
    async updateStatus(row) {
      try {
        const newStatus = row.statusId === 1 ? 2 : 1;

        const response = await apiService.updateMembershipStatusById({
          id: row.id,
          status: newStatus
        });

        if (response.status === 200) {
          row.statusId = newStatus;

          toast.success(`Membership status updated to [${newStatus === 1 ? 'Active' : 'Inactive'}]`);
          this.$emit('modalClose');
        } else {
          toast.error('Failed to update membership status');
        }
      } catch (error) {
        toast.error('Error occurred while updating membership status');
      }
    },
    createDefaultMembershipData() {
      // Return the default structure for division data
      return {
        name: '',
        level: 0,
        levelUpDay: 0,
        levelUpBetAmount: 0
      };
    },
    async submitForm() {
      try {

        if (!this.isEdit) {
          const response = await apiService.createMembership(this.membershipData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Create Membership Success");
          } else {
            toast.error(response.message);
          }

        } else {
          const response = await apiService.updateMembershipInformation(
            {
              id: this.membershipData.id,
              name: this.membershipData.name,
              level: this.membershipData.level,
              levelUpDay: this.membershipData.levelUpDay,
              levelUpBetAmount: this.membershipData.levelUpBetAmount,
            }
          );
          if (response.status === 200) {
            this.hideModal();
            toast.success("Update Membership Information Success");
          this.$emit('modalClose');
          } else {
            console.error('error:', response);
          }
        }

      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.$emit('modalClose');
      this.resetForm();
    },
    resetForm() {
      this.membershipData = this.createDefaultMembershipData();
    },
  },
  watch: {
    // Whenever the row prop changes, update the data properties
    row(newRow) {
      this.membershipData = { ...newRow };
    }
  }
};
</script>
  <style>
.input-font{
  display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
</style>