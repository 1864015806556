<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"
                        ref="closeButton"></button>
                </div>
                <div class="modal-body">
                    <div style="display: grid; grid-template-columns: 50% 50%; gap: 1em">
                        <Card title="Transaction Information" :isToggleEnabled="false">
                            <InfoTable :items="transDetails" :IsTwoline="false" />
                        </Card>


                        <Card title="Withdrawal To" :isToggleEnabled="false">

                            <div class="mb-3  grid-3-7">
                                <label for="referrerReferralCode" class="form-label">Payment Method</label>
                                <input type="text" class="form-control"
                                    v-model="transData.paymentMethodName" disabled>
                            </div>
                            <div class="mb-3  grid-3-7">
                                <label for="referrerReferralCode" class="form-label">Account
                                    Name</label>
                                <input type="text" class="form-control" 
                                    v-model="transData.playerAccountName" disabled>
                            </div>
                            <div class="mb-3  grid-3-7">
                                <label for="referrerReferralCode" class="form-label">Account
                                    Number</label>
                                <input type="text" class="form-control" 
                                    v-model="transData.playerAccountNumber" disabled>
                            </div>
                        </Card>

                        <Card title="Withdrawal From" :isToggleEnabled="false">
                            <div class="mb-3  grid-3-7">
                                <label for="full-name" class="form-label">Select Bank</label>


                                <SelectIncludeAll v-model="paymentChannelAccountId"
                                    :options="paymentChannelAccounts && paymentChannelAccounts.length > 0 ? paymentChannelAccounts.map(account => ({ label: account.paymentChannelCode + ' - '+ account.accountName, value: account.id })) : []"
                                    labelField="label" valueField="value" :includeAllOption="false">
                                </SelectIncludeAll>
                            </div>
                            <div class="mb-3  grid-3-7">
                                <label for="referrerReferralCode" class="form-label">Account
                                    Name</label>
                                <input type="text" class="form-control" 
                                    v-model="transData.merchantAccountName" disabled>
                            </div>
                            <div class="mb-3  grid-3-7">
                                <label for="referrerReferralCode" class="form-label">Account
                                    Number</label>
                                <input type="text" class="form-control" 
                                    v-model="transData.merchantAccountNumber" disabled>
                            </div>
                        </Card>
                        <div class="centralize">
                            <div class="form-group row" style="padding-top: 1.5em;">
                                <div class="col-sm-4 col-sm-offset-2" style="display: flex;gap: 10px;">
                                    <Button button-type="button" button-class="btn-danger block   m-b" button-text="Reject"
                                        button-icon="fa-close" @click="reject" />

                                    <Button button-type="button" button-class="btn-primary block   m-b"
                                        button-text="Approve" button-icon="fa-check" @click="approved" />
                                </div>
                            </div>
                        </div>

                    </div>



                </div>
            </div>
        </div>
    </div>
</template>
    
<script>

import { useToast } from 'vue-toastification';
import apiService from '@/components/Services/apiService';
import Card from '@/components/Element/Card.vue';
import InfoTable from '@/components/Element/InfoTable.vue';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import Button from '@/components/Element/Button.vue';
import { TransactionStatusEnum } from '@/enum/transactionStatus';
import debounce from 'lodash/debounce';
const toast = useToast();
export default {
    name: 'DynamicModal',
    props: {
        title: String,
        modalId: {
            type: String,
            required: true
        },
        row: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Card,
        InfoTable,
        SelectIncludeAll,
        Button
    },
    data() {
        return {
            transData: { ...this.row },
            transDetails: {
                "Transaction No": { value: '', isActive: false },
                "Login ID": { value: "", isActive: true },
                "Transaction Date": { value: "", isActive: false },
                "Transaction Amount": { value: "", isActive: true },
                "Confirmed Amount": { value: "", isActive: true },
               
            },
            paymentChannelAccounts: [],
            paymentChannelAccountId: null,

        };
    },
    created() {
        this.getPaymentChannelAccounts();
    },
    methods: {
        async getPaymentChannelAccounts() {
            try {
                const response = await apiService.getPaymentChannelAccounts();
                this.paymentChannelAccounts = response.data.paymentChannelAccounts;
            } catch (error) {
                toast.error(error.response.data.message);
            }
        },
        convertToDateTimeLocal(dateString) {
            if (!dateString) {
                return '';
            }
            const date = new Date(dateString);

            // Add 8 hours
            date.setHours(date.getHours() + 8);

            // Calculate the timezone offset in milliseconds
            const offset = date.getTimezoneOffset() * 60000;
            const localISOTime = (new Date(date.getTime() - offset)).toISOString().slice(0, -1);

            if (localISOTime) {
                const date = new Date(localISOTime.substring(0, 16)); return date.toLocaleDateString('en-US', {
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
                });
            }
            // Return the date in 'YYYY-MM-DDThh:mm' format
            return '-';
        },
        reject: debounce(async function () {
            try {
                const response = await apiService.updateWithdrawalTransactionStatusById({
                    id: this.row.id,
                    status: TransactionStatusEnum.Rejected,
                    paymentChannelAccountId: this.transData.paymentChannelAccountId
                });

                if (response.status === 200) {
                    this.hideModal();
                    toast.error("Withdrawal Transaction Rejected");
                } else {
                    console.error('error:', response);
                }

            } catch (error) {
                toast.error(error.response.data.message);
            }
        }, 500), // Debounce for 500 milliseconds

        approved: debounce(async function () {
            try {

                if (this.transData.paymentChannelAccountId && this.transData.paymentChannelAccountId > 0) {
                    const response = await apiService.updateWithdrawalTransactionStatusById({
                        id: this.row.id,
                        status: TransactionStatusEnum.Approved,
                        paymentChannelAccountId: this.transData.paymentChannelAccountId
                    });

                    if (response.status === 200) {
                        this.hideModal();
                        toast.success("Withdrawal Transaction Approved");
                    } else {
                        console.error('error:', response);
                    }
                } else {
                    toast.warning("Please select the bank (Withdrawal From)");
                }


            } catch (error) {
                toast.error("Member bank account is required");
            }
        }, 500),

        hideModal() {

            this.paymentChannelAccountId = 0;
            this.$emit('modalClose');
            this.$refs.closeButton.click();

        },
        updateTransDetails() {
            this.transDetails = {
                "Transaction No": { value: this.transData.id || '', isActive: false },
                "Login ID": { value: this.transData.playerUsername || "", isActive: true },
                "Transaction Date": { value: this.convertToDateTimeLocal(this.transData.createdAt) || "", isActive: false },
                "Transaction Amount": { value: this.transData.amount || "", isActive: true },
                "Confirmed Amount": { value: this.transData.localAmount || "", isActive: true },
            };
        }
    },
    watch: {
        // Whenever the row prop changes, update the data properties
        row(newRow) {
            this.transData = { ...newRow };
            this.updateTransDetails();
            this.transData.playerAccountName = newRow.playerAccountName;
            this.transData.playerAccountNumber = newRow.playerAccountNumber;
            this.transData.paymentChannelId = newRow.paymentChannelId;
            this.paymentChannelAccountId = '';
            this.transData.paymentChannelAccountId = 0;
            this.transData.merchantAccountName = "";
            this.transData.merchantAccountNumber = "";

        },
        async paymentChannelAccountId(newVal, oldVal) {


            if (newVal !== oldVal && newVal > 0) {
                // Call your API here
                try {
                    const userDetails = await apiService.getPaymentChannelAccountById({ id: newVal });
                    if (userDetails) {
                        this.transData.paymentChannelAccountId = userDetails.data.id;
                        this.transData.merchantAccountName = userDetails.data.accountName;
                        this.transData.merchantAccountNumber = userDetails.data.accountNumber;
                    } else {
                        this.transData.paymentChannelAccountId = 0;
                        this.transData.merchantAccountName = "";
                        this.transData.merchantAccountNumber = "";
                    }

                } catch (error) {
                    toast.error(error.response.data.message);
                }
            }
        },
    }
};
</script>
    
<style scoped>
.centralize {
    position: absolute;
    bottom: 1em;
    right: 2em;

}

.receiptImg {
    width: 250px;
    height: 300px;
    padding-top: 10px;
}

@media (min-width: 768px) {
    ::v-deep(.modal-dialog) {
        min-width: 1000px !important;
    }

    ::v-deep(.info-table) {
        min-width: 300px;
    }
}
</style>