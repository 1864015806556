<template>
  <div>


    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
      <div class="modal-dialog">
        <div :class="isEditModalShow ? 'modal-content anothermodal' : 'modal-content'">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm" aria-label="Close"
              ref="closeButton"></button>
          </div>
          <div class="modal-body">

            <div class="tabs-container">
              <ul class="nav nav-tabs" role="tablist">
                <li>
                  <a class="nav-link" :class="{ 'active': activeTab === 'tab-1' }" @click="setActiveTab('tab-1')">Basic
                    Info</a>
                </li>

              </ul>
              <div class="tab-content">
                <div role="tabpanel" id="tab-1" class="tab-pane" :class="{ 'active': activeTab === 'tab-1' }">
                  <div class="p-1-2em">
                    <div class="mb-3  grid-3-7">
                      <label for="full-name" class="form-label">Affiliate Name</label>
                      <input type="text" class="form-control"  v-model="memberData.affiliateUsername"
                        disabled>
                    </div>
                    <div class="mb-3  grid-3-7">
                      <label for="full-name" class="form-label">Balance</label>
                      <input type="text" class="form-control"  v-model="memberData.affiliateBalance"
                        disabled>
                    </div>
                  </div>
                  <div class="p-1-2em">
                    <h5 style="font-size: 16px;text-decoration: underline;margin: 1em 0;">Affiliate Member Details</h5>
                    <form @submit.prevent="submitForm" autocomplete="off">

                      <div class="mb-3  grid-3-7">
                        <label for="full-name" class="form-label">Full Name</label>
                        <input type="text" class="form-control"  v-model="memberData.name" required>
                      </div>
                      <div class="mb-3  grid-3-7">
                        <label for="phone-number" class="form-label">Phone (Number)</label>
                        <div class="input-group m-b">
                          <div class="input-group-prepend">
                            <select class="form-control" v-model="memberData.countryCallingCodeId" required>
                              <option v-for="(code, index) in countryCodes" :key="index" :value="code.id">
                                {{ code.name }}
                              </option>
                            </select>
                          </div>
                          <input type="text" class="form-control" v-model="memberData.contactNumber"
                            @keyup="checkContactNumber" required>
                          <!-- Display error message if contact number is invalid -->
                          <div v-if="!isContactNumberValid" class="invalid-feedback d-block">
                            Only numeric values are allowed for contact number.
                          </div>
                        </div>

                      </div>
                      <div class="mb-3  grid-3-7">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" class="form-control"  v-model="memberData.email">
                      </div>

                      <div class="mb-3  grid-3-7" v-if="isEdit">
                        <label for="presetData-name" class="form-label">Date of Birth</label>
                        <!-- Simplified v-model binding by removing 'this' -->
                        <input type="date" class="form-control" placeholder="Date of Birth"
                          v-model="memberData.dateOfBirth">

                      </div>
                      <div class="mb-3  grid-3-7">
                        <label for="username" class="form-label">Login ID</label>
                        <input type="text" class="form-control"  v-model="memberData.username" required
                          :disabled="isEdit">
                      </div>
                      <div class="mb-3  grid-3-7">
                        <label for="membershipId" class="form-label">Membership</label>
                        <SelectIncludeAll v-model="memberData.membershipId"
                          :options="memberships.map(membership => ({ label: membership.name, value: membership.id }))"
                          labelField="label" valueField="value" :includeAllOption="false">
                        </SelectIncludeAll>

                      </div>
                      <div class="mb-3  grid-3-7">
                        <label for="password" class="form-label">Password</label>
                        <div class="input-group m-b">
                        <input :type="showPassword ? 'text' : 'password'" class="form-control"
                          v-model="memberData.password" @keyup="checkPasswordStrength" :required="!isEdit"
                          autocomplete="off">

                        <div class="input-group-append">
                          <button type="button" class="btn btn-outline-secondary" @click="showPassword = !showPassword">
                            <span v-if="showPassword">  <Icon icon="fa-eye-slash" /></span>
                            <span v-else>  <Icon icon="fa-eye" /></span>
                          </button>
                        </div>
                      </div>


                        <div style="grid-column: 2;">


                          <!-- Password Strength Progress Bar -->
                          <div class="progress">
                            <div class="progress-bar" :class="passwordStrength.class"
                              :style="{ width: passwordStrength.width }">
                            </div>
                          </div>
                          <!-- Password Requirements List -->
                          <ul class="password-requirements">
                            <li :class="{ valid: passwordCriteria.minChar, invalid: !passwordCriteria.minChar }">
                              Minimum 6 characters
                            </li>
                            

                          </ul>
                        </div>
                      </div>
                      <div class="mb-3  grid-3-7">
                        <label for="confirmed-password" class="form-label">Confirmed Password</label>
                       

                          <div class="input-group m-b">
                        <input :type="showConfirmPassword ? 'text' : 'password'" class="form-control"
                          v-model="memberData.confirmedPassword"  :required="!isEdit"
                          autocomplete="off">

                        <div class="input-group-append">
                          <button type="button" class="btn btn-outline-secondary" @click="showConfirmPassword = !showConfirmPassword">
                            <span v-if="showConfirmPassword">  <Icon icon="fa-eye-slash" /></span>
                            <span v-else>  <Icon icon="fa-eye" /></span>
                          </button>
                        </div>
                      </div>
                      </div>
                      <div class="mb-3  grid-3-7">
                        <label for="isOptForMarketing" class="form-label">Marketing Usage</label>
                        <div class="switch" @click="switchClick">
                          <div class="onoffswitch">
                            <input type="checkbox" class="onoffswitch-checkbox" v-model="memberData.isOptForMarketing">
                            <label class="onoffswitch-label" for="isOptForMarketing">
                              <span class="onoffswitch-inner"></span>
                              <span class="onoffswitch-switch"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3  grid-3-7">
                        <label for="referrerReferralCode" class="form-label">Referral Code</label>
                        <input type="text" class="form-control"  v-model="memberData.referrerReferralCode"
                          :disabled="memberData.referrerReferralCode">
                      </div>
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                  </div>

                </div>

              </div>



            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { ref, reactive, watch } from 'vue';
import { useToast } from 'vue-toastification';
import SelectIncludeAll from '@/components/Element/SelectIncludeAll.vue';
import apiService from '@/components/Services/apiService';
import axios from 'axios';

import Icon from '@/components/Element/Icon.vue';
export default {
  name: 'DynamicModal',
  components: {
    SelectIncludeAll,
    Icon
  },
  emits: ['modalClose'],
  props: {
    title: String,
    modalId: String,
    isEdit: {
      type: Boolean,
      default: false
    },
    memberships: Object,
    row: {
      type: Object,
      default: () => ({
        id: '',
        name: '',
        countryCallingCodeId: 1,
        contactNumber: '',
        email: '',
        username: '',
        membershipId: '',
        password: '',
        confirmedPassword: '',
        dateOfBirth: '',
        isOptForMarketing: false,
        isCreatedByBackoffice: true,
        referrerReferralCode: '',
        registrationIp: '',
        registrationDomain: '',
      })
    }
  },
  setup(props, { emit }) {
    const toast = useToast();
    const activeTab = ref('tab-1');
    const memberData = reactive(props.isEdit ? { ...props.row } : {});
    const passwordStrength = reactive({ width: '0%', class: 'bg-danger' });
    const passwordCriteria = reactive({
      minChar: false,
      lowercase: false,
      uppercase: false,
    });
    const isPasswordValid = ref(false);
    const isEditModalShow = ref(false);
    const passwordMismatch = ref(false);
    const isContactNumberValid = ref(true);
    const countryCodes = ref([]);
    const closeButton = ref(null);
    const activeRow = ref({});
    const paymentChannels = ref([]);


    const showPassword = ref(false);
    const showConfirmPassword = ref(false);

    const getCountryCallingCode = async () => {
      try {
        const response = await apiService.getCountryCallingCode();
        countryCodes.value = response.data;
        if (countryCodes.value.length > 0) {
          memberData.countryCallingCodeId = countryCodes.value[0].id;
        }
      } catch (error) {
        console.error('error:', error);
      }
    };

    const switchClick = () => {
      memberData.isOptForMarketing = !memberData.isOptForMarketing;
    };

    const checkContactNumber = () => {
      const regex = /^[0-9]*$/; // Allow empty string or numbers
      isContactNumberValid.value = regex.test(memberData.contactNumber);
    };


    const submitForm = async () => {
      try {
        if (validatePassword() && isContactNumberValid.value) {
          const ipResponse = await axios.get('https://ipinfo.io/ip');
          const ip = ipResponse.data.trim();
          const domain = window.location.hostname;
          const userData = {
            name: memberData.name,
            countryCallingCodeId: memberData.countryCallingCodeId,
            contactNumber: memberData.contactNumber,
            email: memberData.email,
            dateOfBirth: memberData.dateOfBirth,
            membershipId: memberData.membershipId,
            password: memberData.password,
            isOptForMarketing: memberData.isOptForMarketing,
            username: memberData.username,
            isCreatedByBackoffice: true,
            referrerReferralCode: memberData.referrerReferralCode,
            registrationIp: ip,
            registrationDomain: domain
          };


          const response = await apiService.createMember(userData);
          if (response.status === 200) {
            hideModal();
            toast.success("Create Member Success");
          } else {
            console.error('error:', response);
          }
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };




    const hideModal = () => {
      closeButton.value.click();
      resetForm();
      emit('modalClose');
    };

    const checkPasswordStrength = () => {
      if (memberData.password) {
        const strength = getPasswordStrength(memberData.password);
        passwordStrength.width = strength.width;
        passwordStrength.class = strength.class;
        const password = memberData.password;
        passwordCriteria.minChar = password.length >= 6;
      }
    };

    const getPasswordStrength = (password) => {
      let strength = { width: '25%', class: 'bg-danger' }; // Weak by default
      
      // Update the strength object based on password criteria
      if (password.length <= 6) strength = { width: '50%', class: 'bg-warning' };
      if (password.length >= 6 ) {
        strength = { width: '100%', class: 'bg-success' };
      }

      return strength;
    };



    const validatePassword = () => {
      if (!props.isEdit && memberData.password) {
        passwordMismatch.value = false;
        isPasswordValid.value = false;

        if (!isPasswordStrong(memberData.password)) {
          return false;
        }
        if (memberData.password !== memberData.confirmedPassword) {
          passwordMismatch.value = true;
          return false;
        }
        isPasswordValid.value = true;
        return true;
      } else {

        return true;
      }
    };

    const isPasswordStrong = (password) => {
      return password.length >= 6;
    };

    const resetForm = () => {

      for (const key in memberData) {
        if (Object.hasOwnProperty.call(memberData, key)) {
          memberData[key] = null; // or set to default values as required
        }
      }
      memberData.countryCallingCodeId = countryCodes.value.length > 0 ? countryCodes.value[0].id : '',
        passwordStrength.value = { width: '0%', class: 'bg-danger' };
      passwordCriteria.value = {
        minChar: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false,
      };
    };



    const setActiveTab = (tabId) => {
      activeTab.value = tabId;

    };



    watch(() => props.row, (newRow) => {
      memberData.referrerReferralCode = newRow.referralCode
      memberData.affiliateUsername = newRow.affiliateUsername
      memberData.affiliateBalance = newRow.affiliateBalance
      memberData.password = null;
    }, { deep: true });





    getCountryCallingCode();


    return {
      showConfirmPassword,
      showPassword,
      activeTab,
      memberData,
      passwordStrength,
      passwordCriteria,
      isPasswordValid,
      passwordMismatch,
      isContactNumberValid,
      countryCodes,
      closeButton,
      paymentChannels,
      activeRow,
      isEditModalShow,
      // Methods
      getCountryCallingCode,
      switchClick,
      checkContactNumber,
      submitForm,
      hideModal,
      checkPasswordStrength,
      getPasswordStrength,
      validatePassword,
      isPasswordStrong,
      resetForm,
      setActiveTab,

    };
  }
};
</script>

<style scoped>
.progress {
  height: 5px;
  margin-top: 5px;
}

.progress-bar {
  transition: width 0.5s ease;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.password-requirements {
  padding-left: 0;
  list-style-type: none;
}

.password-requirements li {
  position: relative;
  padding-left: 20px;
  /* Give enough space for the icon */
}

.password-requirements li.valid::before {
  content: '✓';
  color: green;
  position: absolute;
  left: 0;
}

.password-requirements li.invalid::before {
  content: '✕';
  color: red;
  position: absolute;
  left: 0;
}

select.form-control:not([size]):not([multiple]) {
  height: unset !important;
}

.p-1-2em {
  padding: 1.2em !important;
  padding-bottom: 0 !important;
}

.anothermodal {
  opacity: 0.6;
}
</style>