<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetForm" aria-label="Close"
            ref="closeButton"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm" autocomplete="off">


            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Transfer From</label>
              <Autocomplete ref="autocompleteRef2" :suggestions="loginIdSuggestions.map(player => player.username)"
                @select="onLoginIdSelect" @input="fetchLoginIdSuggestions" />
            </div>

            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Balance</label>
              <input type="text" class="form-control"  v-model="transactionData.balance" disabled>
            </div>
            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Transfer To</label>
              <Autocomplete ref="autocompleteRef" :suggestions="loginIdSuggestions.map(player => player.username)"
                @select="onDestinationPlayerIdSelect" @input="fetchLoginDestinationIdSuggestions" />
            </div>

            <div class="mb-3  grid-3-7">
              <label for="login-id" class="form-label">Amount</label>
              <input type="number" class="form-control"  v-model="transactionData.amount">
            </div>


            <button type="submit" class="btn btn-primary" :disabled="!isEnabled" ref="amount">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

import Autocomplete from "@/components/Element/Autocomplete.vue";
import apiService from '@/components/Services/apiService';
const toast = useToast();
export default {
  name: 'DynamicModal',
  components: {
    Autocomplete,
  },
  props: {
    title: String,
    modalId: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    loginIdSuggestions: {
      type: Object
    },
    // You can expand this object to include all the fields you need
    row: {
      type: Object,
      default: () => ({
        id: '',
        sourcePlayerId: '',
        destinationPlayerId: '',
        amount: 0,
      })
    }
  },
  created() {
    this.resetForm();
  },
  data() {
    return {
      // Create a local copy of the user data to edit
      transactionData: { ...this.row },
      isEnabled: false,

    };
  },
  methods: {
    async submitForm() {
      try {
        if (this.transactionData.destinationPlayerId > 0) {
          if (this.transactionData.balance < this.transactionData.amount) {
            toast.error("Insufficient Balance");
            return;
          }

          if (this.transactionData.amount <= 0 || this.transactionData.amount == undefined) {
            if (this.$refs.amount) {
              this.$refs.amount.focus();
            }
            toast.warning("Invalid transfer amount (Value must be greater than 0) ");
            return;
          }

          const response = await apiService.createTransferTransaction(this.transactionData);
          if (response.status === 200) {
            this.hideModal();
            toast.success("Create Transfer Success");
          } else {
            console.error('error:', response);
          }
        } else {
          toast.warning("Please fill in the [Transfer To]");
          this.$refs.autocompleteRef.focusTo();
        }

      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    hideModal() {
      this.$refs.closeButton.click();
      this.resetForm();
      this.$emit('modalClose');
    },

    fetchLoginIdSuggestions(selectedOption) {
      if (selectedOption) {
        const selectedId = this.loginIdSuggestions
          .filter(permission => permission.username == selectedOption).map(permission => permission.id);

        if (selectedId) {
          this.onLoginIdSelect(selectedOption);
        }

      } else {
        this.isEnabled = false;
        this.transactionData.balance = 0;
      }
    },
    fetchLoginDestinationIdSuggestions(selectedOption) {
      if (selectedOption) {
        const selectedId = this.loginIdSuggestions
          .filter(permission => permission.username == selectedOption).map(permission => permission.id);

        if (selectedId) {
          this.onDestinationPlayerIdSelect(selectedOption);
        }

      } else {
        this.transactionData.destinationPlayerId = 0
      }
    },
    async onDestinationPlayerIdSelect(selectedOption) {
      if (selectedOption) {
        const selectedId = this.loginIdSuggestions
          .filter(permission => permission.username == selectedOption).map(permission => permission.id);

        if (selectedId) {
          if (selectedId[0]) {
            this.transactionData.destinationPlayerId = selectedId[0];
          }
        }
      }


    },

    async onLoginIdSelect(selectedOption) {
      if (selectedOption) {
        const selectedId = this.loginIdSuggestions
          .filter(permission => permission.username == selectedOption).map(permission => permission.id);

        if (selectedId) {
          if (selectedId[0]) {
            this.transactionData.sourcePlayerId = selectedId[0];
            const memberWalletRes = await apiService.getMemberWalletById({ id: selectedId[0] });
            this.transactionData.balance = memberWalletRes.data.walletBalance.toFixed(2);

            const params = {
              playerId: selectedId[0],
              statusId: 1,
            };
            const response = await apiService.getPlayerBankAccounts(params);
            this.memberBankAccounts = response.data.playerBankAccounts;

            this.isEnabled = true;
          }

        }
      }


    },

    resetForm() {
      this.isEnabled = false;
      if (this.$refs.autocompleteRef2) {
        this.$refs.autocompleteRef2.clearSearch();
      }
      if (this.$refs.autocompleteRef) {
        this.$refs.autocompleteRef.clearSearch();
      }
      for (const key in this.transactionData) {
        if (Object.hasOwnProperty.call(this.transactionData, key)) {
          this.transactionData[key] = null; // or set to default values as required
        }
      }
    }

  },
  watch: {
    row(newRow) {
      if (this.isEdit) {

        this.transactionData = { ...newRow };
      }

    }
  }
};
</script>
